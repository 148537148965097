import React, { useEffect } from "react";
import logoKakaoMap from "../../../src/assets/img/logo_kakaomap.png";
import { Link } from "react-router-dom";

const { kakao } = window;
const MobileWayToCome = () => {
  
  useEffect(() => {
    const container = document.getElementById("headOffice");
    const options = {
      center: new kakao.maps.LatLng(37.5709781, 126.9890381),
      level: 4,
    };
    const map = new kakao.maps.Map(container, options);
    // 정상적으로 검색이 완료됐으면

    let markerPosition = new kakao.maps.LatLng(
      37.57102894864521,
      126.99119327869761
    );
    let marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
  }, []);

  useEffect(() => {
    const container = document.getElementById("store");
    const options = {
      center: new kakao.maps.LatLng(37.57014377275733, 126.99192066460148),
      level: 3,
    };
    const map = new kakao.maps.Map(container, options);
    let markerPosition = new kakao.maps.LatLng(
      37.57014377275733,
      126.99192066460148
    );
    let marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    marker.setMap(map);
    return map;
  }, []);

  return (
    <div className="m_company_wrap">
      <div className="company_tit">
        <h3>오시는 길</h3>
        <p>Directions</p>
      </div>
      <div className="company_cot">
        <h4 className="m_mtit">본사 안내</h4>
        <div className="m_mem_inpt1">
          <div
            id="headOffice"
            class="map"
            style={{ width: "100%", height: "180px", overflow: "hidden" }}
          >
            <div
              id="daumRoughmapContainer1637817248605"
              className="root_daum_roughmap root_daum_roughmap_landing"
            ></div>
          </div>
          <div class="wrap_controllers">
            <Link
              to="#"
              className="tit_controllers"
              href="https://map.kakao.com/"
              // target="_blank"
            >
              <img
                src={logoKakaoMap}
                width="72"
                height="16"
                alt="카카오맵"
                style={{ display: "block", width: "72px", height: "16px" }}
              />
            </Link>
            <div className="wrap_btn_roadview"></div>
          </div>
          <table summary="오시는 방법">
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "*%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th>오시는 방법</th>
                <td>지하철 1,3,5호선 종로3가역 2/2-1번 출구 뒷 건물</td>
              </tr>
              <tr>
                <th>주소</th>
                <td>서울 종로구 돈화문로 5가길 1피카디리플러스 9층 (03139)</td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>070-4717-0740</td>
              </tr>
              <tr>
                <th>팩스번호</th>
                <td>02-741-6314</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="m_clear"></div>
        <h4 className="m_mtit">매장 안내(종로 본점)</h4>
        <div className="m_mem_inpt1">
          <div
            id="store"
            className="map"
            style={{ width: "100%", height: "180px", overflow: "hidden" }}
          >
            <div
              id="daumRoughmapContainer1640761739895"
              class="root_daum_roughmap root_daum_roughmap_landing"
            ></div>
          </div>
          <div class="wrap_controllers">
            <Link
              to="#"
              className="tit_controllers"
              href="https://map.kakao.com/"
              // target="_blank"
            >
              <img
                src={logoKakaoMap}
                width="72"
                height="16"
                alt="카카오맵"
                style={{ display: "block", width: "72px", height: "16px" }}
              />
            </Link>
            <div className="wrap_btn_roadview"></div>
          </div>
          <table summary="오시는 방법">
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "*%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th>오시는 방법</th>
                <td>지하철 1,3,5호선 종로3가역 14번 출구 앞</td>
              </tr>
              <tr>
                <th>주소</th>
                <td>서울 종로구 종로 128 화영빌딩 1층(03192)</td>
              </tr>
              <tr>
                <th>전화번호</th>
                <td>02-744-9999</td>
              </tr>
              <tr>
                <th>팩스번호</th>
                <td>02-741-6314</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MobileWayToCome;
