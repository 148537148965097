import React from "react";
import bannerM1 from "../../assets/img/main/bannerM_1.jpeg";
import bannerM2 from "../../assets/img/main/bannerM_2.jpeg";
import bannerM3 from "../../assets/img/main/bannerM_3.jpeg";
import bannerMFTC from "../../assets/img/main/bannerM_ftc.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay]);

const MobileTopSlider = () => {
  const banner = [
    { image: bannerM1 },
    { image: bannerM2 },
    { image: bannerM3 },
    {
      image: bannerMFTC,
      link: "https://m.koreagoldx.co.kr/main/html.php?htmid=service/FTC.html",
    },
  ];

  return (
    <div className="swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal">
      <Swiper
        className="banner"
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
      >
        {banner.map((item, index) => {
          const { image, link } = item;
          return (
            <SwiperSlide
              key={index}
              onClick={() => {
                {
                  link && (window.location.href = link);
                }
              }}
            >
              <div
                className="m_swiper-slide"
                style={{
                  width: "100%",
                  opacity: 1,
                  margin: "0 auto",
                  transitionDuration: "0ms",
                  textAlign: "center",
                }}
              >
                <img src={image} alt="배너 이미지" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default MobileTopSlider;
