import axios from "axios";
//import { useDispatch } from 'react-redux';
//const dispatch = useDispatch();

// set api base url
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

/**
 * 메인페이지
 */
// LiveSpotPrice 조회
export const getLiveSpotPrice = async () => {
  return axios.get(`/api/v1/main/quote/market`);
};

// 환율 조회
export const getExchangeRate = async () => {
  return axios.get(`/api/v1/main/quote/exchange`);
};

//LondonFixing 조회
export const getLondonFix = async () => {
  return axios.get(`/api/v1/main/quote/london`);
};

//기타금속시세 조회
export const getMetalPrices = async () => {
  return axios.get(`/api/v1/main/quote/metal`);
};

//유가증권시세 조회
export const getStockPrice = async () => {
  return axios.get(`/api/v1/main/quote/security`);
};

//매입시세 조회
export const getPurchasePrice = async () => {
  return axios.get(`/api/v1/main/quote/purchase`);
};

//주요지수 조회
export const getMajorIndex = async () => {
  return axios.get(`/api/v1/main/quote/index`);
};

//기준시세 및 도매시세 조회
export const getWholesale = async () => {
  return axios.get(`/api/v1/main/quote/wholesale`);
};

//기타도매시세 조회
export const getWholesaleEtc = async () => {
  return axios.get(`/api/v1/main/quote/wholesale/etc`);
};

//국내 %별 계산 조회
export const getPercent = async (data = "") => {
  return axios.get(`/api/v1/main/quote/percent${data}`);
};

/**
 * 시세조회 페이지
 */

//실시간
export const getInternationalPrice = async (data) => {
  return axios.get(`/api/v1/main/chart/live/price/international${data}`);
};

export const getDomesticPrice = async (data) => {
  return axios.get(`/api/v1/main/chart/live/price/domestic${data}`);
};

export const getInternationalUsdPrice = async (data) => {
  return axios.get(`/api/v1/main/chart/live/rate${data}`);
};

//국내시세
//과거 귀금속시세 조회
export const getDomesticPast = async (data = "") => {
  return axios.get(`/api/v1/main/detail/domestic${data}`);
};

//실시간시세 조회
export const getDomesticLive = async () => {
  return axios.get(`/api/v1/main/detail/domestic/price`);
};

//국내 기간 상품차트 조회
export const getDomesticProductChart = async (data) => {
  return axios.get(`/api/v1/main/chart/period/price/domestic${data}`);
};

//국제시세
//국제기간 상품 차트 조회
export const getInternationalProductChart = async (data) => {
  return axios.get(`/api/v1/main/chart/period/price/international${data}`);
};

//국제기간 환율 차트 조회
export const getInternationalRateChart = async (data) => {
  return axios.get(`/api/v1/main/chart/period/rate${data}`);
};

//국제 실시간 시세 조회
export const getInternational = async (data) => {
  return axios.get(`/api/v1/main/detail/international${data}`);
};
//국제 실시간 환율 조회
export const getInternationalRate = async () => {
  return axios.get(`/api/v1/main/detail/rate`);
};


//국제 실시간 상품페이지 조회
export const getInternationalLivePage = async (data = "") => {
  return axios.get(`/api/v1/main/detail/international/live${data}`);
};
//국제 기간 상품페이지 조회
export const getInternationalPage = async (data = "") => {
  return axios.get(`/api/v1/main/detail/international/period${data}`);
};
//환율 실시간 페이지 조회
export const getInternationalRateLivePage = async (data = "") => {
  return axios.get(`/api/v1/main/detail/rate/live${data}`);
};
//환율 기간 페이지 조회
export const getInternationalRatePage = async (data = "") => {
  return axios.get(`/api/v1/main/detail/rate/period${data}`);
};



//유가증권시세
//유가증권차트 조회
export const getSecurityChart = async (data) => {
  return axios.get(`/api/v1/main/chart/period/security${data}`);
};

//유가증권 기간 조회
export const getSecurityChartList = async (data = "") => {
  return axios.get(`/api/v1/main/detail/security${data}`)
}

//다이아몬드시세 조회
export const getDiamondPrice = async () => {
  return axios.get(`/api/v1/main/detail/diamond`);
};


export const getInspectionStatus = async () => {
  return axios.get(`/api/v1/main/flag/`);
}

export const getLondonFixInquiry = async (data) => {
  return await axios.get(`/api/v1/main/detail/london${data}`)
}