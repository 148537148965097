import React, { useState, useEffect } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import allCross from "../../assets/img/common/all_cross1.png";
import allBtn from "../../assets/img/common/all_btn1.png";
import home from "../../assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";

import useAsync from "../../lib/hooks/useAsync";
import moment from "moment";
import AssetChart from "../../components/common/AssetChart";
import ReactPaginate from "react-paginate";

import {
  numberWithCommas,
  createQueryString,
  formatTime,
} from "../../lib/utils";
import * as APIS from "../../lib/api/index";
export default function InternationalPriceInquiry() {
  const [type, setType] = useState("AU");
  const [range, setRange] = useState("month");
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [chartList, setChartList] = useState([]);
  const [fromDate, setFromDate] = useState(
    formatTime(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      "YYYY-MM-DD"
    )
  );
  const rangeRef = React.useRef();
  const [toDate, setToDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [internationalAsset, setInternationalAsset] = useState([]);

  const queryStringAsset = createQueryString({
    type: type,
  });
  const [internationalData, refetch] = useAsync(
    () => APIS.getInternational(queryStringAsset),
    [],
    false
  );
  const [internationalRateData] = useAsync(
    APIS.getInternationalRate,
    [],
    false
  );

  useEffect(() => {
    setInternationalAsset(internationalData.data);
  }, [internationalData.data]);

  useEffect(() => {
    if (type !== "USD") {
      refetch(() => APIS.getInternational(queryStringAsset));
    }
    // eslint-disable-next-line
  }, [type]);

  const actionType =
    type !== "USD"
      ? () => APIS.getInternationalProductChart(rangeQueryString)
      : () => APIS.getInternationalRateChart(rangeQueryString);

  const [state, refetchChart] = useAsync(actionType, [], false);
  const [chartData, setChartData] = useState([]);

  const searchChartDate = () => {
    setRange("");
    const queryString = createQueryString({
      type: type,
      from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
      to: formatTime(new Date(toDate), "YYYY-MM-DD"),
    });

    const actionType =
      range === "live" && type !== "USD"
        ? () => APIS.getInternationalPrice(queryString)
        : range === "live" && type === "USD"
        ? () => APIS.getInternationalUsdPrice(queryString)
        : range !== "live" && type !== "USD"
        ? () => APIS.getInternationalProductChart(queryString)
        : () => APIS.getInternationalRateChart(queryString);

    refetchChart(actionType);
    //    () => APIS.getInternationalProductChart(queryString),
    //    "",
    //    false
    // );
  };

  useEffect(() => {
    setChartData(state.data);
  }, [state]);

  const headerType = {
    AU: {
      engName: "GOLD",
      korName: "금",
    },
    AG: {
      engName: "SILVER",
      korName: "은",
    },
    PT: {
      engName: "PLATINUM",
      korName: "백금",
    },
    PD: {
      engName: "PALLADIUM",
      korName: "팔라듐",
    },
    USD: {
      engName: "USD",
      korName: "USD",
    },
  };

  const seriesName = headerType[type].korName;
  let arr = [];
  if (chartData?.length > 0) {
    chartData?.forEach((item) => {
      range === "live" && type !== "USD"
        ? arr.push([new Date(item["dateTime"]), item["internationalPrice"]])
        : range === "live" && type === "USD"
        ? arr.push([new Date(item["dateTime"]), item["internationalRate"]])
        : range !== "live" && type !== "USD"
        ? arr.push([new Date(item["date"]), item["internationalPrice"]])
        : arr.push([new Date(item["date"]), item["internationalRate"]]);
    });
  }

  const setting = {
    options: {
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(new Date(val)).format("HH:mm")
                : moment(new Date(val)).format("yyyy.MM.DD");
            return parseDate;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            var val = String(value).split(".");
            if (val.length <= 1) {
              //소수점이 없는 경우
              return numberWithCommas(value);
            } else {
              //소수점이 있는 경우 1
              return numberWithCommas(value.toFixed(2));
            }
          },
        },
        tickAmount: 4,
      },
      tooltip: {
        x: {
          formatter: function (value) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(new Date(value)).format("YYYY-MM-DD HH:mm")
                : moment(new Date(value)).format("yyyy년 MM월 DD일");
            return parseDate;
          },
        },
      },
    },
    series: [
      {
        name: seriesName,
        data: arr,
      },
    ],
    fill: {
      colors: ["#fff"],
    },
  };

  const rangeQueryString = createQueryString(
    range === "month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : {
          type: type,
          from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
          to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
        }
  );

  useEffect(() => {
    const queryString = createQueryString(
      range !== "live"
        ? {
            type: type,
            page: pageNumber,
            size: 15,
            from: fromDate,
            to: toDate,
          }
        : {
            type: type,
            page: pageNumber,
            size: 15,
            from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
            to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
          }
    );
    if (type !== "USD" && range !== "live") {
      APIS.getInternationalPage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              return { ...item, dateTime: item.date || item.dateTime };
            });
            setChartList(parseData || []);
          }
        }
      });
    } else if (type !== "USD" && range === "live") {
      APIS.getInternationalLivePage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              const date = item?.date || item.dateTime;
              const parseDate = moment(new Date(date.replace("T", " "))).format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              return { ...item, dateTime: parseDate };
            });
            setChartList(parseData || []);
          }
        }
      });
    } else if (type === "USD" && range !== "live") {
      APIS.getInternationalRatePage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              return { ...item, dateTime: item.date || item.dateTime };
            });
            setChartList(parseData || []);
          }
        }
      });
    } else if (type === "USD" && range === "live") {
      APIS.getInternationalRateLivePage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              const date = item?.date || item.dateTime;
              const parseDate = moment(new Date(date.replace("T", " "))).format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              return { ...item, dateTime: parseDate };
            });
            setChartList(parseData || []);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [type, range, pageNumber]);

  useEffect(() => {
    const queryString = createQueryString(
      range === "month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "live"
        ? {
            type: type,
            from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
            to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
          }
        : {
            type: type,
            from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
            to: formatTime(new Date(toDate), "YYYY-MM-DD"),
          }
    );
    const actionType =
      range === "live" && type !== "USD"
        ? () => APIS.getInternationalPrice(queryString)
        : range === "live" && type === "USD"
        ? () => APIS.getInternationalUsdPrice(queryString)
        : type !== "USD"
        ? () => APIS.getInternationalProductChart(queryString)
        : () => APIS.getInternationalRateChart(queryString);

    refetchChart(actionType);
    // eslint-disable-next-line
  }, [type, range]);

  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };
  const onPageChange = (props) => {
    const { selected } = props;
    setPageNumber(selected);
  };

  const onChangeDate = (type) => {
    switch (type) {
      case "live":
        setFromDate(formatTime(new Date(), "YYYY-MM-DD"));
        break;
      case "month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "total":
        setFromDate("2016-01-01");
        break;

      default:
    }
    setPageNumber(0);
    setPageCount(0);
    rangeRef.current = type;
    setRange(type);
  };

  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.londonFixInquiry}>
                              런던픽스시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>

              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝--> */}
      {/* <!-- 비쥬얼 박스 시작 --> */}
      <div className="svisual_box">
        <div className="sub_visual1_bg">
          <div className="subvisual_wrap">
            <dl className="sub_vtitl1">
              <dt>시세조회</dt>
              <dd>Market Price Check</dd>
            </dl>
            <div className="location_wrap">
              <div className="location_box">
                <dl>
                  <dt className="home">
                    <Link to="/">
                      <img src={home} alt="home" />
                    </Link>
                  </dt>
                  <dd className="dep_menu1">
                    <button onClick={dropDownFirstTab} className="dropbtn">
                      시세조회
                    </button>
                    <div id="myDropdown1" className="dropdown-content">
                      {/* <Link to="/product/productall.html" style={{display: "none"}}>
                        상품안내
                      </Link>
                      <Link to="/souvenir/inquiry.html" style={{display: "none"}}>
                        기념품제작
                      </Link>
                      <Link
                        to="/newscenter/newsNoticeList.html"
                        style={{display: "none"}}
                      >
                        뉴스센터
                      </Link>
                      <Link
                        to="/notice/announcementList.html"
                        style={{display: "none"}}
                      >
                        공지사항
                      </Link> */}
                      <Link to="/company/companysummary.html">회사소개</Link>
                    </div>
                  </dd>
                  <dd className="dep_menu2">
                    <button onClick={dropDownSecondTab} className="dropbtn">
                      국제시세
                    </button>
                    <div id="myDropdown2" className="dropdown-content">
                      <Link to={routes.domesticPriceInquiry}>국내시세</Link>
                      <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
                      <Link to={routes.securitiesPriceInquiry}>
                        유가증권시세
                      </Link>
                      <Link to={routes.diamondPriceInquiry}>
                        다이아몬드시세
                      </Link>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub_container">
        <div className="sub_container_wrap">
          {/* <!-- lnb start --> */}
          <div className="lnb">
            <h4 className="lb_titl1">시세조회</h4>
            <ul>
              <li>
                <Link to={routes.domesticPriceInquiry}>국내시세</Link>
              </li>
              <li>
                <Link to={routes.internationalPriceInquiry} className="on">
                  국제시세
                </Link>
              </li>
              <li>
                <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
              </li>
              <li>
                <Link to={routes.securitiesPriceInquiry}>유가증권시세</Link>
              </li>
              <li>
                <Link to={routes.diamondPriceInquiry}>다이아몬드시세</Link>
              </li>
            </ul>
          </div>
          {/* <!-- lnb end --> */}
          {/* <!-- contents start --> */}
          <div className="contents">
            <div className="con_titl1">
              <h4>국제시세</h4>
              <span>International Price</span>
            </div>
            <div className="outer">
              <div className="tab_buttons bhistory">
                <button
                  className={`dptab ${type === "AU" ? "active" : ""}`}
                  onClick={() => setType("AU")}
                >
                  골드
                </button>
                <button
                  className={`dptab ${type === "AG" ? "active" : ""}`}
                  onClick={() => setType("AG")}
                >
                  실버
                </button>
                <button
                  className={`dptab  ${type === "PT" ? "active" : ""}`}
                  onClick={() => setType("PT")}
                >
                  플래티넘
                </button>
                <button
                  className={`dptab ${type === "PD" ? "active" : ""}`}
                  onClick={() => setType("PD")}
                >
                  팔라듐
                </button>
                <button
                  className={`dptab ${type === "USD" ? "active" : ""}`}
                  onClick={() => setType("USD")}
                >
                  환율
                </button>
              </div>
              <div className="tabs">
                <div className="tab active">
                  <ul className="quo_list2">
                    <li>
                      <dl className="bidask">
                        {type !== "USD" ? (
                          <dt>
                            국제
                            {internationalAsset?.type
                              ?.replace("Au", "금")
                              ?.replace("Ag", "은")
                              ?.replace("Pt", "플래티넘")
                              ?.replace("Pd", "팔라듐")}
                            기준가
                          </dt>
                        ) : (
                          <dt>국제 환율 기준가</dt>
                        )}

                        <dd>
                          <span className="bbid">Bid</span>
                          <span>
                            {type !== "USD"
                              ? numberWithCommas(internationalAsset?.bid)
                              : numberWithCommas(
                                  internationalRateData?.data.bid
                                )}
                          </span>
                        </dd>
                        <dd>
                          <span className="aask">Ask</span>
                          <span>
                            {type !== "USD"
                              ? numberWithCommas(internationalAsset?.ask)
                              : numberWithCommas(
                                  internationalRateData?.data.ask
                                )}
                          </span>
                        </dd>
                      </dl>
                    </li>
                    {type !== "USD" && (
                      <li>
                        <dl className="wgbox">
                          <dt>
                            국내
                            {internationalAsset?.type
                              ?.replace("Au", "금")
                              ?.replace("Ag", "은")
                              ?.replace("Pt", "플래티넘")
                              ?.replace("Pd", "팔라듐")}
                            기준가
                          </dt>
                          <dd>
                            <span>₩/g</span>
                            <span>
                              {numberWithCommas(
                                internationalAsset?.domesticPrice
                              )}
                            </span>
                          </dd>
                          <dd>
                            <span>₩/3.75g</span>
                            <span>
                              {numberWithCommas(
                                internationalAsset?.domesticPriceDon
                              )}
                            </span>
                          </dd>
                        </dl>
                      </li>
                    )}
                    <li className="last">
                      <dl>
                        <dt>기준 국제 시세(USD/T.oz)</dt>
                        <dd>
                          {type !== "USD"
                            ? numberWithCommas(
                                internationalAsset?.internationalStandardPrice
                              )
                            : numberWithCommas(
                                internationalRateData?.data
                                  .internationalStandardPrice
                              )}
                        </dd>
                      </dl>
                      <dl>
                        <dt>기준 원달러 환율(USD/₩)</dt>
                        <dd>
                          {type !== "USD"
                            ? numberWithCommas(internationalAsset?.exchangeRate)
                            : numberWithCommas(
                                internationalRateData?.data.exchangeRate
                              )}
                        </dd>
                      </dl>
                    </li>
                  </ul>
                  <br />
                  <div className="sch_daytitl1">
                    <h5>기간</h5>
                    <ul className="term">
                      <li>
                        <Link
                          to="#"
                          className={range === "live" ? "on" : ""}
                          onClick={() => onChangeDate("live")}
                        >
                          실시간
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "month" ? "on" : ""}
                          onClick={() => onChangeDate("month")}
                        >
                          1개월
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "3month" ? "on" : ""}
                          onClick={() => onChangeDate("3month")}
                        >
                          3개월
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "year" ? "on" : ""}
                          onClick={() => onChangeDate("year")}
                        >
                          1년
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "3year" ? "on" : ""}
                          onClick={() => onChangeDate("3year")}
                        >
                          3년
                        </Link>
                      </li>
                      <li className="last">
                        <div className="data_day_f1">
                          <input
                            className="datepicker1 hasDatepicker"
                            type="date"
                            id="dp1636523011796"
                            onChange={(e) => {
                              setFromDate(e.target.value);
                            }}
                            value={fromDate}
                            disabled={range === "live" ? true : false}
                          />
                        </div>
                        &nbsp;-&nbsp;
                        <div className="data_day2">
                          <input
                            className="datepicker2 hasDatepicker"
                            type="date"
                            id="dp1636523011801"
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            value={toDate}
                            disabled={range === "live" ? true : false}
                          />
                        </div>
                        <button
                          onClick={searchChartDate}
                          className="search_btn1"
                          disabled={range === "live" ? true : false}
                        >
                          조회
                        </button>
                      </li>
                    </ul>
                    <dl className="donYN">
                      <dt>단위</dt>
                      <dd className="first">
                        <Link to="#" className="on">
                          달러
                        </Link>
                      </dd>
                    </dl>
                  </div>
                  <AssetChart
                    style={{ padding: "15px", marginTop: "30px" }}
                    options={setting.options}
                    series={setting.series}
                    type="area"
                    width="850"
                    height="300"
                  />
                </div>
              </div>
            </div>
            <table className="price_info_table" style={{ tableLayout: "auto" }}>
              {type !== "USD" && range !== "live" && (
                <colgroup>
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="120.25px" />
                  <col width="120.25px" />
                  <col width="120.25px" />
                </colgroup>
              )}
              {type !== "USD" && range === "live" && (
                <colgroup>
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="120.25px" />
                  <col width="120.25px" />
                  <col width="120.25px" />
                </colgroup>
              )}
              {type === "USD" && range !== "live" && (
                <colgroup>
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                </colgroup>
              )}
              {type === "USD" && range === "live" && (
                <colgroup>
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                  <col width="106.25px" />
                </colgroup>
              )}

              <thead>
                {type !== "USD" && range !== "live" && (
                  <tr>
                    <th>날짜</th>
                    <th>Bid</th>
                    <th>Ask</th>
                    <th>국제가(USD/T.oz)</th>
                    <th>환율(₩/USD)</th>
                    <th>국내기준가(₩/g)</th>
                    <th>국내기준가(₩/3.75g)</th>
                  </tr>
                )}

                {type !== "USD" && range === "live" && (
                  <tr>
                    <th>날짜</th>
                    <th>시간</th>
                    <th>Bid</th>
                    <th>Ask</th>
                    <th>국제가(USD/T.oz)</th>
                    <th>환율(₩/USD)</th>
                    <th>국내기준가(₩/g)</th>
                    <th>국내기준가(₩/3.75g)</th>
                  </tr>
                )}

                {type === "USD" && range !== "live" && (
                  <tr>
                    <th>날짜</th>
                    <th>Bid</th>
                    <th>Ask</th>
                    <th>환율(₩/USD)</th>
                  </tr>
                )}

                {type === "USD" && range === "live" && (
                  <tr>
                    <th>날짜</th>
                    <th>시간</th>
                    <th>Bid</th>
                    <th>Ask</th>
                    <th>환율(₩/USD)</th>
                  </tr>
                )}
              </thead>
              <tbody>
                {chartList.map((item, index) => {
                  if (type !== "USD" && range !== "live") {
                    return (
                      <tr key={index}>
                        <td>{item?.dateTime || "-"}</td>
                        <td>{numberWithCommas(item?.bid) || "-"}</td>
                        <td>{numberWithCommas(item?.ask) || "-"}</td>
                        <td>
                          {numberWithCommas(item?.internationalPrice) || "-"}
                        </td>
                        <td>{numberWithCommas(item?.exchangeRate) || "-"}</td>
                        <td>{numberWithCommas(item?.domesticPrice) || "-"}</td>
                        <td>
                          {numberWithCommas(item?.domesticPriceDon) || "-"}
                        </td>
                      </tr>
                    );
                  } else if (type !== "USD" && range === "live") {
                    return (
                      <tr key={index}>
                        <td>
                          {formatTime(item?.dateTime, "YYYY-MM-DD") || "-"}
                        </td>
                        <td>{formatTime(item?.dateTime, "HH:mm:ss") || "-"}</td>
                        <td>{numberWithCommas(item?.bid) || "-"}</td>
                        <td>{numberWithCommas(item?.ask) || "-"}</td>
                        <td>
                          {numberWithCommas(item?.internationalPrice) || "-"}
                        </td>
                        <td>{numberWithCommas(item?.exchangeRate) || "-"}</td>
                        <td>{numberWithCommas(item?.domesticPrice) || "-"}</td>
                        <td>
                          {numberWithCommas(item?.domesticPriceDon) || "-"}
                        </td>
                      </tr>
                    );
                  } else if (type === "USD" && range !== "live") {
                    return (
                      <tr key={index}>
                        <td>{item?.date || "-"}</td>
                        <td>{numberWithCommas(item?.bid) || "-"}</td>
                        <td>{numberWithCommas(item?.ask) || "-"}</td>
                        <td>
                          {numberWithCommas(item?.internationalRate) || "-"}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={index}>
                        <td>
                          {formatTime(item?.dateTime, "YYYY-MM-DD") || "-"}
                        </td>
                        <td>{formatTime(item?.dateTime, "HH:mm:ss") || "-"}</td>
                        <td>{numberWithCommas(item?.bid) || "-"}</td>
                        <td>{numberWithCommas(item?.ask) || "-"}</td>
                        <td>
                          {numberWithCommas(item?.internationalRate) || "-"}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <div style={{ fontWeight: "bold" }}>※ 09시 10분 기준</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className={"pagination next"}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "10%",
                  height: "30px",
                  lineHeight: "25px",
                  color: "#337ab7",
                }}
                onClick={() => {
                  if (pageNumber === 0) {
                    return false;
                  }
                  setPageNumber(0);
                }}
              >
                {"<<"}
              </button>
              &nbsp;
              <ReactPaginate
                className={"pagination"}
                pageCount={Math.ceil(pageCount / 15)}
                forcePage={pageNumber}
                pageRangeDisplayed={5}
                itemsCountPerPage={5}
                marginPagesDisplayed={0}
                previousLabel={"<"}
                nextLabel={">"}
                activeClassName={"tabulator-page active"}
                onPageChange={onPageChange}
              />
              &nbsp;
              <button
                className={"pagination previou"}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "10%",
                  height: "30px",
                  lineHeight: "25px",
                  color: "#337ab7",
                }}
                onClick={() => {
                  if (pageNumber === Math.ceil(pageCount / 15) - 1) {
                    return false;
                  } else {
                    setPageNumber(Math.ceil(pageCount / 15) - 1);
                  }
                }}
              >
                {">>"}
              </button>
            </div>
          </div>
          <div className="clear"></div>
          {/* <!-- contents end --> */}
        </div>
        {/* <!-- contents end --> */}
      </div>
    </>
  );
}
