import React from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import "../../assets/css/slick.css";
import allBtn from "../../assets/img/common/all_btn1.png";
import allCross from "../../assets/img/common/all_cross1.png";
import ceoPhoto from "../../assets/img/ceo_photo.png";
import ceoName from "../../assets/img/CEO_NAME.png";
import home from "../../../src/assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import SideBar from "../../components/common/SideBar";
export default function Greeting() {
  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };
  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝-->	
		<!-- wrap --> */}
      <div id="wrap">
        {/* <!-- 비쥬얼 박스 시작 --> */}
        <div className="svisual_box">
          <div className="sub_visual5_bg">
            <div className="subvisual_wrap">
              <dl className="sub_vtitl1">
                <dt>회사소개</dt>
                <dd>Company Introduction</dd>
              </dl>
              <div className="location_wrap">
                <div className="location_box">
                  <dl>
                    <dt className="home">
                      <Link to="/">
                        <img src={home} alt="home" />
                      </Link>
                    </dt>
                    <dd className="dep_menu1">
                      <button onClick={dropDownFirstTab} className="dropbtn">
                        회사소개
                      </button>
                      <div id="myDropdown1" className="dropdown-content">
                        <Link to={routes.domesticPriceInquiry}>시세조회</Link>
                        {/* <Link
                         to="/newscenter/newsNoticeList.html"
                          style={{display: "none"}}
                        >
                          상품안내
                        </Link>
                        <Link  to="/newscenter/newsNoticeList.html" style={{display: "none"}}>
                          기념품제작
                        </Link>
                        <Link
                          to="/newscenter/newsNoticeList.html"
                          style={{display: "none"}}
                        >
                          뉴스센터
                        </Link>
                        <Link
                          to="/notice/announcementList.html"
                          style={{display: "none"}}
                        >
                          공지사항
                        </Link> */}
                      </div>
                    </dd>
                    <dd className="dep_menu2">
                      <button onClick={dropDownSecondTab} className="dropbtn">
                        CEO 인사말
                      </button>
                      <div id="myDropdown2" className="dropdown-content">
                        <Link to={routes.companyIntroduction}>회사소개</Link>
                        <Link to={routes.companyHistory}>회사연혁</Link>
                        <Link to={routes.companyBusinessIntroduction}>
                          사업소개
                        </Link>
                        <Link to={routes.companyBrandIntroduction}>
                          브랜드소개
                        </Link>
                        <Link to={routes.companyDirections}>오시는 길</Link>
                        {/* <Link to={routes.companyFranchiseInvitation}>
                          가맹점 모집
                        </Link> */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_container">
          <div className="sub_container_wrap">
            {/* <!-- lnb start --> */}
            <SideBar />
            {/* <!-- lnb end -->		
					<!-- contents start --> */}
            <div className="contents">
              <div className="con_titl1">
                <h4>CEO 인사말</h4>
                <span>Ceo Message</span>
              </div>
              <h5 className="subtitl1">CEO 인사말</h5>
              <dl className="ceo_bou">
                <dt>
                  <img src={ceoPhoto} alt="ceo_photo" />
                </dt>
                <dd>
                <strong>
                  "대한민국 금거래의 중심!" 한국금거래소
                </strong>
                <p className="mb30">
                2005년 설립한 한국금거래소는 시스템 기반의 투명한 업무 프로세스 구축, 
                동기부여를 통한 조직문화 확립, 다양한 판매채널을 통한 안정적 수익 창출, IT 기술 기반  
                미래 신성장 동력 발굴 등을 통해 지속 발전하여 명실상부 대한민국 최고의 귀금속 기업으로  
                자리매김하였습니다. 
                <br />
                <br />
                오늘이 있기까지 고객 여러분의 아낌없는 관심과 애정이 성장 원동력이라는 사실을  
                잊지 않고 지속적인 “고객 만족 실현”을 최우선 과제로 삼아 “정도경영(正道經營)”에  
                매진하겠습니다. 
                </p>
                <br />
                <p>
                  <strong>
                    ‘Create Value beyond Gold’
                    <br />
                    한국금거래소는 미래를 위한 새로운 가치 창출에 도전합니다!
                  </strong>
                  골드바로 시작해 실버바와 플래티넘바, 각종 순금 제품과 패션 주얼리, 실물 교환형 유가증권,  
                  금 자판기 등 “전국 가맹점”을 통해 고객의 Needs를 충족시켜 온 한국금거래소는 새로운 도전을 
                  시작합니다.
                  <br />
                  <br />
                  세계 각국은 기후변화로 인한 글로벌 위기 대응 차원 ESG 경영을 통해 글로벌 정책에 부합하는  
                  활동을 전개 중으로 도시광산 자원 재생을 목적으로 설립한 “한국금거래소FTC”는 미국의 친환경  
                  자원재활용 인증인 “UL2809”를 획득, 재활용 금의 고순도화 정련으로 탄소 배출(광물 제련금 1Kg  
                  생산 대비 1/1000) 저감에 기여, 글로벌 친환경 정책의 준수를 통해 글로벌 Major 기업과 상생  
                  협업의 교두보를 마련하였습니다. 
                  <br />
                  <br />
                  한국금거래소는 최상의 운영체제 확립과 변화 지향적이고 유연한 기업문화 정착을 바탕으로  
                  귀금속 산업에서의 친환경・자원 선순환 대표 기업으로 거듭나“금 그 이상의 가치 창출 
                  (Create Value beyond Gold)”을 완성하여 해외에서도 인정받는 초일류 글로벌 “한국금거래소”로  
                  발전해 나가겠습니다.
                  <br />
                  <br />
                  그동안 아낌없이 응원해 주신 고객 여러분의 깊은 사랑을 자양분 삼아 새로운 혁신을 주도하여  
                  대한민국의 자랑이 되겠습니다. 감사합니다!
                  <br />
                  <br />
                </p>
                <img width="454" height="100" src={ceoName} alt="ceo_name" />
                {/* <!--<h5 className="ceo_name">대표이사 송종길</h5>--> */}
                </dd>
              </dl>
            </div>
            <div className="clear"></div>
            {/* <!-- contents --> */}
          </div>
        </div>
      </div>
      {/* <!-- ////// wrap --> */}

      <svg
        id="SvgjsSvg1001"
        width="2"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnssvgjs="http://svgjs.com/svgjs"
        style={{
          overflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1002"></defs>
        <polyline id="SvgjsPolyline1003" points="0,0"></polyline>
        <path id="SvgjsPath1004" d="M0 0 "></path>
      </svg>
    </>
  );
}
