import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import {
  numberWithCommas,
  createQueryString,
  formatDate,
} from "../../lib/utils";

export default function DomesticPercentCalculate() {
  const assetTypeRef = useRef();
  const bidAskTypeRef = useRef();
  const intervalRef = useRef();
  const [assetType, setAssetType] = useState("AU");
  const [bidAskType, setBidAskType] = useState("ASK");
  const [domesitPercentData, setDomesticPercentData] = useState();
  const [domesitMarketPercentData, setDomesticMarketPercentData] = useState();
  const queryString = createQueryString({
    assetType: assetType,
    bidAskType: bidAskType,
  });

  const [percentData, refetch] = useAsync(
    () => APIS.getPercent(queryString),
    [],
    false
  );
  const { data } = percentData;

  useEffect(() => {
    assetTypeRef.current = assetType || "AU";
    bidAskTypeRef.current = bidAskType || "ASK";
    setDomesticPercentData(data?.["percentPremiumMainDtoList"] || []);
    setDomesticMarketPercentData(data?.["percentMarketPriceDto"] || []);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    const query = createQueryString({
      assetType: assetType,
      bidAskType: bidAskType,
    });
    refetch(() => APIS.getPercent(query), "", false);
    // eslint-disable-next-line
  }, [assetType, bidAskType]);

  useEffect(() => {
    const query = createQueryString({
      assetType: assetType,
      bidAskType: bidAskType,
    });
    const interval = setInterval(() => {
      refetch(() => APIS.getPercent(query), "", false);
    }, 60000);
    intervalRef.current = interval;
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const intervalAction = () => {
    const query = createQueryString({
      assetType: assetTypeRef.current,
      bidAskType: bidAskTypeRef.current,
    });
    const interval = setInterval(() => {
      refetch(() => APIS.getPercent(query), "", false);
    }, 60000);
    intervalRef.current = interval;
  };

  return (
    <div id="top_calc" style={{ height: "650px" }}>
      <h4 className="mdtitl1">매매 프리미엄</h4>
      <div className="tbl_table1" style={{ height: "540px" }}>
        <ul>
          <li
            className={`tbl_bs ${bidAskType === "ASK" && "active"}`}
            onClick={() => {
              clearInterval(intervalRef.current);
              bidAskTypeRef.current = "ASK";
              setBidAskType("ASK");
              intervalAction();
            }}
          >
            매수(Buy)
          </li>
          <li
            className={`tbl_bs ${bidAskType === "BID" && "active"}`}
            onClick={() => {
              clearInterval(intervalRef.current);
              bidAskTypeRef.current = "BID";
              setBidAskType("BID");
              intervalAction();
            }}
          >
            매도(Sell)
          </li>
        </ul>
        <ul>
          <li
            className={`tbl_pro ${assetType === "AU" && "active"}`}
            onClick={() => {
              clearInterval(intervalRef.current);
              assetTypeRef.current = "AU";
              setAssetType("AU");
            }}
          >
            금(Au)
          </li>
          <li
            className={`tbl_pro ${assetType === "AG" && "active"}`}
            onClick={() => {
              clearInterval(intervalRef.current);
              assetTypeRef.current = "AG";
              setAssetType("AG");
              intervalAction();
            }}
          >
            은(Ag)
          </li>
          <li
            className={`tbl_pro ${assetType === "PT" && "active"}`}
            onClick={() => {
              clearInterval(intervalRef.current);
              assetTypeRef.current = "PT";
              setAssetType("PT");
              intervalAction();
            }}
          >
            백금(Pt)
          </li>
          <li
            className={`tbl_pro ${assetType === "PD" && "active"}`}
            onClick={() => {
              clearInterval(intervalRef.current);
              assetTypeRef.current = "PD";
              setAssetType("PD");
              intervalAction();
            }}
          >
            팔라듐(Pd)
          </li>
          {/* <li class="tbl_pro active">금(Au)</li>
          <li class="tbl_pro">은(Ag)</li>
          <li class="tbl_pro">백금(Pt)</li>
          <li class="tbl_pro">팔라듐(Pd)</li> */}
        </ul>
        <ul className="tbl_cot_ul">
          <li className="tbl_tit">프리미엄율</li>
          <li className="tbl_tit">국내가(g)</li>
          <li className="tbl_tit">국내가(3.75g)</li>
          <li className="tbl_tit">국제가($)</li>
        </ul>
        {(domesitPercentData || []).map((item, index) => {
          return (
            <ul className="tbl_cot_ul" key={`domestic${index}`}>
              <li className="tbl_cot">{item.premium.toFixed(2) || "-"}</li>
              <li className="tbl_cot">
                {numberWithCommas(item.domesticPrice) || "-"}
              </li>
              <li className="tbl_cot">
                {numberWithCommas(item.domesticPriceDon) || "-"}
              </li>
              <li className="tbl_cot">
                {numberWithCommas(item.globalPrice.toFixed(2)) || "-"}
              </li>
            </ul>
          );
        })}
        <table className="tbl_tb1">
          <tbody>
            <tr>
              <td>국제가($)</td>
              <td>{numberWithCommas(domesitMarketPercentData?.globalPrice)}</td>
              <td>환율(₩)</td>
              <td>
                {numberWithCommas(domesitMarketPercentData?.exchangeRate)}
              </td>
            </tr>
            <tr>
              <td>기준가(g)</td>
              <td>
                {numberWithCommas(domesitMarketPercentData?.domesticPrice)}
              </td>
              <td>기준가(3.75g)</td>
              <td>
                {numberWithCommas(domesitMarketPercentData?.domesticPriceDon)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="gtime" style={{ marginTop: "110px" }}>
        고시시간 : {data ? formatDate(new Date(data?.regdate)) : "-"}
      </p>
    </div>

    // <div id="top_calc" style={{height: "650px"}}>
    //   <h4 className="mdtitl1">매매 프리미엄</h4>
    //   <div className="tbl_table1" style={{ height: "540px" }}>
    //     <ul>
    //       <li
    //         className={`tbl_bs ${bidAskType === "ASK" && "active"}`}
    //         onClick={() => {
    //           clearInterval(intervalRef.current);
    //           bidAskTypeRef.current = "ASK";
    //           setBidAskType("ASK");
    //           intervalAction();
    //         }}
    //       >
    //         매수
    //       </li>
    //       <li
    //         className={`tbl_bs ${bidAskType === "BID" && "active"}`}
    //         onClick={() => {
    //           clearInterval(intervalRef.current);
    //           bidAskTypeRef.current = "BID";
    //           setBidAskType("BID");
    //           intervalAction();
    //         }}
    //       >
    //         매도
    //       </li>
    //     </ul>
    //     <ul>
    //       <li
    //         className={`tbl_pro ${assetType === "AU" && "active"}`}
    //         onClick={() => {
    //           clearInterval(intervalRef.current);
    //           assetTypeRef.current = "AU";
    //           setAssetType("AU");
    //         }}
    //       >
    //         금(Au)
    //       </li>
    //       <li
    //         className={`tbl_pro ${assetType === "AG" && "active"}`}
    //         onClick={() => {
    //           clearInterval(intervalRef.current);
    //           assetTypeRef.current = "AG";
    //           setAssetType("AG");
    //           intervalAction();
    //         }}
    //       >
    //         은(Ag)
    //       </li>
    //       <li
    //         className={`tbl_pro ${assetType === "PT" && "active"}`}
    //         onClick={() => {
    //           clearInterval(intervalRef.current);
    //           assetTypeRef.current = "PT";
    //           setAssetType("PT");
    //           intervalAction();
    //         }}
    //       >
    //         백금(Pt)
    //       </li>
    //       <li
    //         className={`tbl_pro ${assetType === "PD" && "active"}`}
    //         onClick={() => {
    //           clearInterval(intervalRef.current);
    //           assetTypeRef.current = "PD";
    //           setAssetType("PD");
    //           intervalAction();
    //         }}
    //       >
    //         팔라듐(Pd)
    //       </li>
    //     </ul>
    //     <ul className="tbl_cot_ul">
    //       <li className="tbl_tit">프리미엄율</li>
    //       <li className="tbl_tit">국제가</li>
    //       <li className="tbl_tit">국내가(g)</li>
    //       <li className="tbl_tit">국내가(3/75g)</li>
    //     </ul>
    //     {(domesitPercentData || []).map((item, index) => {
    //       return (
    //         <ul className="tbl_cot_ul" key={`domestic${index}`}>
    //           <li className="tbl_cot">{item.premium.toFixed(2) || "-"}</li>
    //           <li className="tbl_cot">
    //             {numberWithCommas(item.globalPrice.toFixed(2)) || "-"}
    //           </li>
    //           <li className="tbl_cot">
    //             {numberWithCommas(item.domesticPrice) || "-"}
    //           </li>
    //           <li className="tbl_cot">
    //             {numberWithCommas(item.domesticPriceDon) || "-"}
    //           </li>
    //         </ul>
    //       );
    //     })}
    //   </div>
    //   <p className="gtime" style={{marginTop: "70px"}} >
    //     고시시간 : {data ? formatDate(new Date(data?.regdate)) : "-"}
    //   </p>
    // </div>
  );
}
