import React, { useState, useEffect } from "react";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";

const BlinkText = (props) => {
  const { children, id, style = {}, className = "" } = props;

  const blinkEffect = () => {
    document?.getElementById(id)?.classList?.add("blink_me");
    setTimeout(() => {
      document?.getElementById(id)?.classList?.remove("blink_me");
    }, 3000);
  };
  return (
    <span style={{ ...style }} className={className} id={id}>
      {children}
      {blinkEffect()}
    </span>
  );
};

const MobileLivePrice = () => {
  const [wholesaleData, refetch] = useAsync(APIS.getWholesale, [], true);
  const [totalData, setTotalData] = useState([]);
  const { data } = wholesaleData;
  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "AU") {
        arrayData[0] = item;
      }
      if (item.type === "AG") {
        arrayData[1] = item;
      }
      if (item.type === "PT") {
        arrayData[2] = item;
      }
      if (item.type === "PD") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };
  const compareFluctuationRate = (type, fluctuation) => {
    switch (type) {
      case "arrow":
        if (fluctuation > 0) {
          return (
            <span className="rddown" style={{ marginLeft: "0px" }}>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span>{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else if (fluctuation < 0) {
          return (
            <span className="rddown">
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span>{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else {
          return (
            <span className="rddown">
              <span>
                <img src={midd} alt="midd" />
              </span>
            </span>
          );
        }
      // no default
    }
  };

  useEffect(() => {
    const standard = reformData(data?.["standardPriceMainDtoList"]) || [];
    const wholesale = reformData(data?.["wholesalePriceMainDtoList"]) || [];
    let parseArr = [];
    wholesale.forEach((item, index) => {
      parseArr[index] = {
        ...parseArr[index],
        priceDonWholesale: item.priceDon,
        priceGramWholesale: item.priceGram,
        fluctuation: item.fluctuation,
      };
    });
    standard.forEach((item, index) => {
      parseArr[index] = {
        ...parseArr[index],
        description:
          item.description === "금"
            ? item.description.replace("금", "Au(금)")
            : item.description === "백금"
            ? item.description.replace("백금", "Pt(백금)")
            : item.description === "은"
            ? item.description.replace("은", "Ag(은)")
            : item.description === "팔라듐"
            ? item.description.replace("팔라듐", "Pd(팔라듐)")
            : "",
        type: item.type,
        priceDonStandard: item.priceDon,
        priceGramStandard: item.priceGram,
      };
    });
    setTotalData(parseArr);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getWholesale(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="m_mcontainer2">
        <div className="m_mmiddle_area1">
          <div id="top_price" className="test-aos">
            <h4 className="m_mtit">실시간 시세</h4>
            <div className="m_tbl_table1">
              <table style={{ marginBottom: "3px" }}>
                <thead>
                  <tr className="tit_tr">
                    <td rowSpan="2">구분</td>
                    <td colSpan="2">국제 기준시세</td>
                    <td colSpan="3">국내 도매시세</td>
                  </tr>
                  <tr className="cot_tr">
                    <td>(₩/g)</td>
                    <td>(₩/3.75g)</td>
                    <td>(₩/g)</td>
                    <td>(₩/3.75g)</td>
                    <td style={{ lineHeight: "12px" }}>
                      변동
                      <br />
                      <span className="ft8">(₩/3.75g)</span>
                    </td>
                  </tr>
                </thead>
              </table>
              <table>
                {totalData?.map((item, index) => {
                  return index === 0 || index === 2 ? (
                    <tr
                      key={index}
                      className="m_odd_tr"
                      style={{ borderTop: "1px solid #DDDDDD" }}
                    >
                      <td className="td1">{item.description}</td>
                      <td className="td2">
                        <BlinkText id={`live_price_${index}_${item.type}_0`}>
                          {numberWithCommas(item.priceGramStandard)}
                        </BlinkText>
                      </td>
                      <td className="td2 lst">
                        <BlinkText id={`live_price_${index}_${item.type}_1`}>
                          {numberWithCommas(item.priceDonStandard)}
                        </BlinkText>
                      </td>
                      <td className="td3">
                        <BlinkText id={`live_price_${index}_${item.type}_2`}>
                          {numberWithCommas(item.priceGramWholesale)}
                        </BlinkText>
                      </td>
                      <td className="td3">
                        <BlinkText id={`live_price_${index}_${item.type}_3`}>
                          {numberWithCommas(item.priceDonWholesale)}
                        </BlinkText>
                      </td>
                      <td className="td3">
                        {compareFluctuationRate("arrow", item.fluctuation)}
                      </td>
                    </tr>
                  ) : (
                    <tr key={index} className="m_even_tr">
                      <td className="td1">{item.description}</td>
                      <td className="td2">
                        <BlinkText id={`live_price_${index}_${item.type}_4`}>
                          {numberWithCommas(item.priceGramStandard)}
                        </BlinkText>
                      </td>
                      <td className="td2 lst">
                        <BlinkText id={`live_price_${index}_${item.type}_5`}>
                          {numberWithCommas(item.priceDonStandard)}
                        </BlinkText>
                      </td>
                      <td className="td3">
                        <BlinkText id={`live_price_${index}_${item.type}_6`}>
                          {numberWithCommas(item.priceGramWholesale)}
                        </BlinkText>
                      </td>
                      <td className="td3">
                        <BlinkText id={`live_price_${index}_${item.type}_7`}>
                          {numberWithCommas(item.priceDonWholesale)}
                        </BlinkText>
                      </td>
                      <td className="td3">
                        {compareFluctuationRate("arrow", item.fluctuation)}
                      </td>
                    </tr>
                  );
                })}
                
              </table>
            </div>
            <p className="m_gtime">
              고시시간 :{data ? formatDate(new Date(data?.regdate)) : "-"}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="m_clear"></div>
    </>
  );
};

export default MobileLivePrice;
