import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import useAsync from "../../lib/hooks/useAsync";
import home from "../../../src/assets/img/icon_home.png";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import allCross from "../../assets/img/common/all_cross1.png";
import allBtn from "../../assets/img/common/all_btn1.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import moment from "moment";
import AssetChart from "../../components/common/AssetChart";
import {
  numberWithCommas,
  createQueryString,
  formatTime,
  formatDate,
} from "../../lib/utils";
import * as APIS from "../../lib/api/index";

export default function DomesticPriceInquiry() {
  const [type, setType] = useState("AU");
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  var dayOfWeek = week[new Date().getDay()];
  const [date, setDate] = useState(
    formatTime(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "YYYY-MM-DD"
    )
  );
  const [range, setRange] = useState("month");
  const rangeRef = React.useRef();
  const [unit, setUnit] = useState("gram");
  const [fromDate, setFromDate] = useState(
    formatTime(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      "YYYY-MM-DD"
    )
  );
  const [toDate, setToDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [domesticLivePrice, setDomesticLivePrice] = useState([]);
  const [domesticLivePriceData] = useAsync(APIS.getDomesticLive, [], true);
  const { data } = domesticLivePriceData;

  const [domesticPastPrice, setDomesticPastPrice] = useState([]);

  const actionType = () => APIS.getDomesticProductChart(rangeQueryString);
  const [state, refetchChart] = useAsync(actionType, [], false);
  const [chartData, setChartData] = useState([]);

  const queryString = createQueryString({
    date: formatTime(
      new Date().setFullYear(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate()
      ),
      "YYYY-MM-DD"
    ),
  });

  const [domesticPastPriceData, refetch] = useAsync(
    () => APIS.getDomesticPast(queryString),
    [],
    false
  );
  const searchPastDate = () => {
    const queryString = createQueryString({
      date: formatTime(
        new Date(
          new Date().setFullYear(
            new Date(date).getFullYear(),
            new Date(date).getMonth(),
            new Date(date).getDate()
          )
        ),
        "YYYY-MM-DD"
      ),
    });

    // ) : (
    // queryString = createQueryString({
    //   date: formatTime(
    //       new Date(new Date().setDate(new Date(date).getDate() - 1)),
    //       "YYYY-MM-DD"
    //   )
    // })
    // )
    refetch(() => APIS.getDomesticPast(queryString), "", false);
  };

  const searchChartDate = () => {
    setRange("");
    const queryString = createQueryString({
      type: type,
      from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
      to: formatTime(new Date(toDate), "YYYY-MM-DD"),
    });
    refetchChart(() => APIS.getDomesticProductChart(queryString), "", false);
  };

  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };

  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "Au") {
        arrayData[0] = item;
      }
      if (item.type === "Ag") {
        arrayData[1] = item;
      }
      if (item.type === "Pt") {
        arrayData[2] = item;
      }
      if (item.type === "Pd") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };
  const reformDataPast = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "AU") {
        arrayData[0] = item;
      }
      if (item.type === "AG") {
        arrayData[1] = item;
      }
      if (item.type === "PT") {
        arrayData[2] = item;
      }
      if (item.type === "PD") {
        arrayData[3] = item;
      }
      if (item.type === "GC") {
        arrayData[4] = item;
      }
      if (item.type === "SC") {
        arrayData[5] = item;
      }
      if (item.type === "SP") {
        arrayData[6] = item;
      }
      if (item.type === "PSC") {
        arrayData[7] = item;
      }
      if (item.type === "SN") {
        arrayData[8] = item;
      }
      if (item.type === "IR") {
        arrayData[9] = item;
      }
      if (item.type === "RH") {
        arrayData[10] = item;
      }
    });
    return arrayData;
  };

  const compareFluctuationRate = (type, fluctuation) => {
    switch (type) {
      case "arrow":
        if (fluctuation > 0) {
          return (
            <span>
              <span className="first_c">
                <img src={upArrow} alt="up" />
              </span>
              <span className="last_c">{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else if (fluctuation < 0) {
          return (
            <span>
              <span className="first_c">
                <img src={downArrow} alt="down" />
              </span>
              <span className="last_c">{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else {
          return (
            <span>
              <span className="first_c">
                <img src={midd} alt="midd" />
              </span>
            </span>
          );
        }
      // no default
    }
  };

  const headerType = {
    AU: {
      engName: "GOLD",
      korName: "금",
    },
    AG: {
      engName: "SILVER",
      korName: "은",
    },
    PT: {
      engName: "PLATINUM",
      korName: "백금",
    },
    PD: {
      engName: "PALLADIUM",
      korName: "팔라듐",
    },
    USD: {
      engName: "USD",
      korName: "USD",
    },
  };

  const seriesName = headerType[type].korName;
  let arr = [];
  if (chartData?.length > 0) {
    chartData?.forEach((item) => {
      range === "live" && unit === "gram"
        ? arr.push([new Date(item["dateTime"]), item["domesticPrice"]])
        : range === "live" && unit === "3.75gram"
        ? arr.push([new Date(item["dateTime"]), item["domesticPriceDon"]])
        : unit === "gram"
        ? arr.push([new Date(item["date"]), item["domesticPrice"]])
        : arr.push([new Date(item["date"]), item["domesticPriceDon"]]);
    });
  }

  const setting = {
    options: {
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(new Date(val)).format("HH:mm")
                : moment(new Date(val)).format("yyyy.MM.DD");
            return parseDate;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            var val = String(value).split(".");
            if (val.length <= 1) {
              //소수점이 없는 경우
              return numberWithCommas(value);
            } else {
              //소수점이 있는 경우
              return numberWithCommas(value.toFixed(2));
            }
          },
        },
        tickAmount: 4,
      },
      tooltip: {
        x: {
          formatter: function (value) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(value).format("YYYY-MM-DD HH:mm")
                : moment(new Date(value)).format("yyyy년 MM월 DD일");
            return parseDate;
          },
        },
      },
    },
    series: [
      {
        name: seriesName,
        data: arr,
      },
    ],
    fill: {
      colors: ["#fff"],
    },
  };
  useEffect(() => {
    setDomesticLivePrice(reformData(data?.["domesticLivePriceDtoList"]) || []);
  }, [data]);

  useEffect(() => {
    setDomesticPastPrice(reformDataPast(domesticPastPriceData.data) || []);
  }, [domesticPastPriceData.data]);
  const rangeQueryString = createQueryString(
    range === "month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : {
          type: type,
          from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
          to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
        }
  );

  useEffect(() => {
    const queryString = createQueryString(
      range === "month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "live"
        ? {
            type: type,
            from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
            to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
          }
        : {
            type: type,
            from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
            to: formatTime(new Date(toDate), "YYYY-MM-DD"),
          }
    );
    const actionType =
      range === "live"
        ? () => APIS.getDomesticPrice(queryString)
        : () => APIS.getDomesticProductChart(queryString);

    refetchChart(actionType);
    // eslint-disable-next-line
  }, [type, range]);

  useEffect(() => {
    setChartData(state.data);
  }, [state]);

  const onChangeDate = (type) => {
    switch (type) {
      case "live":
        setFromDate(formatTime(new Date(), "YYYY-MM-DD"));
        break;
      case "month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "total":
        setFromDate("2016-01-01");
        break;

      default:
    }
    rangeRef.current = type;
    setRange(type);
  };

  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="/" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝--> */}
      {/* <!-- 비쥬얼 박스 시작 --> */}
      <div className="svisual_box">
        <div className="sub_visual1_bg">
          <div className="subvisual_wrap">
            <dl className="sub_vtitl1">
              <dt>시세조회</dt>
              <dd>Market Price Check</dd>
            </dl>
            <div className="location_wrap">
              <div className="location_box">
                <dl>
                  <dt className="home">
                    <Link to="/">
                      <img src={home} alt="home" />
                    </Link>
                  </dt>
                  <dd className="dep_menu1">
                    <button onClick={dropDownFirstTab} className="dropbtn">
                      시세조회
                    </button>
                    <div id="myDropdown1" className="dropdown-content">
                      {/* <Link to="/product/productall.html" style={{display: "none"}}>
                        상품안내
                      </Link>
                    <Link to="/souvenir/inquiry.html" style={{display: "none"}}>
                        기념품제작
                      </Link>
                    <Link
                        to="/newscenter/newsNoticeList.html"
                        style={{display: "none"}}
                      >
                        뉴스센터
                      </Link>
                    <Link
                        to="/notice/announcementList.html"
                        style={{display: "none"}}
                      >
                        공지사항
                      </Link> */}
                      <Link to={routes.companyIntroduction}>회사소개</Link>
                    </div>
                  </dd>
                  <dd className="dep_menu2">
                    <button onClick={dropDownSecondTab} className="dropbtn">
                      국내시세
                    </button>
                    <div id="myDropdown2" className="dropdown-content">
                      <Link to={routes.internationalPriceInquiry}>
                        국제시세
                      </Link>
                      <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
                      <Link to={routes.securitiesPriceInquiry}>
                        유가증권시세
                      </Link>
                      <Link to={routes.diamondPriceInquiry}>
                        다이아몬드시세
                      </Link>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub_container">
        <div className="sub_container_wrap">
          {/* <!-- lnb start --> */}
          <div className="lnb">
            <h4 className="lb_titl1">시세조회</h4>
            <ul>
              <li>
                <Link to={routes.domesticPriceInquiry} className="on">
                  국내시세
                </Link>
              </li>
              <li>
                <Link to={routes.internationalPriceInquiry}>국제시세</Link>
              </li>
              <li>
                <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
              </li>
              <li>
                <Link to={routes.securitiesPriceInquiry}>유가증권시세</Link>
              </li>
              <li>
                <Link to={routes.diamondPriceInquiry}>다이아몬드시세</Link>
              </li>
            </ul>
          </div>
          {/* <!-- lnb end --> */}
          {/* <script src="/js/moment.min.js"></script> */}
          {/* <!-- contents start --> */}
          <div className="contents">
            <div className="con_titl1">
              <h4>국내시세</h4>
              <span>Domestic Price</span>
            </div>
            <div className="outer">
              <div className="tab_buttons bhistory">
                <button
                  className={`dptab ${type === "AU" ? "active" : ""}`}
                  onClick={() => setType("AU")}
                >
                  금
                </button>
                <button
                  className={`dptab ${type === "AG" ? "active" : ""}`}
                  onClick={() => setType("AG")}
                >
                  은
                </button>
                <button
                  className={`dptab  ${type === "PT" ? "active" : ""}`}
                  onClick={() => setType("PT")}
                >
                  백금
                </button>
                <button
                  className={`dptab ${type === "PD" ? "active" : ""}`}
                  onClick={() => setType("PD")}
                >
                  팔라듐
                </button>
              </div>
              <div className="tabs">
                <div className="tab active">
                  <div className="sch_daytitl1">
                    <h5>기간</h5>
                    <ul className="term">
                      <li>
                        <Link
                          to="#"
                          className={range === "live" ? "on" : ""}
                          onClick={() => onChangeDate("live")}
                        >
                          실시간
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "month" ? "on" : ""}
                          onClick={() => onChangeDate("month")}
                        >
                          1개월
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "3month" ? "on" : ""}
                          onClick={() => onChangeDate("3month")}
                        >
                          3개월
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "year" ? "on" : ""}
                          onClick={() => onChangeDate("year")}
                        >
                          1년
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className={range === "3year" ? "on" : ""}
                          onClick={() => onChangeDate("3year")}
                        >
                          3년
                        </Link>
                      </li>
                      <li style={{ marginBottom: "15px" }}>
                        <div className="data_day_f1">
                          <input
                            className="datepicker1 hasDatepicker"
                            type="date"
                            id="dp1636523011796"
                            onChange={(e) => {
                              setFromDate(e.target.value);
                            }}
                            value={fromDate}
                            disabled={range === "live" ? true : false}
                          />
                          {/* <img
                            className="ui-datepicker-trigger"
                            src={calendar}
                            alt=""
                            title=""
                          /> */}
                          {/* </input> */}
                        </div>
                        &nbsp;-&nbsp;
                        <div className="data_day2">
                          <input
                            className="datepicker2 hasDatepicker"
                            type="date"
                            id="dp1636523011801"
                            onChange={(e) => {
                              setToDate(e.target.value);
                            }}
                            value={toDate}
                            disabled={range === "live" ? true : false}
                          />
                          {/* <img
                            className="ui-datepicker-trigger"
                            src={calendar}
                            alt=""
                            title=""
                          /> */}
                          {/* </input> */}
                        </div>
                        {/* <button onClick={searchDate} className="search_btn1"> */}
                        <button
                          onClick={searchChartDate}
                          className="search_btn1"
                          disabled={range === "live" ? true : false}
                        >
                          조회
                        </button>
                      </li>
                    </ul>
                    <dl className="donYN" style={{ marginTop: "25px" }}>
                      <dt>단위</dt>
                      <dd className="first">
                        <Link
                          to="#"
                          className={unit === "gram" ? "on" : ""}
                          onClick={() => setUnit("gram")}
                        >
                          ₩/g
                        </Link>
                      </dd>
                      <dd>
                        <Link
                          to="#"
                          className={unit === "3.75gram" ? "on" : ""}
                          onClick={() => setUnit("3.75gram")}
                        >
                          ₩/3.75g
                        </Link>
                      </dd>
                    </dl>
                  </div>
                  <div>
                    <ul className="inter_chart1" style={{ width: "100%" }}>
                      <li name="chart4" style={{ position: "relative" }}>
                        <div id="domesticChart2" style={{ minHeight: "375px" }}>
                          <AssetChart
                            style={{ padding: "15px", marginTop: "15px" }}
                            options={setting.options}
                            series={setting.series}
                            type="area"
                            width="850"
                            height="300"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="today_quo1">
              <h5>실시간 시세</h5>
              <span className="qu_day">
                {data ? formatDate(new Date(data?.regdate)) : "-"}
              </span>
            </div>
            <ul className="quo_list" style={{ marginBottom: "50px" }}>
              <li>
                <dl className="gold">
                  <dt>금 기준가</dt>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[0]?.domesticPrice)}
                    </span>
                    <span>(₩/g)</span>
                  </dd>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[0]?.domesticPriceDon)}
                    </span>
                    <span>(₩/3.75g)</span>
                  </dd>
                  <dd>
                    <span>전일대비</span>
                    {compareFluctuationRate("arrow", 0, domesticLivePrice)}
                  </dd>
                </dl>
              </li>
              <li>
                <dl className="gold">
                  <dt>은 기준가</dt>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[1]?.domesticPrice)}
                    </span>
                    <span>(₩/g)</span>
                  </dd>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[1]?.domesticPriceDon)}
                    </span>
                    <span>(₩/3.75g)</span>
                  </dd>
                  <dd>
                    <span>전일대비</span>
                    {compareFluctuationRate("arrow", 1, domesticLivePrice)}
                  </dd>
                </dl>
              </li>
              <li>
                <dl className="gold">
                  <dt>백금 기준가</dt>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[2]?.domesticPrice)}
                    </span>
                    <span>(₩/g)</span>
                  </dd>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[2]?.domesticPriceDon)}
                    </span>
                    <span>(₩/3.75g)</span>
                  </dd>
                  <dd>
                    <span>전일대비</span>
                    {compareFluctuationRate("arrow", 2, domesticLivePrice)}
                  </dd>
                </dl>
              </li>
              <li>
                <dl className="gold">
                  <dt>팔라듐 기준가</dt>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[3]?.domesticPrice)}
                    </span>
                    <span>(₩/g)</span>
                  </dd>
                  <dd>
                    <span>
                      {numberWithCommas(domesticLivePrice[3]?.domesticPriceDon)}
                    </span>
                    <span>(₩/3.75g)</span>
                  </dd>
                  <dd>
                    <span>전일대비</span>
                    {compareFluctuationRate("arrow", 3, domesticLivePrice)}
                  </dd>
                </dl>
              </li>
            </ul>
            <div className="today_quo1">
              <h5>지난 귀금속 시세</h5>
              <div className="day_sch" style={{ float: "right" }}>
                <div className="data_day_f1">
                  <input
                    className="datepicker1 hasDatepicker"
                    type="date"
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                    value={date}
                  />
                  {/* <img
                    className="ui-datepicker-trigger"
                    src={calendar}
                    alt=""
                    title=""
                  /> */}
                  {/* </input> */}
                </div>
                <button onClick={searchPastDate} className="search_btn1">
                  조회
                </button>
              </div>
            </div>
            <div className="tl_line" style={{ minHeight: "628px" }}>
              <div className="gold_table1" style={{ border: "none" }}>
                <table summary="귀금속 품목">
                  {domesticPastPrice?.length === 0 ? (
                    <>
                      <colgroup>
                        <col style={{ width: "100%" }} />
                      </colgroup>
                      <tbody>
                        <tr>
                          <td style={{ top: "161px", fontSize: "xx-large" }}>
                            해당 날짜에 해당하는 데이터가 존재하지 않습니다.
                          </td>
                        </tr>
                      </tbody>
                    </>
                  ) : (
                    <>
                      <colgroup>
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>귀금속 품목</th>
                          <th>기준가 (₩/g)</th>
                          <th>기준가</th>
                          <th>전일대비 기준값(₩/g)</th>
                        </tr>
                      </thead>
                      {domesticPastPrice?.map((item, index) => {
                        return (
                          <tbody key={index} style={{ height: "77px" }}>
                            <tr>
                              <th>
                                {item.description}
                                {/* {item.type === "SP" ? " (원/kg)" : ""} */}
                              </th>
                              <td
                                className="tal40"
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                {numberWithCommas(item.domesticPrice)}
                              </td>
                              <td
                                className="tal40"
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                {numberWithCommas(item.domesticPriceDon)}
                                {item.type === "AU" ||
                                item.type === "AG" ||
                                item.type === "PT" ||
                                item.type === "PD"
                                  ? "(₩/3.75g)"
                                  : item.type === "GC" ||
                                    item.type === "IR" ||
                                    item.type === "RH"
                                  ? "(₩/100g)"
                                  : "(₩/1kg)"}
                              </td>
                              <td>
                                {compareFluctuationRate(
                                  "arrow",
                                  item.fluctuation
                                )}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </>
                  )}
                </table>
              </div>
            </div>
            <div className="clear" style={{ marginBottom: "80ox" }}></div>
          </div>
          {/* <!-- contents end --> */}
        </div>
        {/* <!-- contents end --> */}
      </div>

      {/* </div> */}
    </>
  );
}
