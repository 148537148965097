import React from "react";
import walk_best from "../../assets/img/walk_best.png";
import or_line1 from "../../assets/img/or_line1.png";
{/* <!--
import ceo_step1 from "../../assets/img/ceo_step1.png";
import ceo_step2 from "../../assets/img/ceo_step2.png";
import ceo_step3 from "../../assets/img/ceo_step3.png";
import ceo_step4 from "../../assets/img/ceo_step4.png";
--> */}

import step_m_1 from "../../assets/img/step_m_1.jpg";
import step_m_2 from "../../assets/img/step_m_2.jpg";
import step_m_3 from "../../assets/img/step_m_3.jpg";
import step_m_4 from "../../assets/img/step_m_4.jpg";

const MobileIntroductionCompany = () => {
  return (
    <div className="m_company_wrap">
      <div className="company_tit">
        <h3>회사소개</h3>
        <p>Company Introduction</p>
      </div>
      <div className="m_company_cot">
        <h4 className="m_mtit">사훈</h4>
        <div className="work_mess">
          <img
            src={walk_best}
            alt="정도 우리는 정당한 도리를 다하고 올바른 길을 걸으며 미래를 개척한다!"
          />
        </div>
        <h4 className="m_mtit">한국금거래소 핵심가치</h4>
        <div className="m_step_box">
          <ul className="m_step_box1">
            <li>
              <div className="m_org_line">
                <img src={or_line1} alt="line" />
                <dl>
                  <dt>고객중심</dt>
                  <dd>
                    고객이 원하는 것을
                    <br /> 파악하여 유연하고
                    <br /> 민첩하게 제공합니다.
                  </dd>
                </dl>
              </div>
              <div className="m_step_pic">
                <img src={step_m_1} alt="pic1" />
              </div>
            </li>
            <li>
              <div className="m_org_line">
                <img src={or_line1} alt="line" />
                <dl>
                  <dt>투명성</dt>
                  <dd>
                    투명하게 정보를
                    <br /> 제공함으로써
                    <br /> 귀금속 거래의
                    <br /> 기준을 세웁니다.
                  </dd>
                </dl>
              </div>
              <div className="m_step_pic">
                <img src={step_m_2} alt="pic2" />
              </div>
            </li>
            <li>
              <div className="m_org_line">
                <img src={or_line1} alt="line" />
                <dl>
                  <dt>주인정신</dt>
                  <dd>
                    임직원 모두는
                    <br /> 인격과 실력을 갖추고
                    <br /> 한국금거래소의
                    <br /> 주인으로서
                    <br />
                    생각하고 행동합니다.
                  </dd>
                </dl>
              </div>
              <div className="m_step_pic">
                <img src={step_m_3} alt="pic3" />
              </div>
            </li>
            <li>
              <div className="m_org_line">
                <img src={or_line1} alt="line" />
                <dl>
                  <dt>소통/방법</dt>
                  <dd>
                    대리점/조직/개인 간<br /> 벽을 허물고
                    <br /> 수평적인 소통으로
                    <br /> 협업을 강화합니다.
                    <br />
                  </dd>
                </dl>
              </div>
              <div className="m_step_pic">
                <img src={step_m_4} alt="pic4" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileIntroductionCompany;