import React, { useState, useEffect } from "react";
import * as APIS from "../../lib/api/index";
import useAsync from "../../lib/hooks/useAsync";
import up from "../../assets/img/up.png";
import down from "../../assets/img/down.png";
import { numberWithCommas } from "../../lib/utils";

const BlinkText = (props) => {
  const { children, id, style = {}, className = "" } = props;

  const blinkEffect = () => {
    document?.getElementById(id)?.classList?.add("blink_me");
    setTimeout(() => {
      document?.getElementById(id)?.classList?.remove("blink_me");
    }, 3000);
  };
  return (
    <span style={{ ...style }} className={className} id={id}>
      {children}
      {blinkEffect()}
    </span>
  );
};

const MobileSpotPrice = () => {
  const [liveSpotPrice, setLiveSpotPrice] = useState([]);
  const [livespotData, refetch] = useAsync(APIS.getLiveSpotPrice, [], true);
  const { data } = livespotData;

  const reformData = (data = []) => {
    let reParseData = [];
    const parseData = data.map((item) => {
      const engName =
        item.type === "AU"
          ? "Gold"
          : item.type === "AG"
          ? "Silver"
          : item.type === "PD"
          ? "Palladium"
          : item.type === "PT"
          ? "Platinum"
          : "-";
      return {
        ...item,
        engName,
        bidClassName:
          item?.bidFluctuationRate > 0
            ? "ct_dw"
            : item.bidFluctuationRate < 0
            ? "ct_up"
            : "",
        askClassName:
          item?.askFluctuationRate > 0
            ? "ct_dw"
            : item.askFluctuationRate < 0
            ? "ct_up"
            : "",
      };
    });

    parseData?.forEach((item) => {
      if (item.type === "AU") {
        reParseData[0] = item;
      }
      if (item.type === "AG") {
        reParseData[1] = item;
      }
      if (item.type === "PT") {
        reParseData[2] = item;
      }
      if (item.type === "PD") {
        reParseData[3] = item;
      }
    });

    return reParseData;
  };

  useEffect(() => {
    setLiveSpotPrice(reformData(data?.["liveSpotPriceDtoList"] || []));
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getLiveSpotPrice(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div id="spot_prices">
      <div className="m_gold_prices_wrap">
        <div className="m_gold_prices_bg">
          <h3>
            LIVE SPOT PRICES
            <span id="live_spot_time" style={{ fontWeight: 400 }}>
              {data?.regdate || "-"}
            </span>
          </h3>
          <div id="spot_price_box" className="m_gold_prices_box">
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td>BID</td>
                  <td>ASK</td>
                </tr>
              </thead>
              <tbody>
                {(liveSpotPrice || []).map((item, index) => {
                  const bidImgTag = () => {
                    return item?.bidFluctuationRate > 0 ? (
                      <img src={up} />
                    ) : item?.bidFluctuationRate < 0 ? (
                      <img src={down} />
                    ) : (
                      <span></span>
                    );
                  };

                  const askImgTag = () => {
                    return item?.askFluctuationRate > 0 ? (
                      <img src={up} />
                    ) : item?.askFluctuationRate < 0 ? (
                      <img src={down} />
                    ) : (
                      <span></span>
                    );
                  };

                  return (
                    <tr key={index}>
                      <td>{item?.engName || "-"}</td>
                      <td>
                        <BlinkText
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                          id={`spot_pirce_blink_${item.type}_${index}_1`}
                        >
                          {numberWithCommas(Math.abs(item?.bid || "-"))}
                        </BlinkText>
                        <br />
                        <BlinkText
                          style={{
                            color:
                              item?.bidFluctuationRate > 0
                                ? "#F42222"
                                : item?.bidFluctuationRate < 0
                                ? "#2282F4"
                                : "black",
                          }}
                          id={`spot_pirce_blink_${item.type}_${index}_2`}
                        >
                          {Math.abs(item?.bidFluctuationRate || "-")}
                        </BlinkText>
                        {bidImgTag()}
                        <BlinkText
                          id={`spot_pirce_blink_${item.type}_${index}_3`}
                        >
                          {Math.abs(item?.bidFluctuation || "-")}
                        </BlinkText>
                      </td>
                      <td>
                        <BlinkText
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                          id={`spot_pirce_blink_${item.type}_${index}_4`}
                        >
                          {numberWithCommas(Math.abs(item?.ask || "-"))}
                        </BlinkText>
                        <br />
                        <BlinkText
                          style={{
                            color:
                              item?.askFluctuationRate > 0
                                ? "#F42222"
                                : item?.askFluctuationRate < 0
                                ? "#2282F4"
                                : "black",
                          }}
                          id={`spot_pirce_blink_${item.type}_${index}_5`}
                        >
                          {Math.abs(item?.askFluctuationRate || "-")}
                        </BlinkText>
                        {askImgTag()}
                        <BlinkText
                          id={`spot_pirce_blink_${item.type}_${index}_6`}
                        >
                          {Math.abs(item?.askFluctuation || "-")}
                        </BlinkText>
                      </td>
                    </tr>
                  );
                })}
                {/* <tr>
                <td>Gold</td>
                <td>
                  1,865.07
                  <br />
                  <span className="ct_dw">-1.32%</span>
                  <img src={down} alt="down" />
                  25.10
                </td>
                <td>
                  1,865.88
                  <br />
                  <span className="ct_dw">-1.32%</span>
                  <img src={down} alt="down" />
                  25.10
                </td>
              </tr>
              <tr>
                <td>Silver</td>
                <td>
                  25.34
                  <br />
                  <span className="ct_dw">-1.32%</span>
                  <img src={down} alt="down" />
                  25.10
                </td>
                <td>
                  25.41
                  <br />
                  <span className="ct_dw">-1.32%</span>
                  <img src={down} alt="down" />
                  25.10
                </td>
              </tr>
              <tr>
                <td>Platinum</td>
                <td>
                  1,004.53
                  <br />
                  <span className="ct_dw">-1.32%</span>
                  <img src={up} alt="up" />
                  25.10
                </td>
                <td>
                  1,012.87
                  <br />
                  <span className="ct_dw">-1.32%</span>
                  <img src={up} alt="down" />
                  25.10
                </td>
              </tr>
              <tr>
                <td>Palladium</td>
                <td>
                  2,317.93
                  <br />
                  <span className="ct_up">-1.32%</span>
                  <img src={up} alt="up" />
                  25.10
                </td>
                <td>
                  2,324.08
                  <br />
                  <span className="ct_up">-1.32%</span>
                  <img src={up} alt="up" />
                  25.10
                </td>
              </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSpotPrice;
