import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import allCross from "../../assets/img/common/all_cross1.png";
import allBtn from "../../assets/img/common/all_btn1.png";
import home from "../../assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas } from "../../lib/utils";
export default function DiamondPriceInquiry() {
  const [diamondPrice, setDiamondPrice] = useState([]);
  const [diamondPriceData] = useAsync(APIS.getDiamondPrice, [], true);
  const { data } = diamondPriceData;

  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };

  useEffect(() => {
    setDiamondPrice(
      data?.["diamondPriceDtoList"].filter((item) => item.status !== true) || []
    );
  }, [data]);

  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>

      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.londonFixInquiry}>
                              런던픽스시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              {" "}
                              뉴스{" "}
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝--> */}
      {/* <!-- 비쥬얼 박스 시작 --> */}
      <div className="svisual_box">
        <div className="sub_visual1_bg">
          <div className="subvisual_wrap">
            <dl className="sub_vtitl1">
              <dt>시세조회</dt>
              <dd>Market Price Check</dd>
            </dl>
            <div className="location_wrap">
              <div className="location_box">
                <dl>
                  <dt className="home">
                    <Link to="/">
                      <img src={home} alt="home" />
                    </Link>
                  </dt>
                  <dd className="dep_menu1">
                    <button onClick={dropDownFirstTab} className="dropbtn">
                      시세조회
                    </button>
                    <div id="myDropdown1" className="dropdown-content">
                      {/* <Link to="/product/productall.html">상품안내</Link>
                      <Link to="/souvenir/inquiry.html">기념품제작</Link>
                      <Link to="/newscenter/newsNoticeList.html">뉴스센터</Link>
                      <Link to="/notice/announcementList.html">공지사항</Link> */}
                      <Link to={routes.companyIntroduction}>회사소개</Link>
                    </div>
                  </dd>
                  <dd className="dep_menu2">
                    <button onClick={dropDownSecondTab} className="dropbtn">
                      다이아몬드시세
                    </button>
                    <div id="myDropdown2" className="dropdown-content">
                      <Link to={routes.domesticPriceInquiry}>국내시세</Link>
                      <Link to={routes.internationalPriceInquiry}>
                        국제시세
                      </Link>
                      <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
                      <Link to={routes.securitiesPriceInquiry}>
                        유가증권시세
                      </Link>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub_container">
        <div className="sub_container_wrap">
          {/* <!-- lnb start --> */}
          <div className="lnb">
            <h4 className="lb_titl1">시세조회</h4>
            <ul>
              <li>
                <Link to={routes.domesticPriceInquiry}>국내시세</Link>
              </li>
              <li>
                <Link to={routes.internationalPriceInquiry}>국제시세</Link>
              </li>
              <li>
                <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
              </li>
              <li>
                <Link to={routes.securitiesPriceInquiry}>유가증권시세</Link>
              </li>
              <li>
                <Link to={routes.diamondPriceInquiry} className="on">
                  다이아몬드시세
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- lnb end --> */}
          {/* <script src="/js/moment.min.js"></script> */}
          {/* <!-- contents start --> */}
          <div className="contents">
            <div className="con_titl1">
              <h4>다이아몬드 시세</h4>
              <span>Diamond Price</span>
            </div>
            <div className="service_cont">
              <div className="service_diamaond mb50">
                <dl className="diamaond_top">
                  {/* <dt>
                    <strong>2022 한국금거래소</strong>다이아몬드 특별할인전
                  </dt>
                  <dd>
                    자연이 선물한 사랑의 상징, 영원의 다이아몬드 <br />
                    강남, 종로본점, 논현직영점 한국금거래소의 다이아몬드 국내
                    최저가 할인{" "}
                  </dd> */}
                </dl>
                <table
                  className="diamaond_info_table"
                  style={{ tableLayout: "auto" }}
                >
                  <colgroup>
                    <col width="3%" className="number" />
                    <col width="13%" />
                    <col width="15%" />
                    <col width="13%" />
                    <col width="13%" />
                    <col width="13%" />
                    <col width="19%" />
                    <col width="11%" className="soldout" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="number">번호</th>
                      <th>감정</th>
                      <th>중량</th>
                      <th>컬러</th>
                      <th>투명도</th>
                      <th>컷등급</th>
                      <th>할인판매가</th>
                      <th className="soldout">품절유무</th>
                    </tr>
                  </thead>
                  <tbody id="diamaondInfoTableBody">
                    {diamondPrice?.map((item, index) => {
                      return item.isSoldOut === true ? (
                        <tr key={index} className="soldout">
                          <td className="number">{item.position}</td>
                          <td>{item.company}</td>
                          <td>{item.weight.toFixed(2)}ct</td>
                          <td>{item.color}</td>
                          <td>{item.clarity}</td>
                          <td>{item.cutRate}</td>
                          <td>{numberWithCommas(item.price)}원</td>
                          <td className="soldout">
                            <span className="flag_soldout">품절</span>
                          </td>
                        </tr>
                      ) : (
                        <tr key={index} className="">
                          <td className="number">{item.position}</td>
                          <td>{item.company}</td>
                          <td>{item.weight.toFixed(2)}ct</td>
                          <td>{item.color}</td>
                          <td>{item.clarity}</td>
                          <td>{item.cutRate}</td>
                          <td>{numberWithCommas(item.price)}원</td>
                          <td className="soldout"></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="diamaond_block">
                  <h3 className="cont_title">고객 구입 절차</h3>
                  <div className="diamaond_process_box">
                    <ul className="diamaond_process_list">
                      <li className="item item_buy_1">
                        <span>1</span>
                        <strong>방문 예약</strong>
                        <p>
                          가까운 한국금거래소에
                          <br />
                          방문 예약합니다.
                        </p>
                      </li>
                      <li className="item item_buy_2">
                        <span>2</span>
                        <strong>고객 상담</strong>
                        <p>
                          다이아몬드4C 확인 후<br /> 상담원과 원하는
                          <br />
                          크기와 등급, 가격대를 선택합니다.
                        </p>
                      </li>
                      <li className="item item_buy_3">
                        <span>3</span>
                        <strong>주얼리 선택</strong>
                        <p>
                          선택된 다이아몬드와 잘 어울리는
                          <br />
                          주얼리를 추천해드리거나 원하는
                          <br />
                          모양을 선택할 수 있습니다.
                        </p>
                      </li>
                      <li className="item item_buy_4">
                        <span>4</span>
                        <strong>최종 가격</strong>
                        <p>
                          다이아몬드와 주얼리의 합리적인
                          <br />
                          최종 가격을 제시합니다.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="diamaond_block">
                  <h3 className="cont_title">고객 판매 절차</h3>
                  <div className="diamaond_process_box">
                    <ul className="diamaond_process_list">
                      <li className="item item_sell_1">
                        <span>1</span>
                        <strong>방문 예약</strong>
                        <p>
                          다이아몬드의 보증서를 상담원에게
                          <br />
                          아려주고 대략적인 가격을 산출하여
                          <br />
                          방문 날짜를 정합니다.
                        </p>
                      </li>
                      <li className="item item_sell_2">
                        <span>2</span>
                        <strong>고객 내방</strong>
                        <p>
                          가까운 한국금거래소에 내방합니다.
                          <br />
                          상담원은 보증서와 고객의
                          <br />
                          다이아몬드를 검사합니다.
                        </p>
                      </li>
                      <li className="item item_sell_3">
                        <span>3</span>
                        <strong>최종 가격</strong>
                        <p>
                          검사가 완료된 다이아몬드의 최종
                          <br />
                          가격을 제시합니다.
                        </p>
                      </li>
                      <li className="item item_sell_4">
                        <span>4</span>
                        <strong>금액 지불</strong>
                        <p>
                          고객님이 최종 가격에 승인을
                          <br />
                          하면 현장에서 금액을 지불합니다.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          {/* <script type="text/javascript" src="/js/jquery-ui.min.js"></script> */}
          {/* <!-- contents end --> */}
        </div>
      </div>
    </>
  );
}
