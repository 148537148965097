import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./App";
import history from "./history";
import { store } from "./modules/store";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactGA from "react-ga";

ReactGA.initialize("UA-40213886-6", { debug: true });
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const isIE = /*@cc_on!@*/ false || !!document.documentMode;
if (isIE) {
  alert(
    "Internet Explorer 브라우저에서 지원되지 않는 사이트 입니다.\n Chrome, Microsoft Edge 브라우저를 이용해주세요."
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
