import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination, Autoplay]);

const MobileBottomSlider = () => {
  return (
    <div className="swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal">
      <Swiper
        className="banner"
        spaceBetween={100}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
      >
        <SwiperSlide>
          <div
            className="m_swiper-slide"
            style={{
              width: "100%",
              opacity: 1,
              margin: "0 auto",
              //   transform: "translate3d(0px, 0px, 0px)",
              transitionDuration: "0ms",
              textAlign: "center",
            }}
          >
            <Link
              to="#"
              onClick={() => window.open("http://m.koreagoldx.co.kr/")}
            >
              <img
                src="https://koreagoldx.hgodo.com/exgold/img/2c0cb467a29dbe77d4fe2ff5f790deaa_27301.jpg"
                alt="기업체 기념품"
              />
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="m_swiper-slide"
            style={{
              width: "100%",
              opacity: 1,
              margin: "0 auto",
              //   transform: "translate3d(-2086px, 0px, 0px)",
              transitionDuration: "0ms",
              textAlign: "center",
            }}
          >
            <Link to="#" onClick={() => window.open("http://m.mbro.co.kr/")}>
              <img
                src="https://koreagoldx.hgodo.com/exgold/img/7bff2dabc78fda719a87808259fd7958_70240.jpg"
                alt="엠브로"
              />
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="m_swiper-slide"
            style={{
              width: "100%",
              opacity: 1,
              margin: "0 auto",
              //   transform: "translate3d(-2086px, 0px, 0px)",
              transitionDuration: "0ms",
              textAlign: "center",
            }}
          >
            <Link
              to="#"
              onClick={() => window.open("http://m.gold24k.goldshell.co.kr/")}
            >
              <img
                src="https://koreagoldx.hgodo.com/exgold/img/a8716c202972729a66d0b2e2d93e8d87_97896.jpg"
                alt="골드쉘 순금주얼리"
              />
            </Link>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="m_swiper-slide"
            style={{
              width: "100%",
              opacity: 1,
              margin: "0 auto",
              //   transform: "translate3d(-2086px, 0px, 0px)",
              transitionDuration: "0ms",
              textAlign: "center",
            }}
          >
            <Link
              to="#"
              onClick={() => window.open("http://m.goldshell.co.kr/")}
            >
              <img
                src="https://koreagoldx.hgodo.com/exgold/img/e1712d11581825b28d2dd713a3cb31c9_42585.jpg"
                alt="골드쉘"
              />
            </Link>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default MobileBottomSlider;
