import React from "react";
import { Link, useLocation } from "react-router-dom";
import mLogo from "../../assets/img/mlogo.png";
import re_btn from "../../assets/img/re_btn.png";
import { routes } from "../../lib/routes";

const MobileHeader = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const check = pathName === "/" || pathName === "" ? false : true;
  return (
    <div style={{ position: "relative" }}>
      <div className="m_sub_header">
        <div className="m_header_wrap subh">
          {check && (
            <div className="re_btn">
              <Link to={routes.main}>
                <img src={re_btn} alt="X" />
              </Link>
            </div>
          )}
          <h1 className="logo">
            <Link to={routes.main}>
              <img src={mLogo} alt="로고" className="mlogo" />
            </Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
