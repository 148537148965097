const SET_TAB = "tab/SET_TAB";
const INIT_TAB = "tab/INIT_TAB";

const INIT_STATE = {
  tab: "companyIntroduce",
};

export default function tab(state = INIT_STATE, action) {
  switch (action.type) {
    case "tab/SET_TAB":
      return { ...state,...action.payload };
    case "tab/INIT_TAB":
      return { ...action.payload };
    default:
      return { ...state };
  }
}

export const setTab = (payload) => {
    return { type: SET_TAB, payload: payload };
};

export const initTab = () => {
  return { type: INIT_TAB, payload: INIT_STATE };
};
