import React from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/sub.css";
import "../../assets/css/common.css";
import allBtn from "../../assets/img/common/all_btn1.png";
import allCross from "../../assets/img/common/all_cross1.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
export default function PrivacyPolicy() {
  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              {" "}
                              뉴스{" "}
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!-- //header_warp --> */}
      <div id="container">
        <div
          id="contents"
          className="terms"
          style={{ minHeight: "400px", padding: "0 0 80px 0" }}
        >
          {/* <!-- 본문 시작 --> */}
          <div className="sub_content">
            <div className="content_box">
              <div className="service_tit">
                <h2
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  개인정보처리방침
                </h2>
              </div>
              <div
                className="service_cont privacy"
                style={{
                  fontWeight: "initial",
                  fontSize: "initial",
                }}
              >
                <br />
                1. 처리하는 개인정보 항목
                <br />
                회사는 고객 문의 서비스 운영을 위해 아래와 같은 개인정보를
                수집하고 있습니다.
                <br />
                ● 필수 입력 사항
                <br />
                - 성명(익명), 비밀번호
                <br />
                ● 선택 입력 사항
                <br />
                - 휴대전화 번호, 첨부파일
                <br />
                ● 서비스 이용과정이나 사업 처리 과정에서 아래와 같은 정보들이
                생성되어 수집될 수 있습니다.
                <br />
                - 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보
                <br />
                <br />
                2. 개인정보 처리 목적
                <br />
                회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
                <br />● 고객 문의 서비스 이용에 따른 본인확인, 개인 식별, 불량
                회원의 부정 이용 방지와 비인가 사용 방지, 불만 처리 등 민원처리,
                고지사항 전달
                <br />
                <br />
                3. 개인정보 수집 방법
                <br />
                회사는 이용자가 입력하는 개인정보 및 귀하의 컴퓨터에서 자동으로
                생성되는 정보( 접속 로그, 쿠키, IP ) 등을 수집합니다.
                <br />
                <br />
                4. 개인정보의 처리 및 보유기간
                <br />
                원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당
                정보를 지체 없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할
                필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한
                기간 동안 회원정보를 보관합니다.
                <br />● 보존 항목 : 문의 접수 시 기재한 개인정보 및 상담내용
                <br />● 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률
                (소비자의 불만 또는 분쟁처리에 관한 기록)
                <br />● 보존 기간 : 3년
                <br />
                <br />
                5. 개인정보보호 책임자 및 담당 부서 등 안내
                <br />
                회사는 고객의 개인정보를 보호하고 개인 정보와 관련한 불만을
                처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리 책임자를
                지정하고 있습니다.
                <br />
                개인정보관리 책임자
                <br /> ● 성명 : 안광호 <br />● 소속 : 경영지원본부
                <br />
                ● 전화번호 : 02-744-9990
                <br />
                <br />
                <br />
                <br />
                귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호
                관련 민원을 개인정보 책임자 혹은 담당 부서로 신고하실 수
                있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한
                답변을 드릴 것입니다.
                <br />
                아래의 기관은 회사와는 별개의 기관으로서, 자체적인 개인정보
                불만처리, 피해 구제 결과에 만족하지 못하시거나 보다 자세한
                도움이 필요하시면 문의하여 주시기 바랍니다.
                <br />
                <br />
                ● 개인정보 침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)
                <br />
                ● 대검찰청 사이버범죄수사단 (www.spo.go.kr / 02-3480-2000)
                <br />● 경찰청 사이버안전국 (www.ctrc.go.kr/ 국번 없이 182)
              </div>
            </div>
            {/* <!-- //content_box --> */}
          </div>
          {/* <!-- //sub_content --> */}
        </div>
        {/* <!-- //본문 끝 contents --> */}
      </div>
      {/* <!-- //container --> */}

      <svg
        id="SvgjsSvg1539"
        width="2"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnssvgjs="http://svgjs.com/svgjs"
        style={{
          overflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1540"></defs>
        <polyline id="SvgjsPolyline1541" points="0,0"></polyline>
        <path id="SvgjsPath1542" d="M0 0 "></path>
      </svg>
    </>
  );
}
