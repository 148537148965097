import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router-dom";

const MainLayout = (props) => {
  const location = useLocation();
  let filter = "win16|win32|win64|mac|macintel";
  let check = "PC";
  if (0 > filter.indexOf(navigator.platform.toLowerCase())) {
    check = "Mobile";
  } else {
    check = "PC";
  }

  return (
    <div>
      <Header />
      <div style={check === "Mobile" && location?.pathname?.includes("company") ? { paddingBottom : "550px"} : {}}>{props.children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;