import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import {numberWithCommas, formatDate} from "../../lib/utils"
// import moment from "moment";
export default function LdnFix() {
  const [londonFix, setLondonFix] = useState([]);
  const [ldnData] = useAsync(APIS.getLondonFix, [], true);
  const { data } = ldnData;
 
  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "Au") {
        arrayData[0] = item;
      }
      if (item.type === "Ag") {
        arrayData[1] = item;
      }
      if (item.type === "Pt") {
        arrayData[2] = item;
      }
      if (item.type === "Pd") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };
  useEffect(() => {
    setLondonFix(reformData(data?.["londonFixingDtoList"]) || []);
  }, [data]);
  return (
    <li style={{ width: "500px", height: "477px", float: "left", marginTop: "15px"}}>
      <div className="ldnfix">
        <h4 className="mdtitl1">London Fix </h4>
        {/* <div className="ldn_m">
          <strong>환율최초고시</strong>
          <strong>1,114.87</strong>
          <span className="redfn">1.10</span>
          <img src={upArrow2} alt="up" />
          <span className="fn_10">2020-11-26 00:00:00</span>
        </div> */}
      </div>
      <table>
        <thead>
          <tr>
            <td>종류</td>
            <td>AM</td>
            <td>PM</td>
          </tr>
        </thead>
        <tbody style={{ background: "#fff"}}>
          <tr>
            <td>{londonFix[0]?.description}</td>
            <td>{numberWithCommas(londonFix[0]?.am)}</td>       
            <td>{numberWithCommas(londonFix[0]?.pm)}</td>
          </tr>
          <tr>
            <td>{londonFix[1]?.description}</td>
            <td colSpan="2">{numberWithCommas(londonFix[1]?.am)}</td>
          </tr>
          <tr>
            <td>{londonFix[2]?.description}</td>
            <td>{numberWithCommas(londonFix[2]?.am)}</td>
            <td>{numberWithCommas(londonFix[2]?.pm)}</td>
          </tr>
          <tr>
            <td>{londonFix[3]?.description}</td>
            <td>{numberWithCommas(londonFix[3]?.am)}</td>
            <td>{numberWithCommas(londonFix[3]?.pm)}</td>
          </tr>
        </tbody>
      </table>
      <p className="gtime" style={{marginTop: "10px"}}>고시시간 :{data ? formatDate(new Date(data?.regdate)): "-"}</p>
    </li>
  );
}
