import React, { useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import "../../assets/css/slick.css";
import allBtn from "../../assets/img/common/all_btn1.png";
import allCross from "../../assets/img/common/all_cross1.png";
import home from "../../../src/assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import SideBar from "../../components/common/SideBar";
export default function History() {
  const [dateRange, setDateRange] = useState("recent");
  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };
  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝-->
	<!-- wrap --> */}
      <div id="wrap">
        {/* <!-- 비쥬얼 박스 시작 --> */}
        <div className="svisual_box">
          <div className="sub_visual5_bg">
            <div className="subvisual_wrap">
              <dl className="sub_vtitl1">
                <dt>회사소개</dt>
                <dd>Company Introduction</dd>
              </dl>
              <div className="location_wrap">
                <div className="location_box">
                  <dl>
                    <dt className="home">
                      <Link to="/">
                        <img src={home} alt="home" />
                      </Link>
                    </dt>
                    <dd className="dep_menu1">
                      <button onClick={dropDownFirstTab} className="dropbtn">
                        회사소개
                      </button>
                      <div id="myDropdown1" className="dropdown-content">
                        <Link to={routes.domesticPriceInquiry}>시세조회</Link>
                        {/* <Link
                          to="/newscenter/newsNoticeList.html"
                          style={{ display: "none" }}
                        >
                          상품안내
                        </Link>
                        <Link
                          to="/newscenter/newsNoticeList.html"
                          style={{ display: "none" }}
                        >
                          기념품제작
                        </Link>
                        <Link
                          to="/newscenter/newsNoticeList.html"
                          style={{ display: "none" }}
                        >
                          뉴스센터
                        </Link>
                        <Link
                          to="/notice/announcementList.html"
                          style={{ display: "none" }}
                        >
                          공지사항
                        </Link> */}
                      </div>
                    </dd>
                    <dd className="dep_menu2">
                      <button onClick={dropDownSecondTab} className="dropbtn">
                        회사연혁
                      </button>
                      <div id="myDropdown2" className="dropdown-content">
                        <Link to={routes.companyIntroduction}>회사소개</Link>
                        <Link to={routes.companyGreeting}>CEO 인사말</Link>
                        <Link to={routes.companyBusinessIntroduction}>
                          사업소개
                        </Link>
                        <Link to={routes.companyBrandIntroduction}>
                          브랜드소개
                        </Link>
                        <Link to={routes.companyDirections}>오시는 길</Link>
                        {/* <Link to={routes.companyFranchiseInvitation}>
                          가맹점 모집
                        </Link> */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_container">
          <div className="sub_container_wrap">
            {/* <!-- lnb start --> */}
            <SideBar />
            {/* <!-- lnb end --> */}
            <div className="contents">
              <div className="con_titl1">
                <h4>회사 연혁</h4>
                <span>Business History</span>
              </div>
              <div className="outer">
                <div className="tab_buttons bhistory">
                  <button
                    className={`${dateRange === "recent" && "active"}`}
                    onClick={() => setDateRange("recent")}
                  >
                    2020-현재
                  </button>
                  <button
                    className={`${dateRange === "5yearsAgo" && "active"}`}
                    onClick={() => setDateRange("5yearsAgo")}
                  >
                    2015-2019
                  </button>
                  <button
                    className={`${dateRange === "10yearsAgo" && "active"}`}
                    onClick={() => setDateRange("10yearsAgo")}
                  >
                    2010-2014
                  </button>
                  <button
                    className={`${dateRange === "15yearsAgo" && "active"}`}
                    onClick={() => setDateRange("15yearsAgo")}
                  >
                    2005-2009
                  </button>
                </div>
                <div className={"tabs"}>
                  <div className={`tab ${dateRange === "recent" && "active"}`}>
                    <ul className="prd_box2">
                      <div className="slick-list history_cont">
                        <dl className="history_right">
                          <dt>2020.03</dt>
                          <dd>플랫폼 APP Biz 호가거래 "센골드" 출시</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2020.05</dt>
                          <dd>실버 유가증권 판매시작(1,000g)</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2020.07</dt>
                          <dd>
                            <span className="ldot"></span>골드 유가증권
                            판매시작(10g)
                          </dd>
                          <dd>
                            <span className="ldot"></span>KRX금시장 위원회 위원
                            위촉(김안모 대표이사)
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2020.10</dt>
                          <dd>대한적십자사 포장증</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2020.12</dt>
                          <dd>매출 1조 8,200억 달성</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2021.01</dt>
                          <dd>코리아테크노브레인(KTB)자회사 편입 인수</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2021.03</dt>
                          <dd>한국조폐공사 근속기념메달 외주가공 연간계약</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2021.07</dt>
                          <dd>KRX금시장 적격금지금생산업자 승인등록(KTB)</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2021.10</dt>
                          <dd>자회사 ㈜한국금거래소FTC 사명변경 (舊 KTB)</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2021.11</dt>
                          <dd>CEO Community 경제전문지 대표이사 성공사례</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2021.12</dt>
                          <dd>
                            플랫폼 APP Biz "금방금방" 개인간 중개거래 개시
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2021.12</dt>
                          <dd>매출 2조 6,300억 달성(KorDA 3,000억 초과달성)</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2022.01</dt>
                          <dd>㈜KVM Indium 임가공 계약체결 (舊 삼성코닝)</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2022.12</dt>
                          <dd>
                            플랫폼 APP Biz"금방금방" 개인間 중개거래 개시
                          </dd>
                          <dd>
                            매출 2조6,300억 달성 (KorDA 3,000억 초과달성)
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2023.10</dt>
                          <dd>한국금거래소FTC 귀금속 전용 정련 공장 완공</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2023.12</dt>
                          <dd>
                            KRX 금시장 누적 거래액 1조 5,760억원
                          </dd>
                          <dd>
                            수출 2조 2,000억원 달성(2015~2023)
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2024.02</dt>
                          <dd>
                            한국금거래소FTC UL2809 인증
                          </dd>
                          <dd>
                            그린 마케팅 선언, 재생금 공급 시작
                          </dd>
                        </dl>
                      </div>
                      {/* </li> */}
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                  <div
                    className={`tab ${dateRange === "5yearsAgo" && "active"}`}
                  >
                    <ul className="prd_box2">
                      <div className="slick-list history_cont">
                        <dl className="history_right">
                          <dt>2015.01</dt>
                          <dd>삼성증권 골드바 판매 계약</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2015.02</dt>
                          <dd>
                            <span className="ldot"></span>제주은행, 이트레이드
                            증권 골드바 판매 계약
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2015.03</dt>
                          <dd>IBK기업은행 골드바 판매 계약</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2015.04</dt>
                          <dd>
                            <span className="ldot"></span>부산은행, 대구은행,
                            HK저축은행 골드바 판매 계약
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2015.05</dt>
                          <dd>신세계몰 우수협력사 표창</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2015.07</dt>
                          <dd>
                            <span className="ldot"></span>삼성저축은행 골드바
                            판매 계약
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2015.09</dt>
                          <dd>
                            ISO9001 인증 획득, BNK저축은행 골드바 판매 계약
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2015.10</dt>
                          <dd>
                            <span className="ldot"></span>대신저축은행,
                            스마트저축은행 골드바 판매 계약
                          </dd>
                          <dd>
                            <span className="ldot"></span>인도 수출 계약
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2015.11</dt>
                          <dd>친애저축은행 골드바 판매 계약</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2015.12</dt>
                          <dd>
                            <span className="ldot"></span>NH농협은행 골드바 판매
                            계약
                          </dd>
                          <dd>
                            <span className="ldot"></span>연매출 1조 120억 달성,
                            누적매출 4조 9870억 돌파
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2016.01</dt>
                          <dd>한국금거래소 청담본점(엠브로) 개관</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2016.12</dt>
                          <dd>
                            <span className="ldot"></span>제1회 보석의날
                            서울특별시장상 수상
                          </dd>
                          <dd>
                            <span className="ldot"></span>53회 무역의날 기념
                            5천만불 수출탑, 산업포장 수상
                          </dd>
                          <dd>
                            <span className="ldot"></span>산업통상자원부
                            장관표창 수상
                          </dd>
                          <dd>
                            <span className="ldot"></span>신한은행장 외환거래
                            감사패
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2017.10</dt>
                          <dd>신세계백화점 골드바 판매 계약 체결</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2017.12</dt>
                          <dd>
                            <span className="ldot"></span>신한은행 골드바 판매
                            계약
                          </dd>
                          <dd>
                            <span className="ldot"></span>2017년 주얼리 함쟝
                            조사 품질 우수업체 수상
                          </dd>
                          <dd>
                            <span className="ldot"></span>54회 무역의날 기념
                            7천불 수출탑 수상
                          </dd>
                          <dd>
                            <span className="ldot"></span>연매출 1조 5천억원
                            달성
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2018.02</dt>
                          <dd>현대홈쇼핑 순금 주얼리 판매</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2018.03</dt>
                          <dd>
                            <span className="ldot"></span>GIA 한국 총동문회
                            세미나 및 감사패 수상
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2018.04</dt>
                          <dd>동서울대학교 협약 체결</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2018.05</dt>
                          <dd>
                            <span className="ldot"></span>조폐공사 외주가공 연간
                            계약
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2018.12</dt>
                          <dd>55회 무역의 날 기념 5억불 수출탑 수상</dd>
                          <dd>E-commerce 온라인몰 28개사 확대 공급</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2019.01</dt>
                          <dd>이마트 골드바 판매 계약 체결</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2019.02</dt>
                          <dd>
                            <span className="ldot"></span>엠브로 다이아몬드클럽
                            론칭
                          </dd>
                        </dl>
                      </div>
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                  <div
                    className={`tab ${dateRange === "10yearsAgo" && "active"}`}
                  >
                    <ul className="prd_box2">
                      <div className="slick-list history_cont">
                        <dl className="history_right">
                          <dt>2010.12</dt>
                          <dd>실시간 국제,국내 금시세 정보 제공</dd>
                          <dd>순금나라 대리점 55개점 개설</dd>
                          <dd>누적매출 1조2천억원</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2011.03</dt>
                          <dd>순금나라 대리점 70개점 개설</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2011.12</dt>
                          <dd>
                            <span className="ldot"></span>KRX) 주관 환리스크
                            관리 최우수 기업상 수상
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2012.12</dt>
                          <dd>한국조폐공사 Gold/Silver Bar 제조 공급 계약</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2013.01</dt>
                          <dd>
                            한국금거래소쓰리엠으로 상호변경, <br />
                            한국금거래소 빌딩으로 사옥이전
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2013.03</dt>
                          <dd>
                            <span className="ldot"></span>한국조폐공사, 국민은행
                            업무제휴
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2013.05</dt>
                          <dd>서울머니쇼 참가</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2013.09</dt>
                          <dd>
                            <span className="ldot"></span>우리은행/경남은행
                            골드바 판매계약
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2014.01</dt>
                          <dd>
                            인천아시안게임 공식후원사 지정,
                            <br /> 공식 골드바/실버바 공급
                          </dd>
                          <dd>
                            연매출 1조 120억 달성, 누적매출 4조 9870억 돌파
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2014.02</dt>
                          <dd>
                            <span className="ldot"></span>신한은행 업무제휴
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2014.04</dt>
                          <dd>
                            제2회 대한민국 귀금속 함량기술콘테스트
                            <br /> 원자재부문 우수상 수상
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2014.06</dt>
                          <dd>
                            <span className="ldot"></span>우리은행 실버바 판매
                            계약
                          </dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2014.10</dt>
                          <dd>하나은행 골드바 판매 계약</dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2014.12</dt>
                          <dd>
                            <span className="ldot"></span>한국조폐공사
                            우수협력사 표창
                          </dd>
                        </dl>
                      </div>
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                  <div
                    className={`tab ${dateRange === "15yearsAgo" && "active"}`}
                  >
                    <ul className="prd_box2">
                      <div className="slick-list history_cont">
                        <dl className="history_right">
                          <dt>1995.01</dt>
                          <dd>
                            <span className="ldot"></span>비치나귀금속 창업
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2005.12</dt>
                          <dd>㈜한국귀금속쓰리엠 창립 연매출 1000억 달성</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2006.12</dt>
                          <dd>
                            <span className="ldot"></span>연매출 2000억 달성
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2007.12</dt>
                          <dd>연매출 2500억 달성</dd>
                        </dl>
                        <dl className="history_right">
                          <dt>2008.12</dt>
                          <dd>
                            <span className="ldot"></span>금매입전문브랜드
                            순금나라 런칭 연매출 3400억 달성
                          </dd>
                        </dl>
                        <dl className="history_left">
                          <dt>2009.12</dt>
                          <dd>연매출 3400억 달성</dd>
                        </dl>
                      </div>
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                </div>
              </div>
              <div className="clear"></div>
              {/* <!-- contents end --> */}
            </div>
          </div>
        </div>
        {/* <!--**컨테이너 영역 끝--> */}
      </div>
      {/* <!-- ////// wrap --> */}

      <svg
        id="SvgjsSvg1001"
        width="2"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnssvgjs="http://svgjs.com/svgjs"
        style={{
          overflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1002"></defs>
        <polyline id="SvgjsPolyline1003" points="0,0"></polyline>
        <path id="SvgjsPath1004" d="M0 0 "></path>
      </svg>
    </>
  );
}
