import React, { useState, useEffect } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import allCross from "../../assets/img/common/all_cross1.png";
import allBtn from "../../assets/img/common/all_btn1.png";
import home from "../../assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import useAsync from "../../lib/hooks/useAsync";
import { createQueryString } from "../../lib/utils";
import * as APIS from "../../lib/api/index";
export default function LondonFixInquiry() {
  const [date, setDate] = useState(new Date().getFullYear().toString());
  const [data, setData] = useState({});
  const queryString = createQueryString({ year: date });
  const [lodonFixData, refetch] = useAsync(
    () => APIS.getLondonFixInquiry(queryString),
    [],
    true
  );

  useEffect(() => {
    setData(lodonFixData.data);
  }, [lodonFixData, date]);

  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };

  const dateKey = Object.keys(data || {});

  // eslint no-extend-native: ["error", { "exceptions": ["Object"] }]
  // eslint freeze: true */
  Array.prototype.division = function (n) {
    var arr = this;
    var len = arr.length;
    var cnt = Math.floor(len / n);
    var tmp = [];

    for (var i = 0; i <= cnt; i++) {
      tmp.push(arr.splice(0, n));
    }

    return tmp;
  };

  const parseDate = dateKey.division(5);

  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.londonFixInquiry}>
                              런던픽스시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>

              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝--> */}
      {/* <!-- 비쥬얼 박스 시작 --> */}
      <div className="svisual_box">
        <div className="sub_visual1_bg">
          <div className="subvisual_wrap">
            <dl className="sub_vtitl1">
              <dt>시세조회</dt>
              <dd>Market Price Check</dd>
            </dl>
            <div className="location_wrap">
              <div className="location_box">
                <dl>
                  <dt className="home">
                    <Link to="/">
                      <img src={home} alt="home" />
                    </Link>
                  </dt>
                  <dd className="dep_menu1">
                    <button onClick={dropDownFirstTab} className="dropbtn">
                      시세조회
                    </button>
                    <div id="myDropdown1" className="dropdown-content">
                      {/* <Link to="/product/productall.html" style={{display: "none"}}>
                        상품안내
                      </Link>
                      <Link to="/souvenir/inquiry.html" style={{display: "none"}}>
                        기념품제작
                      </Link>
                      <Link
                        to="/newscenter/newsNoticeList.html"
                        style={{display: "none"}}
                      >
                        뉴스센터
                      </Link>
                      <Link
                        to="/notice/announcementList.html"
                        style={{display: "none"}}
                      >
                        공지사항
                      </Link> */}
                      <Link to="/company/companysummary.html">회사소개</Link>
                    </div>
                  </dd>
                  <dd className="dep_menu2">
                    <button onClick={dropDownSecondTab} className="dropbtn">
                      런던픽스시세
                    </button>
                    <div id="myDropdown2" className="dropdown-content">
                      <Link to={routes.domesticPriceInquiry}>국내시세</Link>
                      <Link to={routes.internationalPriceInquiry}>
                        국제시세
                      </Link>
                      <Link to={routes.securitiesPriceInquiry}>
                        유가증권시세
                      </Link>
                      <Link to={routes.diamondPriceInquiry}>
                        다이아몬드시세
                      </Link>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub_container">
        <div className="sub_container_wrap">
          {/* <!-- lnb start --> */}
          <div className="lnb">
            <h4 className="lb_titl1">시세조회</h4>
            <ul>
              <li>
                <Link to={routes.domesticPriceInquiry}>국내시세</Link>
              </li>
              <li>
                <Link to={routes.internationalPriceInquiry}>국제시세</Link>
              </li>
              <li>
                <Link to={routes.londonFixInquiry} className="on">
                  런던픽스시세
                </Link>
              </li>
              <li>
                <Link to={routes.securitiesPriceInquiry}>유가증권시세</Link>
              </li>
              <li>
                <Link to={routes.diamondPriceInquiry}>다이아몬드시세</Link>
              </li>
            </ul>
          </div>
          {/* <!-- lnb end --> */}
          {/* <!-- contents start --> */}
          <div className="contents">
            <div className="con_titl1">
              <h4>지난 London Fix 시세</h4>
              <span>Last London Fix Price</span>
            </div>
            <div className="londonfix_cont">
              <div>
                <select
                  defaultValue={date}
                  onChange={(e) => {
                    const { value } = e.target;
                    const query = createQueryString({ year: value });
                    refetch(() => APIS.getLondonFixInquiry(query), "", false);
                    setDate(value);
                  }}
                >
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                </select>
              </div>
              <div>
                <table style={{ width: "100%" }} class="lf_table top">
                  <thead>
                    <tr>
                      <td rowspan="2" colSpan="2" class="lf_date">
                        날짜
                      </td>
                      <td colspan="2">금(Gold)</td>
                      <td colspan="2" className="lf_pk">
                        은(Silver)
                      </td>
                      <td colspan="2">백금(Platinum)</td>
                      <td colspan="2" className="lf_pk">
                        팔라듐(Palladium)
                      </td>
                    </tr>
                    <tr>
                      <td>AM</td>
                      <td>PM</td>
                      <td className="lf_pk" colSpan={2}>
                        -
                      </td>
                      <td>AM</td>
                      <td>PM</td>
                      <td className="lf_pk">AM</td>
                      <td className="lf_pk">PM</td>
                    </tr>
                  </thead>
                </table>
                {(parseDate || []).map((item) => {
                  // item = index , index 안에 5개의 어레이가 있다.
                  return (
                    <table
                      style={{ width: "100%", marginTop: "30px" }}
                      className="lf_table"
                    >
                      {(item || []).map((el, index) => {
                        const au =
                          data?.[el]?.find((item) => item.type === "Au") || {};
                        const ag =
                          data?.[el]?.find((item) => item.type === "Ag") || {};
                        const pt =
                          data?.[el]?.find((item) => item.type === "Pt") || {};
                        const pd =
                          data?.[el]?.find((item) => item.type === "Pd") || {};

                        return (
                          <tr key={index}>
                            <td className="lf_date" colSpan="2">
                              {el}
                            </td>
                            <td>{au?.am || "-"}</td>
                            <td>{au?.pm || "-"}</td>
                            <td className="lf_pk" colSpan={2}>
                              {ag?.am || "-"}
                            </td>
                            <td>{pt?.am || "-"}</td>
                            <td>{pt?.pm || "-"}</td>
                            <td className="lf_pk">{pd?.am || "-"}</td>
                            <td className="lf_pk">{pd?.pm || "-"}</td>
                          </tr>
                        );
                      })}
                    </table>
                  );
                })}
              </div>
            </div>
            <div class="clear"></div>
          </div>
          {/* <!-- contents end --> */}
        </div>
        {/* <!-- contents end --> */}
      </div>
    </>
  );
}
