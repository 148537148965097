import React from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import "../../assets/css/slick.css";
import allBtn from "../../assets/img/common/all_btn1.png";
import allCross from "../../assets/img/common/all_cross1.png";
import storeStep from "../../assets/img/store_step1.png";
import storeStep2 from "../../assets/img/store_step2.png";
import storeStep3 from "../../assets/img/store_step3.png";
import storeStep4 from "../../assets/img/store_step4.png";
import storeStep5 from "../../assets/img/store_step5.png";
import storeStep6 from "../../assets/img/store_step6.png";
import storeStep7 from "../../assets/img/store_step7.png";
import storeStep8 from "../../assets/img/store_step8.png";
import home from "../../../src/assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import SideBar from "../../components/common/SideBar";
export default function FranchiseInvitation() {
  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };
  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝-->
	<!-- wrap --> */}
      <div id="wrap">
        {/* <!-- 비쥬얼 박스 시작 --> */}
        <div className="svisual_box">
          <div className="sub_visual5_bg">
            <div className="subvisual_wrap">
              <dl className="sub_vtitl1">
                <dt>회사소개</dt>
                <dd>Company Introduction</dd>
              </dl>
              <div className="location_wrap">
                <div className="location_box">
                  <dl>
                    <dt className="home">
                      <Link to="/">
                        <img src={home} alt="home" />
                      </Link>
                    </dt>
                    <dd className="dep_menu1">
                      <button onClick={dropDownFirstTab} className="dropbtn">
                        회사소개
                      </button>
                      <div id="myDropdown1" className="dropdown-content">
                        <Link to={routes.domesticPriceInquiry}>시세조회</Link>
                        {/* <Link
                         to="/newscenter/newsNoticeList.html"
                          style={{display: "none"}}
                        >
                          상품안내
                        </Link>
                        <Link  to="/newscenter/newsNoticeList.html" style={{display: "none"}}>
                          기념품제작
                        </Link>
                        <Link
                          to="/newscenter/newsNoticeList.html"
                          style={{display: "none"}}
                        >
                          뉴스센터
                        </Link>
                        <Link
                          to="/notice/announcementList.html"
                          style={{display: "none"}}
                        >
                          공지사항
                        </Link> */}
                      </div>
                    </dd>
                    <dd className="dep_menu2">
                      <button onClick={dropDownSecondTab} className="dropbtn">
                        가맹점모집
                      </button>
                      <div id="myDropdown2" className="dropdown-content">
                        <Link to={routes.companyIntroduction}>회사소개</Link>
                        <Link to={routes.companyGreeting}>CEO 인사말</Link>
                        <Link to={routes.companyHistory}>회사연혁</Link>
                        <Link to={routes.companyBusinessIntroduction}>
                          사업소개
                        </Link>
                        <Link to={routes.companyBrandIntroduction}>
                          브랜드소개
                        </Link>
                        <Link to={routes.companyDirections}>오시는 길</Link>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_container">
          <div className="sub_container_wrap">
            {/* <!-- lnb start --> */}
            <SideBar />
            {/* <!-- lnb end -->
				<!-- contents start --> */}
            <div className="contents">
              <div className="con_titl1">
                <h4>가맹점 모집</h4>
                <span>Recruitment of Franchises</span>
              </div>
              <div className="gold_m_number">
                <dl>
                  <dt>한국금거래소 가맹점 개설 문의</dt>
                  <dd>1544-9993</dd>
                </dl>
              </div>
              <ul className="stroe_numlist">
                <li>
                  <div className="num1">1</div>
                  <dl>
                    <dt>가맹점 개설상담</dt>
                    <dd>전화상담 및 방문 상담하여 드립니다.</dd>
                  </dl>
                  <img src={storeStep} alt="1s_icon" className="icon" />
                </li>
                <li>
                  <div className="num2">2</div>
                  <dl>
                    <dt>상권승인</dt>
                    <dd>해당지역 가맹점 확인 및 상권승인을 해드립니다.</dd>
                  </dl>
                  <img src={storeStep2} alt="2s_icon" className="icon" />
                </li>
                <li>
                  <div className="num3">3</div>
                  <dl>
                    <dt>가맹점 계약</dt>
                    <dd>가맹점 및 점포 계약, 사업자등록 신청</dd>
                  </dl>
                  <img src={storeStep3} alt="3s_icon" className="icon" />
                </li>
                <li>
                  <div className="num4">4</div>
                  <dl>
                    <dt>제품준비</dt>
                    <dd>초도물량 확정 후 제품 주문</dd>
                  </dl>
                  <img src={storeStep4} alt="4s_icon" className="icon" />
                </li>
                <li>
                  <div className="num5">5</div>
                  <dl>
                    <dt>인테리어 공사</dt>
                    <dd>인테리어 업체 선정 및 공사, 판매사원 모집</dd>
                  </dl>
                  <img src={storeStep5} alt="5s_icon" className="icon" />
                </li>
                <li>
                  <div className="num6">6</div>
                  <dl>
                    <dt>교육</dt>
                    <dd>
                      가맹점주 및 판매사원에 대한 보석, 귀금속 교육 가맹본부에서
                      실시, 제품 교육 및 판매, 회사규정 교육
                    </dd>
                  </dl>
                  <img src={storeStep6} alt="6s_icon" className="icon" />
                </li>
                <li>
                  <div className="num7">7</div>
                  <dl>
                    <dt>개점 준비</dt>
                    <dd>
                      인테리어 점검, 디스플레이 점검 및 전반적인 판매 툴 준비
                    </dd>
                  </dl>
                  <img src={storeStep7} alt="7s_icon" className="icon" />
                </li>
                <li>
                  <div className="num8">8</div>
                  <dl>
                    <dt>오픈 행사</dt>
                    <dd>
                      가맹본부와 가맹점 행사 규모 협의 후 오픈 행사, <br />
                      오픈 당일 본사 판매 지원
                    </dd>
                  </dl>
                  <img src={storeStep8} alt="8s_icon" className="icon" />
                </li>
              </ul>
              <div className="clear"></div>
            </div>
            {/* <!--contents--> */}
          </div>
        </div>
        {/* <!--**컨테이너 영역 끝--> */}
      </div>
      {/* <!-- ////// wrap --> */}

      <svg
        id="SvgjsSvg1001"
        width="2"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnssvgjs="http://svgjs.com/svgjs"
        style={{
          overflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1002"></defs>
        <polyline id="SvgjsPolyline1003" points="0,0"></polyline>
        <path id="SvgjsPath1004" d="M0 0 "></path>
      </svg>
    </>
  );
}
