import React from 'react';
import Chart from "react-apexcharts";

// {/* <Chart
// style={{ padding: "15px" }}
// options={state.options}
// series={state.series}
// type="line"
// width="430" */}

const AssetChart = (props) => {
  const {type = "line", width = "400", height= "", series={}, style={}, options = {}  } = props;
  return <Chart 
    type={type}
    options={options}
    series={series}
    width={width}
    height={height}
    style={style}
  />
}

export default AssetChart;