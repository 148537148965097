import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { formatDate, numberWithCommas } from "../../lib/utils";
export default function LiveSpotPrice() {
  const [liveSpotPrice, setLiveSpotPrice] = useState([]);
  const [livespotData, refetch] = useAsync(APIS.getLiveSpotPrice, [], true);
  const { data } = livespotData;
  // const assetValueRef = useRef({
  //   goldBid: "",
  //   goldAsk: "",
  //   silverBid: "",
  //   silverAsk: "",
  //   platinumBid: "",
  //   platinumAsk: "",
  //   palladiumBid: "",
  //   palladiumAsk: "",
  // });

  //소수점 2자리까지 반올림
  const roundNumber = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };
  const compareFluctuationRate = (type, index, data) => {
    switch (type) {
      case "default":
        if (liveSpotPrice[index][data] > 0) {
          return (
            <span className="up_red">{`${roundNumber(
              Math.abs(liveSpotPrice[index][data])
            )}%`}</span>
          );
        } else if (liveSpotPrice[index][data] < 0) {
          return (
            <span className="dw_blue">{`${roundNumber(
              Math.abs(liveSpotPrice[index][data])
            )}%`}</span>
          );
        } else {
          return <span>{"-"}</span>;
        }
      case "arrow":
        if (liveSpotPrice[index][data] > 0) {
          return (
            <>
              <span className="up" style={{ marginLeft: "14px" }}>
                <img src={upArrow} alt="up" />
              </span>
              <span className="counter">
                {numberWithCommas(
                  roundNumber(Math.abs(liveSpotPrice[index][data]))
                )}
              </span>
            </>
          );
        } else if (liveSpotPrice[index][data] < 0) {
          return (
            <>
              <span className="down" style={{ marginLeft: "14px" }}>
                <img src={downArrow} alt="down" />
              </span>
              <span className="counter">
                {numberWithCommas(
                  roundNumber(Math.abs(liveSpotPrice[index][data]))
                )}
              </span>
            </>
          );
        } else {
          return (
            <>
              <span>{"-"}</span>
            </>
          );
        }
      // no default
    }
  };

  // const TextItem = (props) => {

  //   // switch (type) {
  //   //   case "bid":
  //   //     return (
  //   //       <ul>
  //   //         <li>
  //   //           <span className="counter">{props?.bid}</span>
  //   //         </li>
  //   //       </ul>
  //   //     );
  //   //   case "ask":
  //   //     return (
  //   //       <ul id="ASK">
  //   //       <li>
  //   //         <span className="counter">{props?.ask}</span>
  //   //       </li>
  //   //     </ul>
  //   //     );
  //   // }
  // };
  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "AU") {
        arrayData[0] = item;
      }
      if (item.type === "AG") {
        arrayData[1] = item;
      }
      if (item.type === "PT") {
        arrayData[2] = item;
      }
      if (item.type === "PD") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };
  useEffect(() => {
    setLiveSpotPrice(reformData(data?.["liveSpotPriceDtoList"]) || []);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getLiveSpotPrice(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div id="spot_prices">
      <div className="gold_prices_wrap">
        <div className="gold_prices_bg">
          <h3 style={{ marginBottom: "45px" }}>
            LIVE SPOT PRICES
            <span>{data ? formatDate(new Date(data?.regdate)) : "-"}</span>
          </h3>
          <div className="gold_prices_box_new">
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td>Bid</td>
                  <td>Ask</td>
                </tr>
              </thead>
              <tbody>
                {liveSpotPrice?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {item.type
                          .replace("AU", "Gold")
                          .replace("AG", "Silver")
                          .replace("PT", "Platinum")
                          .replace("PD", "Palladium")}
                      </td>
                      <td>
                        <>{numberWithCommas(item.bid)}</>
                        <br />
                        <span>
                          {compareFluctuationRate(
                            "default",
                            index,
                            "bidFluctuationRate"
                          )}
                        </span>
                        <span>
                          {compareFluctuationRate(
                            "arrow",
                            index,
                            "bidFluctuation"
                          )}
                        </span>
                      </td>
                      <td>
                        {numberWithCommas(item.ask)}
                        <br />
                        <span>
                          {compareFluctuationRate(
                            "default",
                            index,
                            "askFluctuationRate"
                          )}
                        </span>
                        <span>
                          {compareFluctuationRate(
                            "arrow",
                            index,
                            "askFluctuation"
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    // 예전 디자인 코드
    /* <div id="spot_prices">
      <div className="gold_prices_wrap">
        <div className="gold_prices_bg">
          <h3>
            LIVE SPOT PRICES
              <span style={{ float: "right", paddingRight: "30px" }}>
                {data ? formatDate(new Date(data?.regdate)) : "-"}
              </span>
          </h3>
          <div className="gold_prices_box">
            <dl className="first" style={{marginTop: "23px"}}>
              <dt></dt>
              <dd>
                <ul>
                  <li>Gold</li>
                  <li>Silver</li>
                  <li>Platinum</li>
                  <li>Palladium</li>
                </ul>
              </dd>
            </dl>
            <dl style={{marginRight: "20px"}}>
              <dt>Bid</dt>
              <dd id="BID"> */
    /* {liveSpotPrice?.map((item) => {
                  // return (
                    <TextItem {...item}/>
                  // )
                })} */
    /* {liveSpotPrice?.map(({ bid }, index) => {
                  return (
                    <ul key={index}>
                      <li>
                        <span className="counter">{numberWithCommas(bid)}</span>
                      </li>
                    </ul>
                  );
                })}
              </dd>
            </dl>
            <dl  style={{marginRight: "20px"}}>
              <dt>Ask</dt>
              <dd>
                {liveSpotPrice?.map(({ ask }, index) => {
                  return (
                    <ul key={index} id="ASK">
                      <li>
                        <span className="counter">{numberWithCommas(ask)}</span>
                      </li>
                    </ul>
                  );
                })}
              </dd>
            </dl>
            <dl style={{width: "113px"}}>
              <dt>+/-</dt>
              <dd>
                <ul className="per_num"  style={{marginRight: "20px"}}>
                  {compareFluctuationRate("arrow", 0)}
                </ul>
                <ul className="per_num"  style={{marginRight: "20px"}}>
                  {compareFluctuationRate("arrow", 1)}
                </ul>
                <ul className="per_num"  style={{marginRight: "20px"}}>
                  {compareFluctuationRate("arrow", 2)}
                </ul>
                <ul className="per_num"  style={{marginRight: "20px"}}>
                  {compareFluctuationRate("arrow", 3)}
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>등락률</dt>
              <dd>
                <ul>
                  <li>{compareFluctuationRate("default", 0)}</li>
                </ul>
                <ul>
                  <li>{compareFluctuationRate("default", 1)}</li>
                </ul>
                <ul>
                  <li>{compareFluctuationRate("default", 2)}</li>
                </ul>
                <ul>
                  <li>{compareFluctuationRate("default", 3)}</li>
                </ul>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div> */
  );
}
