import React from "react";
import { routes } from "../../lib/routes";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setTab } from "../../modules/reducers/tab";
const SideBar = () => {
  const dispatch = useDispatch();
  const tabData = useSelector((state) => state.tab);

  return (
    <div className="lnb">
      <h4 className="lb_titl1">회사소개</h4>
      <ul>
        <li>
          <Link
            name="ceoMessage"
            to={routes.companyIntroduction}
            className={`${tabData.tab === "companyIntroduce" && "on"}`}
            onClick={() => dispatch(setTab({tab: "companyIntroduce"}))}
          >
            회사소개
          </Link>
        </li>
        <li>
          <Link
            name="ceoMessage"
            to={routes.companyGreeting}
            className={`${tabData.tab === "greeting" && "on"}`}
            onClick={() => dispatch(setTab({tab : "greeting"}))}
          >
            CEO 인사말
          </Link>
        </li>
        <li>
          <Link
            name="businessHistory"
            to={routes.companyHistory}
            className={`${tabData.tab === "history" && "on"}`}
            onClick={() => dispatch(setTab({tab : "history"}))}
          >
            회사연혁
          </Link>
        </li>
        <li>
          <Link
            name="businessIntroduction"
            to={routes.companyBusinessIntroduction}
            className={`${tabData.tab === "business" && "on"}`}
            onClick={() => dispatch(setTab({tab : "business"}))}
          >
            사업소개
          </Link>
        </li>
        <li>
          <Link
            name="brandIntroduction"
            to={routes.companyBrandIntroduction}
            className={`${tabData.tab === "brand" && "on"}`}
            onClick={() => dispatch(setTab({tab : "brand"}))}
          >
            브랜드 소개
          </Link>
        </li>
        <li>
          <Link
            name="wayToCome"
            to={routes.companyDirections}
            className={`${tabData.tab === "direction" && "on"}`}
            onClick={() => dispatch(setTab({tab : "direction"}))}
          >
            오시는 길
          </Link>
        </li>
        {/* <li>
          <Link
            name="franchiseInvitation"
            to={routes.companyFranchiseInvitation}
            className={`${tabData.tab === "franchisee" && "on"}`}
            onClick={() => dispatch(setTab({tab : "franchisee"}))}
          >
            가맹점모집
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default SideBar;
