import React, { useEffect, useState } from "react";
import useAsync from "../../lib/hooks/useAsync";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import { Link } from "react-router-dom";
import moment from "moment";
import AssetChart from "../../components/common/AssetChart";
import {
  numberWithCommas,
  createQueryString,
  formatTime,
  formatDate,
} from "../../lib/utils";
import * as APIS from "../../lib/api/index";

const MobileDomesticPrice = () => {
  const [type, setType] = useState("AU");
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  var dayOfWeek = week[new Date().getDay()];
  const [date, setDate] = useState(
    formatTime(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "YYYY-MM-DD"
    )
  );
  const [range, setRange] = useState("month");
  const rangeRef = React.useRef();
  const [unit, setUnit] = useState("gram");
  const [fromDate, setFromDate] = useState(
    formatTime(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      "YYYY-MM-DD"
    )
  );
  const [toDate, setToDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [domesticLivePrice, setDomesticLivePrice] = useState([]);
  const [domesticLivePriceData] = useAsync(APIS.getDomesticLive, [], true);
  const { data } = domesticLivePriceData;

  const [domesticPastPrice, setDomesticPastPrice] = useState([]);

  const actionType = () => APIS.getDomesticProductChart(rangeQueryString);
  const [state, refetchChart] = useAsync(actionType, [], false);
  const [chartData, setChartData] = useState([]);

  const queryString = createQueryString({
    date: formatTime(
      new Date().setFullYear(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate()
      ),
      "YYYY-MM-DD"
    ),
  });

  const [domesticPastPriceData, refetch] = useAsync(
    () => APIS.getDomesticPast(queryString),
    [],
    false
  );
  const searchPastDate = () => {
    const queryString = createQueryString({
      date: formatTime(
        new Date(
          new Date().setFullYear(
            new Date(date).getFullYear(),
            new Date(date).getMonth(),
            new Date(date).getDate()
          )
        ),
        "YYYY-MM-DD"
      ),
    });
    // ) : (
    // queryString = createQueryString({
    //   date: formatTime(
    //       new Date(new Date().setDate(new Date(date).getDate() - 1)),
    //       "YYYY-MM-DD"
    //   )
    // })
    // )
    refetch(() => APIS.getDomesticPast(queryString), "", false);
  };

  const searchChartDate = () => {
    setRange("");
    const queryString = createQueryString({
      type: type,
      from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
      to: formatTime(new Date(toDate), "YYYY-MM-DD"),
    });
    refetchChart(() => APIS.getDomesticProductChart(queryString), "", false);
  };

  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "Au") {
        arrayData[0] = item;
      }
      if (item.type === "Ag") {
        arrayData[1] = item;
      }
      if (item.type === "Pt") {
        arrayData[2] = item;
      }
      if (item.type === "Pd") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };
  const reformDataPast = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "AU") {
        arrayData[0] = item;
      }
      if (item.type === "AG") {
        arrayData[1] = item;
      }
      if (item.type === "PT") {
        arrayData[2] = item;
      }
      if (item.type === "PD") {
        arrayData[3] = item;
      }
      if (item.type === "GC") {
        arrayData[4] = item;
      }
      if (item.type === "SC") {
        arrayData[5] = item;
      }
      if (item.type === "SP") {
        arrayData[6] = item;
      }
      if (item.type === "PSC") {
        arrayData[7] = item;
      }
      if (item.type === "SN") {
        arrayData[8] = item;
      }
      if (item.type === "IR") {
        arrayData[9] = item;
      }
      if (item.type === "RH") {
        arrayData[10] = item;
      }
    });
    return arrayData;
  };

  const compareFluctuationRate = (type, fluctuation) => {
    switch (type) {
      case "arrow":
        if (fluctuation > 0) {
          return (
            <span>
              <span className="first_c">
                <img src={upArrow} alt="up" />
              </span>
              <span className="last_c">{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else if (fluctuation < 0) {
          return (
            <span>
              <span className="first_c">
                <img src={downArrow} alt="down" />
              </span>
              <span className="last_c">{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else {
          return (
            <span>
              <span className="first_c">
                <img src={midd} alt="midd" />
              </span>
            </span>
          );
        }
      // no default
    }
  };

  const headerType = {
    AU: {
      engName: "GOLD",
      korName: "금",
    },
    AG: {
      engName: "SILVER",
      korName: "은",
    },
    PT: {
      engName: "PLATINUM",
      korName: "백금",
    },
    PD: {
      engName: "PALLADIUM",
      korName: "팔라듐",
    },
    USD: {
      engName: "USD",
      korName: "USD",
    },
  };

  const seriesName = headerType[type].korName;
  let arr = [];
  if (chartData?.length > 0) {
    chartData?.forEach((item) => {
      range === "live" && unit === "gram"
        ? arr.push([new Date(item["dateTime"]), item["domesticPrice"]])
        : range === "live" && unit === "3.75gram"
        ? arr.push([new Date(item["dateTime"]), item["domesticPriceDon"]])
        : unit === "gram"
        ? arr.push([new Date(item["date"]), item["domesticPrice"]])
        : arr.push([new Date(item["date"]), item["domesticPriceDon"]]);
    });
  }

  const setting = {
    options: {
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(new Date(val)).format("HH:mm")
                : moment(new Date(val)).format("yyyy.MM.DD");
            return parseDate;
          },
        },
        tickAmount: 3,
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            var val = String(value).split(".");
            if (val.length <= 1) {
              //소수점이 없는 경우
              return numberWithCommas(value);
            } else {
              //소수점이 있는 경우
              return numberWithCommas(value.toFixed(2));
            }
          },
        },
        tickAmount: 3,
      },
      tooltip: {
        x: {
          formatter: function (value) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(value).format("YYYY-MM-DD HH:mm")
                : moment(new Date(value)).format("yyyy년 MM월 DD일");
            return parseDate;
          },
        },
      },
    },
    series: [
      {
        name: seriesName,
        data: arr,
      },
    ],
    fill: {
      colors: ["#fff"],
    },
  };
  useEffect(() => {
    setDomesticLivePrice(reformData(data?.["domesticLivePriceDtoList"]) || []);
  }, [data]);

  useEffect(() => {
    setDomesticPastPrice(reformDataPast(domesticPastPriceData.data) || []);
  }, [domesticPastPriceData.data]);
  const rangeQueryString = createQueryString(
    range === "month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : {
          type: type,
          from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
          to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
        }
  );

  useEffect(() => {
    const queryString = createQueryString(
      range === "month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "live"
        ? {
            type: type,
            from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
            to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
          }
        : {
            type: type,
            from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
            to: formatTime(new Date(toDate), "YYYY-MM-DD"),
          }
    );
    const actionType =
      range === "live"
        ? () => APIS.getDomesticPrice(queryString)
        : () => APIS.getDomesticProductChart(queryString);

    refetchChart(actionType);
    // eslint-disable-next-line
  }, [type, range]);

  useEffect(() => {
    setChartData(state.data);
  }, [state]);

  const onChangeDate = (type) => {
    switch (type) {
      case "live":
        setFromDate(formatTime(new Date(), "YYYY-MM-DD"));
        break;
      case "month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "total":
        setFromDate("2016-01-01");
        break;

      default:
    }
    rangeRef.current = type;
    setRange(type);
  };

  return (
    <div className="m_price_wrap">
      <div className="price_tit">
        <h3>국내시세</h3>
        <p>Domestic Price</p>
      </div>
      <div className="price_cot">
        <div className="outer">
          <div
            className="m_tab_buttons bhistory"
            style={{ width: "100%", display: "block", overflow: "hidden" }}
          >
            <button
              className={`dptab ${type === "AU" ? "active" : ""}`}
              onClick={() => setType("AU")}
            >
              금
            </button>
            <button
              className={`dptab ${type === "AG" ? "active" : ""}`}
              onClick={() => setType("AG")}
            >
              은
            </button>
            <button
              className="dptab"
              className={`dptab  ${type === "PT" ? "active" : ""}`}
              onClick={() => setType("PT")}
            >
              백금
            </button>
            <button
              className={`dptab ${type === "PD" ? "active" : ""}`}
              onClick={() => setType("PD")}
            >
              팔라듐
            </button>
          </div>
          <div className="m_tabs price">
            <div className="m_tab active">
              <div className="m_tab_top">
                <ul className="m_term">
                  <li>
                    <Link
                      to="#"
                      className={range === "live" ? "on" : ""}
                      onClick={() => onChangeDate("live")}
                    >
                      실시간
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={range === "month" ? "on" : ""}
                      onClick={() => onChangeDate("month")}
                    >
                      1개월
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={range === "3month" ? "on" : ""}
                      onClick={() => onChangeDate("3month")}
                    >
                      3개월
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={range === "year" ? "on" : ""}
                      onClick={() => onChangeDate("year")}
                    >
                      1년
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={range === "3year" ? "on" : ""}
                      onClick={() => onChangeDate("3year")}
                    >
                      3년
                    </Link>
                  </li>
                </ul>
                <ul className="donYN">
                  <li className="first">
                    <Link
                      to="#"
                      className={unit === "gram" ? "on" : ""}
                      onClick={() => setUnit("gram")}
                    >
                      ₩/g
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={unit === "3.75gram" ? "on" : ""}
                      onClick={() => setUnit("3.75gram")}
                    >
                      ₩/3.75g
                    </Link>
                  </li>
                </ul>
              </div>
              <ul>
                <li className="m_last">
                  <div className="m_data_day">
                    <input
                      className="datepicker1 hasDatepicker"
                      type="date"
                      id="dp1636523011796"
                      onChange={(e) => {
                        setFromDate(e.target.value);
                      }}
                      value={fromDate}
                      disabled={range === "live" ? true : false}
                    />
                  </div>
                  <div className="m_data_day">
                    <input
                      className="datepicker2 hasDatepicker"
                      type="date"
                      id="dp1636523011801"
                      onChange={(e) => {
                        setToDate(e.target.value);
                      }}
                      value={toDate}
                      disabled={range === "live" ? true : false}
                    />
                  </div>
                  <a href="#" className="m_search_btn1">
                    조회
                  </a>
                </li>
              </ul>
              <div>
                <AssetChart
                  style={{ marginTop: "15px" }}
                  options={setting.options}
                  series={setting.series}
                  type="area"
                  width="100%"
                  height="300px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m_clear"></div>
      <div className="m_today_quo1">
        <h4 className="m_mtit">
          실시간 시세
          <span className="qu_day">
            {data ? formatDate(new Date(data?.regdate)) : "-"}
          </span>
        </h4>
      </div>
      <ul className="m_quo_list" style={{ boxSizing: "border-box" }}>
        <li style={{ boxSizing: "border-box" }}>
          <dl className="gold">
            <dt>금 기준가</dt>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[0]?.domesticPrice)}
              </span>
              <span>(₩/g)</span>
            </dd>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[0]?.domesticPriceDon)}
              </span>
              <span>(₩/3.75g)</span>
            </dd>
            <dd>
              <span>전일대비</span>
              {/* <span>
                <span className="first_c">
                  <img src="/img/up.png" alt="up" />
                </span>
                <span className="last_c">641</span>
              </span> */}
              {compareFluctuationRate("arrow", 0, domesticLivePrice)}
            </dd>
          </dl>
        </li>
        <li style={{ boxSizing: "border-box" }}>
          <dl className="gold">
            <dt>은 기준가</dt>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[1]?.domesticPrice)}
              </span>
              <span>(₩/g)</span>
            </dd>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[1]?.domesticPriceDon)}
              </span>
              <span>(₩/3.75g)</span>
            </dd>
            <dd>
              <span>전일대비</span>
              {compareFluctuationRate("arrow", 1, domesticLivePrice)}
              {/* <span>
                <span className="first_c">
                  <img src="/img/down.png" alt="down" />
                </span>
                <span className="last_c">32</span>
              </span> */}
            </dd>
          </dl>
        </li>
        <li style={{ boxSizing: "border-box" }}>
          <dl className="gold">
            <dt>백금 기준가</dt>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[2]?.domesticPrice)}
              </span>
              <span>(₩/g)</span>
            </dd>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[2]?.domesticPriceDon)}
              </span>
              <span>(₩/3.75g)</span>
            </dd>
            <dd>
              <span>전일대비</span>
              {/* <span>
                <span className="first_c">
                  <img src="/img/down.png" alt="down" />
                </span>
                <span className="last_c">1,164</span>
              </span> */}
              {compareFluctuationRate("arrow", 2, domesticLivePrice)}
            </dd>
          </dl>
        </li>
        <li style={{ boxSizing: "border-box" }}>
          <dl className="gold">
            <dt>팔라듐 기준가</dt>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[3]?.domesticPrice)}
              </span>
              <span>(₩/g)</span>
            </dd>
            <dd>
              <span>
                {numberWithCommas(domesticLivePrice[3]?.domesticPriceDon)}
              </span>
              <span>(₩/3.75g)</span>
            </dd>
            <dd>
              <span>전일대비</span>
              {compareFluctuationRate("arrow", 3, domesticLivePrice)}
            </dd>
          </dl>
        </li>
      </ul>
      <div className="m_clear"></div>
      <div className="m_today_quo1">
        <h4 className="m_mtit" style={{ fontSize: "15px", lineHeight: "30px" }}>
          지난 귀금속 시세
          <div className="m_date_btn" style={{ width: "60%" }}>
            <div className="m_data_day2" style={{ width: "60%" }}>
              <input
                className="datepicker1 hasDatepicker"
                type="date"
                onChange={(e) => {
                  setDate(e.target.value);
                }}
                value={date}
              />
            </div>
            <button
              style={{ marginLeft: "5px", width: "20%" }}
              onClick={searchPastDate}
              className="m_search_btn1"
            >
              조회
            </button>
          </div>
        </h4>
      </div>
      <div className="m_tl_line">
        <div className="m_gold_table1" style={{ border: "none" }}>
          <table summary="귀금속 품목">
            {domesticPastPrice?.length === 0 ? (
              <>
                <colgroup>
                  <col style={{ width: "100%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ top: "161px", fontSize: "20px" }}>
                      해당 날짜에 해당하는 <br />
                      데이터가 존재하지 않습니다.
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <>
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "35%" }} />
                  <col style={{ width: "25%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>귀금속 품목</th>
                    <th>기준가 (₩/g)</th>
                    <th>기준가</th>
                    <th>
                      전일대비 <br />
                      기준값(₩/g)
                    </th>
                  </tr>
                </thead>
                {domesticPastPrice?.map((item, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <th>
                          {item.description}
                          {/* {item.type === "SP" ? " (원/kg)" : ""} */}
                        </th>
                        <td
                          className="tal40"
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          {numberWithCommas(item.domesticPrice)}
                        </td>
                        <td
                          className="tal40"
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          {numberWithCommas(item.domesticPriceDon)}
                          {item.type === "AU" ||
                          item.type === "AG" ||
                          item.type === "PT" ||
                          item.type === "PD"
                            ? "(₩/3.75g)"
                            : item.type === "GC" ||
                              item.type === "IR" ||
                              item.type === "RH"
                            ? "(₩/100g)"
                            : "(₩/1kg)"}
                        </td>
                        <td>
                          {compareFluctuationRate("arrow", item.fluctuation)}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default MobileDomesticPrice;
