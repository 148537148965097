import React, { useState, useEffect } from "react";
import { routes } from "./lib/routes";
import {
  Route,
  Switch,
  withRouter,
  BrowserRouter as Router,
  Redirect,
  useLocation,
} from "react-router-dom";
import MainLayout from "./components/common/MainLayout";
import Main from "./pages/main/Main";
import TermService from "./pages/main/TermService";
import Loading from "./components/common/Loading";
import DomesticPriceInquiry from "./pages/price-inquiry/DomesticPriceInquiry";
import InternationalPriceInquiry from "./pages/price-inquiry/InternationalPriceInquiry";
import DiamondPriceInquiry from "./pages/price-inquiry/DiamondPriceInquiry";
import SecuritiesPriceInquiry from "./pages/price-inquiry/SequritiesPriceInquiry";
import { useSelector } from "react-redux";
import BrandIntroduction from "./pages/company/BrandIntroduction";
import BusinessIntroduction from "./pages/company/BusinessIntroduction";
import Greeting from "./pages/company/Greeting";
import CompanyIntroduction from "./pages/company/CompanyIntroduction";
import Directions from "./pages/company/Directions";
import FranchiseInvitation from "./pages/company/FranchiseInvitation";
import History from "./pages/company/History";
import PrivacyPolicy from "./pages/main/PrivacyPolicy";
import LondonFixInquiry from "./pages/price-inquiry/LondonFixInquiry";
//import Inspection from "./ Inspection";
import MainPopup from "./modal/MainPopup";
import MobileMainPopup from "./modal/MobileMainPopup";
import histroy from "./history";
import MobileMainLayout from "./pages/mobileCommon/MobileMainLayout";
import MobileMain from "./pages/mobileMain/MobileMain";
import MobileBrandIntroduction from "./pages/mobileCompany/MobileBrandIntroduction";
import MobileIntroductionCompany from "./pages/mobileCompany/MobileIntroductionCompay";
import MobileCEOMessage from "./pages/mobileCompany/MobileCEOMessage";
import MobileBusinessIntroduction from "./pages/mobileCompany/MobileBusinessIntroduction";
import MobileFranchiseInvitation from "./pages/mobileCompany/MobileFranchiseInvitation";
import MobileHistory from "./pages/mobileCompany/MobileHistory";
import MobilePremiumPrice from "./pages/mobilePrice/MobilePremiumPrice";
import MobileDomesticPrice from "./pages/mobilePrice/MobileDomesticPrice";
import MobileInternationalPrice from "./pages/mobilePrice/MobileInternationalPrice";
import MobileLdnFix from "./pages/mobilePrice/MobileLdnFix";
import MobileDiamondPrice from "./pages/mobilePrice/MobileDiamondPrice";
import MobileSequrityPrice from "./pages/mobilePrice/MobileSequrityPrice";
import MobileWayToCome from "./pages/mobileCompany/MobileWayToCome";
import MobileTermService from "./pages/mobileMain/MobileTermService";
import MobilePolicy from "./pages/mobileMain/MobilePolicy";

function App() {
  const loading = useSelector((state) => state.loading);
  const [size, setSize] = useState(window.innerWidth);
  // const [isFirst, setFirst] = useState(true);
  // const location = useLocation();

  const handleResize = () => {
    const body = document.getElementById("root").getBoundingClientRect();
    setSize(body.width);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let filter = "win16|win32|win64|mac|macintel";
  let check = "PC";
  if (0 > filter.indexOf(navigator.platform.toLowerCase())) {
    check = "Mobile";
  } else {
    check = "PC";
  }
  const mobile = check === "Mobile" || size <= 912 ? true : false;
  return (
    <>
      {mobile ? (
        <MobileMainLayout>
          <Router histroy={histroy}>
            <Switch>
              <Route exact path={"/index_m.htm"}>
                <Redirect to="/" />
              </Route>
              <Route exact path={routes.main} component={MobileMain} />
              <Route
                exact
                path={routes.companyBrandIntroduction}
                component={MobileBrandIntroduction}
              />
              <Route
                exact
                path={routes.companyIntroduction}
                component={MobileIntroductionCompany}
              />
              <Route
                exact
                path={routes.companyGreeting}
                component={MobileCEOMessage}
              />
              <Route
                exact
                path={routes.companyBusinessIntroduction}
                component={MobileBusinessIntroduction}
              />
              <Route
                exact
                path={routes.companyFranchiseInvitation}
                component={MobileFranchiseInvitation}
              />
              <Route
                exact
                path={routes.companyHistory}
                component={MobileHistory}
              />
              <Route
                exact
                path={routes.premiumPrice}
                component={MobilePremiumPrice}
              />
              <Route
                exact
                path={routes.domesticPriceInquiry}
                component={MobileDomesticPrice}
              />
              <Route
                exact
                path={routes.internationalPriceInquiry}
                component={MobileInternationalPrice}
              />
              <Route
                exact
                path={routes.londonFixInquiry}
                component={MobileLdnFix}
              />
              <Route
                exact
                path={routes.diamondPriceInquiry}
                component={MobileDiamondPrice}
              />
              <Route
                exact
                path={routes.securitiesPriceInquiry}
                component={MobileSequrityPrice}
              />
              <Route
                exact
                path={routes.companyDirections}
                component={MobileWayToCome}
              />
              <Route
                exact
                path={routes.termService}
                component={MobileTermService}
              />
              <Route
                exact
                path={routes.privacyPolicy}
                component={MobilePolicy}
              />
            </Switch>
            {/* {isFirst && location.pathname === "/" && (
              <MobileMainPopup setFirst={setFirst} />
            )} */}
          </Router>
        </MobileMainLayout>
      ) : (
        <>
          <div id="main" />
          <Router histroy={histroy}>
            <MainLayout>
              <Switch>
                <Route exact path={"/index_m.htm"}>
                  <Redirect to="/" />
                </Route>
                <Route exact path={routes.main} component={Main} />
                <Route
                  path={routes.domesticPriceInquiry}
                  component={DomesticPriceInquiry}
                />
                <Route
                  path={routes.internationalPriceInquiry}
                  component={InternationalPriceInquiry}
                />
                <Route
                  path={routes.londonFixInquiry}
                  component={LondonFixInquiry}
                />
                <Route
                  path={routes.diamondPriceInquiry}
                  component={DiamondPriceInquiry}
                />
                <Route
                  path={routes.securitiesPriceInquiry}
                  component={SecuritiesPriceInquiry}
                />
                <Route
                  exact
                  path={routes.companyIntroduction}
                  component={CompanyIntroduction}
                />
                <Route path={routes.companyGreeting} component={Greeting} />
                <Route path={routes.companyHistory} component={History} />
                <Route
                  path={routes.companyBusinessIntroduction}
                  component={BusinessIntroduction}
                />
                <Route
                  path={routes.companyBrandIntroduction}
                  component={BrandIntroduction}
                />
                <Route path={routes.companyDirections} component={Directions} />
                <Route
                  path={routes.companyFranchiseInvitation}
                  component={FranchiseInvitation}
                />
                <Route path={routes.termService} component={TermService} />
                <Route path={routes.privacyPolicy} component={PrivacyPolicy} />
              </Switch>
            </MainLayout>
            {/* {isFirst && location.pathname === "/" && (
              <MainPopup setFirst={setFirst} />
            )} */}
            {loading.isLoading && <Loading />}
          </Router>
        </>
      )}
    </>
  );
}

export default withRouter(App);
