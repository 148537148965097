import React, { useLayoutEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

const MobileEtcHeader = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const tabRef = useRef("");
  const [value, setValue] = useState(0);
  const forceUpdate = () => setValue((value) => value + 1);

  const tabs = [
    { title: "시세조회", key: "price" },
    { title: "매매프리미엄", key: "premiumPrice" },
    { title: "회사소개", key: "company" },
  ];

  useLayoutEffect(() => {
    tabRef.current = tabs.find((item) => pathName.includes(item.key)) || "-";
    forceUpdate();
  }, [pathName]);

  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "m_dropdown-content") {
      clickFirstTab.className = "m_dropdown-content show";
    } else {
      clickFirstTab.className = "m_dropdown-content";
    }
  };

  const dropDownSecondTab = () => {
    if (
      tabRef?.current?.key === "premiumPrice" ||
      tabRef?.current?.key === undefined
    ) {
      return;
    }
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "m_dropdown-content") {
      clickSecondTab.className = "m_dropdown-content show";
    } else {
      clickSecondTab.className = "m_dropdown-content";
    }
  };

  const firstTab = [
    { title: "시세조회", path: "/price/inquiry/domestic", key: "price" },
    { title: "매매프리미엄", path: "/premiumPrice", key: "premiumPrice" },
    { title: "회사소개", path: "/company/introduction", key: "company" },
  ];
  const secondTab = [
    {
      path: "/company/introduction",
      description: "companyIntroduction",
      title: "회사소개",
    },
    {
      path: "/company/greeting",
      description: "companyGreeting",
      title: "CEO 인사말",
    },
    {
      path: "/company/history",
      description: "companyHistory",
      title: "회사연혁",
    },
    {
      path: "/company/introduction/business",
      description: "companyBusinessIntroduction",
      title: "사업소개",
    },
    {
      path: "/company/introduction/brand",
      description: "companyBrandIntroduction",
      title: "브랜드소개",
    },
    {
      path: "/company/directions",
      description: "companyDirections",
      title: "오시는 길",
    },
    // {
    //   path: "/company/franchise/invitation",
    //   description: "companyFranchiseInvitation",
    //   title: "가맹점 모집",
    // },
  ];

  const thirdTab = [
    {
      title: "국내시세",
      path: "/price/inquiry/domestic",
      key: "domesticPriceInquiry",
    },
    {
      title: "국제시세",
      path: "/price/inquiry/international",
      key: "internationalPriceInquiry",
    },
    {
      title: "런던픽스시세",
      path: "/price/inquiry/londonfix",
      key: "londonFixInquiry",
    },
    {
      title: "유가증권시세",
      path: "/price/inquiry/security",
      key: "securitiesPriceInquiry",
    },
    {
      title: "다이아몬드시세",
      path: "/price/inquiry/diamond",
      key: "diamondPriceInquiry",
    },
  ];

  const filterFirstTab = firstTab.filter(
    (item) => !item.path.includes(tabRef.current.key)
  );
  const filterSecondTab = secondTab.filter((item) => item.path !== pathName);
  const filterThirdTab = thirdTab.filter((item) => item.path !== pathName);

  return (
    <div
      className="m_visual_tab"
      style={{
        position: "fixed",
        width: "100%",
        height: "50px",
        top: "0px",
        marginBottom: "45px",
        display: pathName !== "/premiumPrice" ? "block" : "none",
        zIndex: 100,
      }}
    >
      <ul>
        <li className="m_dep_menu1" onClick={dropDownFirstTab}>
          <button className="m_dropbtn">
            {firstTab.find((item) => item.path.includes(tabRef.current.key))
              ?.title || "-"}
          </button>
          <div id="myDropdown1" className="m_dropdown-content">
            {filterFirstTab.map((item) => {
              return <Link to={item.path}>{item.title}</Link>;
            })}
          </div>
        </li>
        <li className="dep_menu2" onClick={dropDownSecondTab}>
          <button className="m_dropbtn">
            {tabRef?.current?.key === "premiumPrice"
              ? "매매프리미엄"
              : tabRef?.current?.key === "price"
              ? thirdTab.find((item) => item.path.includes(pathName))?.title ||
                "-"
              : secondTab.find((item) => item.path.includes(pathName))?.title ||
                "-"}
          </button>
          <div id="myDropdown2" className="m_dropdown-content">
            {(
              (tabRef.current.key === "price"
                ? filterThirdTab
                : filterSecondTab) || []
            ).map((item) => {
              return <Link to={item.path}>{item.title}</Link>;
            })}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default MobileEtcHeader;
