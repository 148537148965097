import React, { useEffect, useState } from "react";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import banner from "../../assets/img/mbanner1.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import right_btn from "../../assets/img/right_btn.png";

const MobileSecurity = () => {
  const [stockPrice, setStockPrice] = useState([]);
  const [stockPriceData, refetch] = useAsync(APIS.getStockPrice, [], true);
  const { data } = stockPriceData;

  const [purchasePrice, setPurchasePrice] = useState([]);
  const [purchasePriceData] = useAsync(APIS.getPurchasePrice, [], true);
  const { data: purchaseData } = purchasePriceData;

  const [londonFix, setLondonFix] = useState([]);
  const [ldnData] = useAsync(APIS.getLondonFix, [], true);
  const { data: londonData } = ldnData;

  const [metalPrice, setMetalPrice] = useState([]);
  const [metalPriceData] = useAsync(APIS.getMetalPrices, [], true);
  const { data: metalData } = metalPriceData;

  const reformDataMetal = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "Cu") {
        arrayData[0] = item;
      }
      if (item.type === "Rh") {
        arrayData[1] = item;
      }
      if (item.type === "In") {
        arrayData[2] = item;
      }
      if (item.type === "Ir") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };

  const reformDataLdn = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "Au") {
        arrayData[0] = item;
      }
      if (item.type === "Ag") {
        arrayData[1] = item;
      }
      if (item.type === "Pt") {
        arrayData[2] = item;
      }
      if (item.type === "Pd") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };

  const compareFluctuationRate = (type, index) => {
    switch (type) {
      case "default":
        if (stockPrice[index]?.fluctuationRate > 0) {
          return (
            <td className="ch_up">
              {Math.abs(stockPrice[index]?.fluctuationRate)}%
            </td>
          );
        } else if (stockPrice[index]?.fluctuationRate < 0) {
          return (
            <td className="ch_dw">
              {Math.abs(stockPrice[index]?.fluctuationRate)}%
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (stockPrice[index]?.fluctuation > 0) {
          return (
            <td>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(Math.abs(stockPrice[index]?.fluctuation))}
              </span>
            </td>
          );
        } else if (stockPrice[index]?.fluctuation < 0) {
          return (
            <td>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(Math.abs(stockPrice[index]?.fluctuation))}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      // no default
    }
  };

  const compareFluctuationRateMetal = (type, index) => {
    switch (type) {
      case "arrow":
        if (metalPrice[index]?.sign === "+") {
          return (
            <>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">{metalPrice[index]?.fluctuation}</span>
            </>
          );
        } else if (metalPrice[index]?.sign === "-") {
          if (metalPrice[index]?.fluctuation === 0) {
            return (
              // <td>
              <img src={midd} alt="midd" />
              // </td>
            );
          }
          return (
            <>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {`${metalPrice[index]?.fluctuation}`}
              </span>
            </>
          );
        }
      // no default
    }
  };

  const compareFluctuationPurchasePriceRate = (type, index) => {
    switch (type) {
      case "default":
        if (purchasePrice[index]?.fluctuationRate > 0) {
          return (
            <td className="ch_up">
              {Math.abs(purchasePrice[index]?.fluctuationRate)}%
            </td>
          );
        } else if (purchasePrice[index]?.fluctuationRate < 0) {
          return (
            <td className="ch_dw">
              {Math.abs(purchasePrice[index]?.fluctuationRate)}%
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (purchasePrice[index]?.fluctuation > 0) {
          return (
            <td>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(Math.abs(purchasePrice[index]?.fluctuation))}
              </span>
            </td>
          );
        } else if (purchasePrice[index]?.fluctuation < 0) {
          return (
            <td>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(Math.abs(purchasePrice[index]?.fluctuation))}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      // no default
    }
  };
  useEffect(() => {
    setPurchasePrice(purchaseData?.["purchasePriceMainDtoList"] || []);
  }, [purchaseData]);

  useEffect(() => {
    setStockPrice(data?.["securityMainDtoList"] || []);
  }, [data]);

  useEffect(() => {
    setLondonFix(reformDataLdn(londonData?.["londonFixingDtoList"]) || []);
  }, [londonData]);

  useEffect(() => {
    setMetalPrice(reformDataMetal(metalData?.["preciousMetalsDtoList"]) || []);
  }, [metalData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getStockPrice(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="m_mcontainer1">
        <div
          data-aos="zoom-in"
          className="m_mmiddle_area4 aos-init aos-animate"
        >
          <ul id="down_price" className="m_pro_money2">
            <li>
              <h4 className="m_mtit">
                유가증권 시세
                <div className="m_more_btn">
                  <Link to={routes.securitiesPriceInquiry}>
                    더보기
                    <img src={right_btn} alt=">" />
                  </Link>
                </div>
              </h4>
              <table className="m_tit_tabl3">
                <thead>
                  <tr>
                    <td>권종</td>
                    <td>판매가격</td>
                    <td>변동</td>
                    <td>등락률</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>골드바 10g</td>
                    <td>{`${numberWithCommas(stockPrice[0]?.price)}`}원</td>
                    {compareFluctuationRate("arrow", 0)}
                    {compareFluctuationRate("default", 0)}
                  </tr>
                  <tr>
                    <td>실버바 1kg</td>
                    <td>{`${numberWithCommas(stockPrice[1]?.price)}`}원</td>
                    {compareFluctuationRate("arrow", 1)}
                    {compareFluctuationRate("default", 1)}
                  </tr>
                </tbody>
              </table>
              <p className="m_gtime">
                고시시간 : {data ? formatDate(new Date(data?.regdate)) : "-"}
              </p>
              <div className="m_banner_img1">
                <img
                  src={banner}
                  style={{ width: "100%" }}
                  alt="한국금거래소 유가증권 이것을 알아야 성공한다."
                />
              </div>
            </li>
            <div className="m_clear"></div>
            <li>
              <h4 className="m_mtit">사업자 매입 시세</h4>
              <table>
                <thead>
                  <tr>
                    <td>종류</td>
                    <td>시세</td>
                    <td>변동</td>
                    <td>등락률</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>순금시세</td>
                    <td>{numberWithCommas(purchasePrice[0]?.price)}</td>
                    {compareFluctuationPurchasePriceRate("arrow", 0)}
                    {compareFluctuationPurchasePriceRate("default", 0)}
                  </tr>
                  <tr>
                    <td>18k 금시세</td>
                    <td>{numberWithCommas(purchasePrice[1]?.price)}</td>
                    {compareFluctuationPurchasePriceRate("arrow", 1)}
                    {compareFluctuationPurchasePriceRate("default", 1)}
                  </tr>
                  <tr>
                    <td>14k 금시세</td>
                    <td>{numberWithCommas(purchasePrice[2]?.price)}</td>
                    {compareFluctuationPurchasePriceRate("arrow", 2)}
                    {compareFluctuationPurchasePriceRate("default", 2)}
                  </tr>
                  <tr>
                    <td>백금시세</td>
                    <td>{numberWithCommas(purchasePrice[3]?.price)}</td>
                    {compareFluctuationPurchasePriceRate("arrow", 3)}
                    {compareFluctuationPurchasePriceRate("default", 3)}
                  </tr>
                  <tr>
                    <td>은시세</td>
                    <td>{numberWithCommas(purchasePrice[4]?.price)}</td>
                    {compareFluctuationPurchasePriceRate("arrow", 4)}
                    {compareFluctuationPurchasePriceRate("default", 4)}
                  </tr>
                </tbody>
              </table>
              <p className="m_gtime">
                고시시간 :{" "}
                {purchaseData
                  ? formatDate(new Date(purchaseData?.regdate))
                  : "-"}
              </p>
            </li>
            <div className="m_clear"></div>
            <li>
              <div className="m_ldnfix">
                <h4 className="m_mtit">
                  London Fix
                  <div className="m_more_btn">
                    <Link to={routes.londonFixInquiry}>
                      더보기
                      <img src={right_btn} alt=">" />
                    </Link>
                  </div>
                </h4>
              </div>
              <table>
                <thead>
                  <tr>
                    <td>종류</td>
                    <td>AM</td>
                    <td>PM</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{londonFix[0]?.description}</td>
                    <td>{numberWithCommas(londonFix[0]?.am)}</td>
                    <td>{numberWithCommas(londonFix[0]?.pm)}</td>
                  </tr>
                  <tr>
                    <td>{londonFix[1]?.description}</td>
                    <td colSpan="2">{numberWithCommas(londonFix[1]?.am)}</td>
                  </tr>
                  <tr>
                    <td>{londonFix[2]?.description}</td>
                    <td>{numberWithCommas(londonFix[2]?.am)}</td>
                    <td>{numberWithCommas(londonFix[2]?.pm)}</td>
                  </tr>
                  <tr>
                    <td>{londonFix[3]?.description}</td>
                    <td>{numberWithCommas(londonFix[3]?.am)}</td>
                    <td>{numberWithCommas(londonFix[3]?.pm)}</td>
                  </tr>
                </tbody>
              </table>
              <p className="m_gtime">
                고시시간 :
                {londonData ? formatDate(new Date(londonData?.regdate)) : "-"}
              </p>
            </li>
            <div className="m_clear"></div>
            <li>
              <h4 className="m_mtit">기타 금속 시세</h4>
              <table>
                <thead>
                  <tr>
                    <td>종류</td>
                    <td>시세</td>
                    <td>변동</td>
                    <td>날짜</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>구리(USD/LBS)</td>
                    <td>{numberWithCommas(metalPrice[0]?.price)}</td>
                    <td>{compareFluctuationRateMetal("arrow", 0)}</td>
                    <td>
                      {metalPrice[3]
                        ? formatDate(new Date(metalPrice[0]?.regdate))
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>로듐(USD/OZ)</td>
                    <td>{numberWithCommas(metalPrice[1]?.price)}</td>
                    <td>{compareFluctuationRateMetal("arrow", 1)}</td>
                    <td>
                      {metalPrice[0]
                        ? formatDate(new Date(metalPrice[1]?.regdate))
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>인듐(USD/KG)</td>
                    <td>{numberWithCommas(metalPrice[2]?.price)}</td>
                    <td>{compareFluctuationRateMetal("arrow", 2)}</td>
                    <td>
                      {metalPrice[2]
                        ? formatDate(new Date(metalPrice[2]?.regdate))
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>이리듐(USD/OZ)</td>
                    <td>{numberWithCommas(metalPrice[3]?.price)}</td>
                    <td>{compareFluctuationRateMetal("arrow", 3)}</td>
                    <td>
                      {metalPrice[1]
                        ? formatDate(new Date(metalPrice[3]?.regdate))
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="m_gtime">
                고시시간 :{" "}
                {metalData ? formatDate(new Date(metalData?.regdate)) : "-"}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileSecurity;
