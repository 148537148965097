import React, { useState } from "react";
import goldExchange04 from "../../assets/img/img_goldexchange_04.png";
import goldShell04 from "../../assets/img/img_Bi_glodshell04.png";
import goldWorld04 from "../../assets/img/img_BI_goldworld04.png";
import mbro04 from "../../assets/img/img_mbro_04.png";

const MobileBrandIntroduction = () => {
  const [active, setActive] = useState("int_img1");
  return (
    <div className="m_company_wrap">
      <div className="company_tit">
        <h3>브랜드소개</h3>
        <p>Brand Introduction</p>
      </div>

      <div className="outer">
        <div className="m_tab2s_abrec">
          <div className="m_tab_buttons bhistory int_img">
            <button
              className={
                active === "int_img1" ? "m_active int_img1" : "m_int_img1"
              }
              onClick={() => setActive("int_img1")}
            />
            <button
              className={
                active === "int_img2" ? "m_active int_img2" : "m_int_img2"
              }
              onClick={() => setActive("int_img2")}
            />
            <button
              className={
                active === "int_img3" ? "m_active int_img3" : "m_int_img3"
              }
              onClick={() => setActive("int_img3")}
            />
            <button
              className={
                active === "int_img4" ? "m_active int_img4" : "m_int_img4"
              }
              onClick={() => setActive("int_img4")}
            />
          </div>
        </div>
        <div className="m_tabs">
          <div className={`tab ${active === "int_img1" ? "active" : ""}`}>
            <ul className="m_prd_box2">
              <li>
                <p className="prd_tits1">대한민국 최고의 귀금속 브랜드</p>
                <h6 className="prd_tits2">한국금거래소</h6>
              </li>
              <li>
                <img
                  src={goldExchange04}
                  alt="goldexchange4"
                  style={{ width: "100%" }}
                />
                <p className="prd_s1">
                  수시조회 4만~8만여 고객 및 회원의 관심을 기초로
                  <br /> 가장 빠르고 정확한 귀금속 정보를 드리기 위해 <br />
                  오늘도 매진하고 있습니다.
                </p>
                <p className="prd_s2">
                  한국금거래소는 귀금속 거래의 투명성 확보를 위해
                  <br /> 실시간 시세 정보를 제공하며,
                  <br /> 컨설팅을 통해 귀금속 전반에 대한 다양한 서비스를
                  <br /> 제공합니다.
                </p>
              </li>
            </ul>
          </div>
          <div className={`tab ${active === "int_img2" ? "active" : ""}`}>
            <ul className="m_prd_box2">
              <li>
                <p className="prd_tits1">
                  내면으로부터 시작되는 우아한 아름다움
                </p>
                <h6 className="prd_tits2">골드쉘</h6>
              </li>
              <li>
                <img
                  src={goldShell04}
                  alt="goldshell4"
                  style={{ width: "100%" }}
                />
                <p className="prd_s1">
                  근거 있는 아름다움을 소유한 당신의 오브제,
                  <br /> GOLDSHELL 여성의 우아한 아름다움은 <br />
                  내면의 가치로부터 시작됩니다.
                </p>
                <p className="prd_s2">
                  부드럽고 강인한 여성을 표현하는 골드쉘은
                  <br /> 자신의 고유한 빛을 찾아가는 이들의 신념과 만나
                  <br /> 그 누구도 흉내낼 수 없는 아우라로 완성됩니다.
                </p>
              </li>
            </ul>
          </div>
          <div className={`tab ${active === "int_img3" ? "active" : ""}`}>
            <ul className="m_prd_box2">
              <li>
                <p className="prd_tits1">영롱한 빛을 간직한 순금</p>
                <h6 className="prd_tits2">순금나라</h6>
              </li>
              <li>
                <img
                  src={goldWorld04}
                  alt="goldworld4"
                  style={{ width: "100%" }}
                />
                <p className="prd_s1">
                  금은 휘귀성, 연속성, 그리고 빛의 찬란함 때문에
                  <br /> 장신구와 화폐로 많은 사랑을 받아오고 있습니다.
                  <br /> 현대에 이르러 반도체, 의료용 등<br /> 그 쓰임이 많아
                  지속적으로 관심을 받아오고 있으며,
                  <br />그 가치는 상승하고 있습니다.
                </p>
                <p className="prd_s2">
                  순금나라는 영롱한 빛을 간직한 <br />
                  순금 장신구 및 다양한 기념품 서비스를 제공합니다.
                </p>
              </li>
            </ul>
          </div>
          <div className={`tab ${active === "int_img4" ? "active" : ""}`}>
            <ul className="m_prd_box2">
              <li>
                <p className="prd_tits1">
                  최고의 세공 기술을 가진 명장의 고품격 주얼리
                </p>
                <h6 className="prd_tits2">엠브로</h6>
              </li>
              <li>
                <img src={mbro04} alt="mbro4" style={{ width: "100%" }} />
                <p className="prd_s1">
                  명장이 직접 핸드메이드로 완성하는 주얼리는
                  <br /> 감각적인 컴템포러리 주얼리인 동시에 <br />
                  클래식한 웅장함을 표현합니다.
                </p>
                <p className="prd_s2">
                  40여 년 귀금속 세공에 열정을 쏟은
                  <br /> 명장의 작품 세계가 녹아 있는 주얼리는
                  <br /> 다양하고 황홀한 빛깔의 천연 보석들이 세팅되어
                  <br /> 찬란한 빛을 더합니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBrandIntroduction;
