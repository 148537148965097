import moment from "moment";
export function numberWithCommas(x) {
  if (x) {
    let onlyNumber = x.toString().replace(/,/gi, "");
    let parts = onlyNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  } else if (x === "") {
    return "";
  } else if (x === 0) {
    return "0";
  } else {
    return "0";
  }
}

export function unComma(value) {
  const n = parseInt(value.replace(/,/g, ""));
  return n;
}

export function createQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(p + "=" + obj[p]);
    }
  const query = str.join("&");
  return `?${query}`;
}

export const formatDate = (date) => {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;
  hour = hour >= 10 ? hour : "0" + hour;
  minute = minute >= 10 ? minute : "0" + minute;
  second = second >= 10 ? second : "0" + second;

  return (
    date.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    " " +
    hour +
    ":" +
    minute +
    ":" +
    second
  );
};

export const formatTime = (date, format) => {
  if (date) {
    return moment(date).format(format);
  } else {
    return "";
  }
};
