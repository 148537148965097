import React from "react";
import "../../assets/css/layout.css";
import "../../assets/css/common.css";
// import footerLogo from "../../assets/img/footer_logo.png";
import footerLogo from "../../assets/img/footer_logo.jpeg";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const handleChangeFamilySite = (e) => {
    if (e.target.value !== "defalut") {
      window.location.href = e.target.value;
    }
  };

  const location = useLocation();
  let filter = "win16|win32|win64|mac|macintel";
  let check = "PC";
  if (0 > filter.indexOf(navigator.platform.toLowerCase())) {
    check = "Mobile";
  } else {
    check = "PC";
  }

  return (
    <div
      id="main-footer"
      style={
        check === "Mobile" && location?.pathname?.includes("company")
          ? { position: "fixed", bottom: 0, width: "100%" }
          : {}
      }
    >
      <div className="infor_wrap1">
        <div className="infor_box1">
          <ul>
            <li>
              <Link to={routes.termService} data-lightbox="inline">
                이용약관
              </Link>
            </li>
            <li>
              <Link to={routes.privacyPolicy}>개인정보 처리 방침</Link>
            </li>
          </ul>
          <select
            defaultValue="defalut"
            className="select_type2 sel_family"
            onChange={handleChangeFamilySite}
          >
            <option value="defalut" disabled>
              Family Site
            </option>
            <option value="http://www.goldshell.co.kr/">골드쉘</option>
            <option value="http://www.soongumnara.co.kr/">순금나라</option>
            <option value="https://www.koreagoldx.co.kr/">한국금거래소</option>
            <option value="http://www.exgoldftc.co.kr/">
              한국금거래소 FTC
            </option>
          </select>
        </div>
      </div>
      {/* <!--**개인정보처리 영역 끝-->	 */}
      {/* <!-- footer --> */}
      <div id="footer">
        <div className="footer_wrap">
          {/* <div className="footer_logo">
            <img src={footerLogo} alt="하단 로고" />
          </div> */}
          <div className="footer_info">
            <address className="addres1">
              본사 : 서울 종로구 돈화문로5가길 1 피카디리플러스 8층
            </address>
            <div className="company_num">
              <p>TEL : 070-4717-0740 FAX : 02)741-6314</p>
            </div>
            <address className="addres1">
               직영점 : 서울 종로구 종로 128 화영빌딩 1층
            </address>
            <div className="company_num">
              <p>TEL : 02)744-9999 FAX : 02)744-5044</p>
            </div>
            <address className="addres2">
              <p>
                상호 : 주식회사 한국금거래소 / 대표이사 : 송종길 /
                사업자등록번호 : 101-86-11518
              </p>
            </address>
            <div className="addres3">
              <p>통신판매업신고번호 : 제01-1999호</p>
              <p>
                개인정보보호관리책임자 : 안광호
              </p>
              <p>Copyright Korea Gold Exchange All rights reserved.</p>
            </div>
          </div>
          <ul className="footer_phone1">
            <li className="re_time">
              <span>실시간 상담</span>
              <strong>1544 9993</strong>
            </li>
            <li className="pro_time">
              <span>금제품 문의</span>
              <strong>02 744 9999</strong>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
