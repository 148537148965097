import React, { useState, useEffect } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import "../../assets/css/goods.css";
import allCross from "../../assets/img/common/all_cross1.png";
import allBtn from "../../assets/img/common/all_btn1.png";
import home from "../../assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  numberWithCommas,
  createQueryString,
  formatTime,
} from "../../lib/utils";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import AssetChart from "../../components/common/AssetChart";
import ReactPaginate from "react-paginate";

export default function SecuritiesPriceInquiry() {
  const [type, setType] = useState("AU");
  const [range, setRange] = useState("month");
  const [fromDate, setFromDate] = useState(
    formatTime(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      "YYYY-MM-DD"
    )
  );
  const [toDate, setToDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [chartData, setChartData] = useState([]);
  const [stockPrice, setStockPrice] = useState([]);
  const [stockPriceData] = useAsync(APIS.getStockPrice, [], true);
  const { data } = stockPriceData;

  // 페이징 관련
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [chartList, setChartList] = useState([]);

  useEffect(() => {
    const queryString = createQueryString({
      type: type,
      page: pageNumber,
      size: 15,
      from: fromDate,
      to: toDate,
    });
    APIS.getSecurityChartList(queryString).then((res) => {
      const { data, success } = res.data;
      if (success) {
        if (data.content.length > 0) {
          setPageCount(data?.["totalElements"] || 0);
          setChartList(data["content"] || []);
        }
      }
    });
    // eslint-disable-next-line
  }, [type, range, pageNumber]);

  const rangeQueryString = createQueryString(
    range === "month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : {
          type: type,
          from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
          to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
        }
  );
  const actionType = () => APIS.getSecurityChart(rangeQueryString);
  const [state, refetchChart] = useAsync(actionType, [], false);
  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };
  const headerType = {
    AU: {
      engName: "GOLD",
      korName: "금",
    },
    AG: {
      engName: "SILVER",
      korName: "은",
    },
    PT: {
      engName: "PLATINUM",
      korName: "백금",
    },
    PD: {
      engName: "PALLADIUM",
      korName: "팔라듐",
    },
    USD: {
      engName: "USD",
      korName: "USD",
    },
  };

  const seriesName = headerType[type].korName;
  let arr = [];
  if (chartData?.length > 0) {
    chartData?.forEach((item) => {
      arr.push([new Date(item["date"]), item["price"]]);
    });
  }
  const searchChartDate = () => {
    setRange("");
    const queryString = createQueryString({
      type: type,
      from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
      to: formatTime(new Date(toDate), "YYYY-MM-DD"),
    });
    refetchChart(() => APIS.getSecurityChart(queryString), "", false);
  };

  const setting = {
    options: {
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            return moment(new Date(val)).format("yyyy. MM.DD");
          },
          // datetimeUTC: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            var val = String(value).split(".");
            if (val.length <= 1) {
              //소수점이 없는 경우
              return numberWithCommas(value);
            } else {
              //소수점이 있는 경우
              return numberWithCommas(value.toFixed(2));
            }
          },
        },
        tickAmount: 4,
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return moment(value).format("yyyy년 MM월 DD일");
          },
        },
      },
      colors: [type === "AU" ? "#ffc945" : "#7c7c7c"],
    },
    series: [
      {
        name: seriesName,
        data: arr,
      },
    ],
  };
  useEffect(() => {
    const queryString = createQueryString(
      range === "month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "5year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 5)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "total"
        ? {
            type: type,
            from: "2016-01-01",
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : {
            type: type,
            from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
            to: formatTime(new Date(toDate), "YYYY-MM-DD"),
          }
    );
    const actionType =
      //   range === "live"
      // ? () => APIS.getDomesticPrice(queryString)
      () => APIS.getSecurityChart(queryString);

    refetchChart(actionType);
    // eslint-disable-next-line
  }, [type, range]);

  useEffect(() => {
    setChartData(state.data);
  }, [state]);

  useEffect(() => {
    setStockPrice(data?.["securityMainDtoList"] || []);
  }, [data]);

  const onPageChange = (props) => {
    const { selected } = props;
    setPageNumber(selected);
  };

  const onChangeDate = (type) => {
    switch (type) {
      case "month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "1year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "5year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 5)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "total":
        setFromDate("2016-01-01");
        break;

      default:
    }
    setPageNumber(0);
    setPageCount(0);
    setRange(type);
  };
  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.londonFixInquiry}>
                              런던픽스시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      <div className="svisual_box">
        <div className="sub_visual1_bg">
          <div className="subvisual_wrap">
            <dl className="sub_vtitl1">
              <dt>시세조회</dt>
              <dd>Market Price Check</dd>
            </dl>
            <div className="location_wrap">
              <div className="location_box">
                <dl>
                  <dt className="home">
                    <Link to="/">
                      <img src={home} alt="home" />
                    </Link>
                  </dt>
                  <dd className="dep_menu1">
                    <button onClick={dropDownFirstTab} className="dropbtn">
                      시세조회
                    </button>
                    <div id="myDropdown1" className="dropdown-content">
                      {/* <Link to="/product/productall.html" style={{display:"none"}}>상품안내</Link>
									<Link to="/souvenir/inquiry.html" style={{display: "none"}}>기념품제작</Link>
									<Link to="/newscenter/newsNoticeList.html" style={{display: "none"}}>뉴스센터</Link>
									<Link to="/notice/announcementList.html" style={{display: "none"}}>공지사항</Link> */}
                      <Link to={routes.companyIntroduction}>회사소개</Link>
                    </div>
                  </dd>
                  <dd className="dep_menu2">
                    <button onClick={dropDownSecondTab} className="dropbtn">
                      유가증권시세
                    </button>
                    <div id="myDropdown2" className="dropdown-content">
                      <Link to={routes.domesticPriceInquiry}>국내시세</Link>
                      <Link to={routes.internationalPriceInquiry}>
                        국제시세
                      </Link>
                      <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
                      <Link to={routes.diamondPriceInquiry}>
                        다이아몬드시세
                      </Link>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub_container">
        <div className="sub_container_wrap">
          {/* <!-- lnb start --> */}
          <div className="lnb">
            <h4 className="lb_titl1">시세조회</h4>
            <ul>
              <li>
                <Link to={routes.domesticPriceInquiry}>국내시세</Link>
              </li>
              <li>
                <Link to={routes.internationalPriceInquiry}>국제시세</Link>
              </li>
              <li>
                <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
              </li>
              <li>
                <Link to={routes.securitiesPriceInquiry} className="on">
                  유가증권시세
                </Link>
              </li>
              <li>
                <Link to={routes.diamondPriceInquiry}>다이아몬드시세</Link>
              </li>
            </ul>
          </div>
          {/* <!-- lnb end --> */}
          {/* <script src="/js/moment.min.js"></script> */}
          {/* <!-- contents start --> */}
          <div className="contents">
            {/* <!-- //상단 탭 --> */}
            <div className="con_titl1">
              <h4>유가증권시세</h4>
              <span>Securities Price</span>
            </div>
            <div className="service_cont">
              <div style={{ paddingBottom: "25px" }}>
                <ul className="graph_dt_check">
                  <li>
                    <input
                      type="checkbox"
                      name="goldbar"
                      value="goldbar"
                      onChange={() => {
                        setPageNumber(0);
                        setPageCount(0);
                        setType("AU");
                      }}
                      checked={type === "AU" ? true : false}
                    />
                    <label>골드바 10g</label>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name="silverbar"
                      value="silverbar"
                      onChange={() => {
                        setPageNumber(0);
                        setPageCount(0);
                        setType("AG");
                      }}
                      checked={type === "AG" ? true : false}
                    />
                    <label>실버바 1kg</label>
                  </li>
                </ul>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  className="sch_daytitl1"
                  style={{ marginBottom: "10px", marginTop: "30px" }}
                >
                  <h5>기간 </h5>
                  <ul className="term">
                    <li>
                      <Link
                        to="#"
                        className={range === "month" ? "on" : ""}
                        onClick={() => onChangeDate("month")}
                      >
                        1개월
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "3month" ? "on" : ""}
                        onClick={() => onChangeDate("3month")}
                      >
                        3개월
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "year" ? "on" : ""}
                        onClick={() => onChangeDate("1year")}
                      >
                        1년
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "3year" ? "on" : ""}
                        onClick={() => onChangeDate("3year")}
                      >
                        3년
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "5year" ? "on" : ""}
                        onClick={() => onChangeDate("5year")}
                      >
                        5년
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "total" ? "on" : ""}
                        onClick={() => onChangeDate("total")}
                      >
                        전체
                      </Link>
                    </li>
                    <li className="last">
                      <div className="data_day_f1">
                        <input
                          className="datepicker1 hasDatepicker"
                          type="date"
                          id="dp1636523011796"
                          onChange={(e) => {
                            setFromDate(e.target.value);
                          }}
                          disabled={true}
                          value={fromDate}
                        />
                      </div>{" "}
                      -&nbsp;
                      <div className="data_day2">
                        <input
                          className="datepicker2 hasDatepicker"
                          type="date"
                          id="dp1636523011801"
                          onChange={(e) => {
                            setToDate(e.target.value);
                          }}
                          disabled={true}
                          value={toDate}
                        />
                      </div>
                      <button
                        onClick={searchChartDate}
                        className="search_btn1"
                        disabled={true}
                      >
                        조회
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- 차트 --> */}
              {/* <div className="sub_graph mb50">
           </div> */}
              <AssetChart
                style={{ padding: "15px", marginTop: "15px" }}
                options={setting.options}
                series={setting.series}
                type="area"
                width="850"
                height="300"
              />
              {/* <!-- //차트 --> */}
              {/* <!--//실시간판매가--> */}
              <div className="now_price mb50">
                <h5>실시간 판매가</h5>
                <table>
                  <tr>
                    <th rowSpan="2">권종</th>
                    <th>골드바</th>
                    <th>실버바</th>
                  </tr>
                  <tr className="color_price">
                    {/* <td></td> */}
                    <td>10g</td>
                    <td>1,000g</td>
                  </tr>
                  <tr>
                    <th>판매가격</th>
                    <td>{`${numberWithCommas(stockPrice[0]?.price)}`}원</td>
                    <td>{`${numberWithCommas(stockPrice[1]?.price)}`}원</td>
                  </tr>
                </table>
              </div>
              {/* <!--//실시간판매가--> */}
              {/* <!--//전날까지판매가--> */}
              <p className="table_top">
                총 <b id="tatalMarket">{pageCount}</b>개
              </p>
              <table className="price_info_table" style={{}}>
                <colgroup>
                  <col width="106.25px" />
                  <col width="106.25px" />
                </colgroup>
                <thead>
                  <tr>
                    <th>고시시간</th>
                    <th>{type === "AU" ? "골드바 10g" : "실버바 1kg"}</th>
                  </tr>
                </thead>
                <tbody>
                  {chartList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.date || "-"}</td>
                        <td>{numberWithCommas(item?.price) || "-"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className={"pagination next"}
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "10%",
                    height: "30px",
                    lineHeight: "25px",
                    color: "#337ab7",
                  }}
                  onClick={() => {
                    if (pageNumber === 0) {
                      return false;
                    }
                    setPageNumber(0);
                  }}
                >
                  {"<<"}
                </button>
                &nbsp;
                <ReactPaginate
                  className={"pagination"}
                  pageCount={Math.ceil(pageCount / 15)}
                  forcePage={pageNumber}
                  pageRangeDisplayed={5}
                  itemsCountPerPage={5}
                  marginPagesDisplayed={0}
                  previousLabel={"<"}
                  nextLabel={">"}
                  activeClassName={"tabulator-page active"}
                  onPageChange={onPageChange}
                />
                &nbsp;
                <button
                  className={"pagination previou"}
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "10%",
                    height: "30px",
                    lineHeight: "25px",
                    color: "#337ab7",
                  }}
                  onClick={() => {
                    if (pageNumber === Math.ceil(pageCount / 15) - 1) {
                      return false;
                    } else {
                      setPageNumber(Math.ceil(pageCount / 15) - 1);
                    }
                  }}
                >
                  {">>"}
                </button>
              </div>
              {/* <!--//전날까지판매가--> */}
            </div>
            <div className="clear"></div>
          </div>
          {/* <script type="text/javascript" src="/js/jquery-ui.min.js"></script> */}
          {/* <!-- contents end --> */}
        </div>
        {/* <!-- contents end --> */}
      </div>
    </>
  );
}
