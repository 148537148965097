import React, { useEffect, useState } from "react";
import downArrow from "../../assets/img/down.png";
import upArrow from "../../assets/img/up.png";
import midd from "../../assets/img/midd.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas } from "../../lib/utils";

const MobileMajorPrice = () => {
  const [asiaIndex, setAsiaIndex] = useState([]);
  const [euIndex, setEuIndex] = useState([]);
  const [krIndex, setKrIndex] = useState([]);
  const [oilIndex, setOilIndex] = useState([]);
  const [usIndex, setUsIndex] = useState([]);
  const [majorIndexData, refetch] = useAsync(APIS.getMajorIndex, [], true);
  const { data } = majorIndexData;

  const upImg = "";
  const downImg = "";

  const compareFluctuationRate = (type, index, indexState) => {
    switch (type) {
      case "default":
        if (indexState[index]?.flucRa > 0) {
          return (
            <td className="ch_up">{Math.abs(indexState[index]?.flucRa)}%</td>
          );
        } else if (indexState[index]?.flucRa < 0) {
          return (
            <td className="ch_dw">{Math.abs(indexState[index]?.flucRa)}%</td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (indexState[index]?.fluc > 0) {
          return (
            <td>
              <img src={upArrow} alt="up" />
              {Math.abs(indexState[index]?.fluc)}
            </td>
          );
        } else if (indexState[index]?.fluc < 0) {
          return (
            <td>
              <img src={downArrow} alt="down" />
              {Math.abs(indexState[index]?.fluc)}
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }

      // no default
    }
  };

  useEffect(() => {
    setAsiaIndex(data?.["asiaIndexDtoList"] || []);
    setEuIndex(data?.["euIndexDtoList"] || []);
    setKrIndex(data?.["krIndexDtoList"] || []);
    setOilIndex(data?.["oilIndexDtoList"] || []);
    setUsIndex(data?.["usIndexDtoList"] || []);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getMajorIndex(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="m_mcontainer5">
        <h4 className="m_mtit">주요지수</h4>
        <div className="m_mmiddle_area5">
          <table className="kindex">
            <tbody>
              <tr>
                <td>코스피</td>
                <td>{numberWithCommas(krIndex[0]?.price)}</td>
                {compareFluctuationRate("arrow", 0, krIndex)}
                {compareFluctuationRate("default", 0, krIndex)}
              </tr>
              <tr>
                <td>다우존스</td>
                <td>{numberWithCommas(usIndex[2]?.price)}</td>
                {compareFluctuationRate("arrow", 2, usIndex)}
                {compareFluctuationRate("default", 2, usIndex)}
              </tr>
              <tr>
                <td>영국 FTSE</td>
                <td>{numberWithCommas(euIndex[0]?.price)}</td>
                {compareFluctuationRate("arrow", 0, euIndex)}
                {compareFluctuationRate("default", 0, euIndex)}
              </tr>
              <tr>
                <td>닛케이225</td>
                <td>{numberWithCommas(asiaIndex[1]?.price)}</td>
                {compareFluctuationRate("arrow", 1, asiaIndex)}
                {compareFluctuationRate("default", 1, asiaIndex)}
              </tr>
              <tr>
                <td>WTI선물</td>
                <td>{numberWithCommas(oilIndex[0]?.price)}</td>
                {compareFluctuationRate("arrow", 0, oilIndex)}
                {compareFluctuationRate("default", 0, oilIndex)}
              </tr>
              <tr>
                <td>코스닥</td>
                <td>{numberWithCommas(krIndex[1]?.price)}</td>
                {compareFluctuationRate("arrow", 1, krIndex)}
                {compareFluctuationRate("default", 1, krIndex)}
              </tr>
              <tr>
                <td>나스닥</td>
                <td>{numberWithCommas(usIndex[1]?.price)}</td>
                {compareFluctuationRate("arrow", 1, usIndex)}
                {compareFluctuationRate("default", 1, usIndex)}
              </tr>
              <tr>
                <td>프랑스 CAC</td>
                <td>{numberWithCommas(euIndex[2]?.price)}</td>
                {compareFluctuationRate("arrow", 2, euIndex)}
                {compareFluctuationRate("default", 2, euIndex)}
              </tr>
              <tr>
                <td>상하이 종합지수</td>
                <td>{numberWithCommas(asiaIndex[0]?.price)}</td>
                {compareFluctuationRate("arrow", 0, asiaIndex)}
                {compareFluctuationRate("default", 0, asiaIndex)}
              </tr>
              <tr>
                <td>브렌트유</td>
                <td>{numberWithCommas(oilIndex[2]?.price)}</td>
                {compareFluctuationRate("arrow", 2, oilIndex)}
                {compareFluctuationRate("default", 2, oilIndex)}
              </tr>
              <tr>
                <td>코스피200</td>
                <td>{numberWithCommas(krIndex[2]?.price)}</td>
                {compareFluctuationRate("arrow", 2, krIndex)}
                {compareFluctuationRate("default", 2, krIndex)}
              </tr>
              <tr>
                <td>S{"&"}P500</td>
                <td>{numberWithCommas(usIndex[0]?.price)}</td>
                {compareFluctuationRate("arrow", 0, usIndex)}
                {compareFluctuationRate("default", 0, usIndex)}
              </tr>
              <tr>
                <td>독일 DAX</td>
                <td>{numberWithCommas(euIndex[1]?.price)}</td>
                {compareFluctuationRate("arrow", 1, euIndex)}
                {compareFluctuationRate("default", 1, euIndex)}
              </tr>
              <tr>
                <td>독일H.I.S지수</td>
                <td>{numberWithCommas(asiaIndex[2]?.price)}</td>
                {compareFluctuationRate("arrow", 2, asiaIndex)}
                {compareFluctuationRate("default", 2, asiaIndex)}
              </tr>
              <tr>
                <td>두바이유</td>
                <td>{numberWithCommas(oilIndex[1]?.price)}</td>
                {compareFluctuationRate("arrow", 1, oilIndex)}
                {compareFluctuationRate("default", 1, oilIndex)}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MobileMajorPrice;
