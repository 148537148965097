import React from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import "../../assets/css/slick.css";
import allBtn from "../../assets/img/common/all_btn1.png";
import allCross from "../../assets/img/common/all_cross1.png";
//import busiCont1 from "../../assets/img/busi_cont1.png"
import home from "../../../src/assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import SideBar from "../../components/common/SideBar";
import BusinessImg from "../../assets/img/business_introduction_img.jpeg";

export default function BusinessIntroduction() {
  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };

  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <Link to="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </Link>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">All Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/newscenter/marketNoticeList.html">
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝-->	 */}
      {/* <!-- wrap --> */}
      <div id="wrap">
        {/* <!-- 비쥬얼 박스 시작 --> */}
        <div className="svisual_box">
          <div className="sub_visual5_bg">
            <div className="subvisual_wrap">
              <dl className="sub_vtitl1">
                <dt>회사소개</dt>
                <dd>Company Introduction</dd>
              </dl>
              <div className="location_wrap">
                <div className="location_box">
                  <dl>
                    <dt className="home">
                      <Link to="/">
                        <img src={home} alt="home" />
                      </Link>
                    </dt>
                    <dd className="dep_menu1">
                      <button onClick={dropDownFirstTab} className="dropbtn">
                        회사소개
                      </button>
                      <div id="myDropdown1" className="dropdown-content">
                        <Link to={routes.domesticPriceInquiry}>시세조회</Link>
                        {/* <Link
                         to="/newscenter/newsNoticeList.html"
                          style={{display: "none"}}
                        >
                          상품안내
                        </Link>
                        <Link  to="/newscenter/newsNoticeList.html" style={{display: "none"}}>
                          기념품제작
                        </Link>
                        <Link
                          to="/newscenter/newsNoticeList.html"
                          style={{display: "none"}}
                        >
                          뉴스센터
                        </Link>
                        <Link
                          to="/notice/announcementList.html"
                          style={{display: "none"}}
                        >
                          공지사항
                        </Link> */}
                      </div>
                    </dd>
                    <dd className="dep_menu2">
                      <button onClick={dropDownSecondTab} className="dropbtn">
                        사업소개
                      </button>
                      <div id="myDropdown2" className="dropdown-content">
                        <Link to={routes.companyIntroduction}>회사소개</Link>
                        <Link to={routes.companyGreeting}>CEO 인사말</Link>
                        <Link to={routes.companyHistory}>회사연혁</Link>
                        <Link to={routes.companyBrandIntroduction}>
                          브랜드소개
                        </Link>
                        <Link to={routes.companyDirections}>오시는 길</Link>
                        {/* <Link to={routes.companyFranchiseInvitation}>
                          가맹점 모집
                        </Link> */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_container">
          <div className="sub_container_wrap">
            {/* <!-- lnb start --> */}
            <SideBar />
            {/* <!-- lnb end -->					
					<!-- contents start --> */}
            <div className="contents">
              <div className="con_titl1">
                <h4>사업소개</h4>
                <span>Business Introduction</span>
              </div>
              <div className="busi_cont1">
                <img src={BusinessImg} alt="한국금거래소 주요 사업" />
              </div>
            </div>
            <div className="clear"></div>
            {/* <!-- contents end -->	 */}
          </div>
        </div>
        {/* // <!--**컨테이너 영역 끝--> */}
      </div>
      {/* // <!--  wrap --> */}

      <svg
        id="SvgjsSvg1001"
        width="2"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlnssvgjs="http://svgjs.com/svgjs"
        style={{
          overflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1002"></defs>
        <polyline id="SvgjsPolyline1003" points="0,0"></polyline>
        <path id="SvgjsPath1004" d="M0 0 "></path>
      </svg>
    </>
  );
}
