import React from "react";
import store_step1 from "../../assets/img/store_step1.png";
import store_step2 from "../../assets/img/store_step2.png";
import store_step3 from "../../assets/img/store_step3.png";
import store_step4 from "../../assets/img/store_step4.png";
import store_step5 from "../../assets/img/store_step5.png";
import store_step6 from "../../assets/img/store_step6.png";
import store_step7 from "../../assets/img/store_step7.png";
import store_step8 from "../../assets/img/store_step8.png";

const MobileFranchiseInvitation = () => {
  return (
    <div className="m_company_wrap">
      <div className="company_tit">
        <h3>가맹점 모집</h3>
        <p>Recruitment of Franchises</p>
      </div>
      <div className="company_cot">
        <div className="m_gold_m_number">
          <dl>
            <dt>한국금거래소 가맹점 개설 문의</dt>
            <dd>1544-9993</dd>
          </dl>
        </div>
        <ul className="m_stroe_numlist">
          <li>
            <dl>
              <dt>
                <div className="m_store_num">1</div>가맹점 개설상담
              </dt>
              <dd>전화상담 및 방문 상담하여 드립니다.</dd>
            </dl>
            <img src={store_step1} alt="1s_icon" class="icon" />
          </li>
          <li>
            <dl>
              <dt>
                <div className="m_store_num">2</div>상권승인
              </dt>
              <dd>해당지역 가맹점 확인 및 상권승인을 해드립니다.</dd>
            </dl>
            <img src={store_step2} alt="2s_icon" class="icon" />
          </li>
          <li>
            <dl>
              <dt>
                <div className="m_store_num">3</div>가맹점 계약
              </dt>
              <dd>가맹점 및 점포 계약, 사업자등록 신청</dd>
            </dl>
            <img src={store_step3} alt="3s_icon" class="icon" />
          </li>
          <li>
            <dl>
              <dt>
                <div className="m_store_num">4</div>제품준비
              </dt>
              <dd>초도물량 확정 후 제품 주문</dd>
            </dl>
            <img src={store_step4} alt="4s_icon" class="icon" />
          </li>
          <li>
            <dl>
              <dt>
                <div className="m_store_num">5</div>인테리어 공사
              </dt>
              <dd>인테리어 업체 선정 및 공사, 판매사원 모집</dd>
            </dl>
            <img src={store_step5} alt="5s_icon" class="icon" />
          </li>
          <li>
            <dl>
              <dt>
                <div className="m_store_num">6</div>교육
              </dt>
              <dd>
                가맹점주 및 판매사원에 대한 보석, 귀금속 교육 가맹본부에서 실시,
                제품 교육 및 판매, 회사규정 교육
              </dd>
            </dl>
            <img src={store_step6} alt="6s_icon" class="icon" />
          </li>
          <li>
            <dl>
              <dt>
                <div className="m_store_num">7</div>개점 준비
              </dt>
              <dd>인테리어 점검, 디스플레이 점검 및 전반적인 판매 툴 준비</dd>
            </dl>
            <img src={store_step7} alt="7s_icon" class="icon" />
          </li>
          <li>
            <dl>
              <dt>
                <div className="m_store_num">8</div>오픈 행사
              </dt>
              <dd>
                가맹본부와 가맹점 행사 규모 협의 후 오픈 행사, <br />
                오픈 당일 본사 판매 지원
              </dd>
            </dl>
            <img src={store_step8} alt="8s_icon" class="icon" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileFranchiseInvitation;
