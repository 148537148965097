import React, { useEffect, useState } from "react";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas } from "../../lib/utils";

const MobileDiamondPrice = () => {
  const [diamondPrice, setDiamondPrice] = useState([]);
  const [diamondPriceData] = useAsync(APIS.getDiamondPrice, [], true);
  const { data } = diamondPriceData;

  useEffect(() => {
    setDiamondPrice(
      data?.["diamondPriceDtoList"].filter((item) => item.status !== true) || []
    );
  }, [data]);

  return (
    <div class="m_price_wrap">
      <div class="m_contents">
        <div class="price_tit">
          <h3>다이아몬드 시세</h3>
          <p>Diamond Price</p>
        </div>
        <div class="m_service_cont">
          <div class="m_service_diamaond">
            <div class="pb20">
              <div class="m_diamaond_top">
                <p>
                  정확하고 믿을 수 있는
                  <br />
                  다이아몬드 한국금거래소에서
                  <br />
                  감정 후 매매하세요.{" "}
                </p>
              </div>
              <div class="m_clear"></div>
              {diamondPrice?.map((item, index) => {
                return (
                  <table class="m_diamond_tb">
                    <thead>
                      <tr className={item.isSoldOut ? "m_soldout" : ""}>
                        <td>
                          <span>감정</span>
                          <br />
                          {item.company}
                        </td>
                        <td>
                          <span>중량</span>
                          <br />
                          {item.weight.toFixed(2)}ct
                        </td>
                        <td>
                          <span>컬러</span>
                          <br />
                          {item.color}
                        </td>
                        <td>
                          <span>투명도</span>
                          <br />
                          {item.clarity}
                        </td>
                        <td>
                          <span>컷등급</span>
                          <br />
                          {item.cutRate}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="2">할인판매가</td>
                        <td colspan="2"> {numberWithCommas(item.price)}원</td>
                        {item.isSoldOut && (
                          <td
                            className="m_soldout"
                          >
                            <span>품절</span>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </div>
            <div class="m_diamaond_block">
              <h4 class="m_mtit">고객 구입 절차</h4>
              <div class="m_diamaond_process_box">
                <ul class="m_diamaond_process_list">
                  <li class="item item_buy_1">
                    <span>1</span>&nbsp;
                    <strong>방문 예약</strong>
                    <p>
                      가까운 한국금거래소에
                      <br />
                      방문 예약합니다.
                    </p>
                  </li>
                  <li class="item item_buy_2">
                    <span>2</span>&nbsp;
                    <strong>고객 상담</strong>
                    <p>
                      다이아몬드4C 확인 후<br /> 상담원과 원하는
                      <br />
                      크기와 등급, 가격대를 선택합니다.
                    </p>
                  </li>
                  <li class="item item_buy_3">
                    <span>3</span>&nbsp;
                    <strong>주얼리 선택</strong>
                    <p>
                      선택된 다이아몬드와 잘 어울리는
                      <br />
                      주얼리를 추천해드리거나 원하는
                      <br />
                      모양을 선택할 수 있습니다.
                    </p>
                  </li>
                  <li class="item item_buy_4">
                    <span>4</span>&nbsp;
                    <strong>최종 가격</strong>
                    <p>
                      다이아몬드와 주얼리의 합리적인
                      <br />
                      최종 가격을 제시합니다.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="m_clear"></div>
            <div class="m_diamaond_block">
              <h4 class="m_mtit">고객 판매 절차</h4>
              <div class="m_diamaond_process_box">
                <ul class="m_diamaond_process_list">
                  <li class="item item_sell_1">
                    <span>1</span>&nbsp;
                    <strong>방문 예약</strong>
                    <p>
                      다이아몬드의 보증서를 상담원에게
                      <br />
                      아려주고 대략적인 가격을 산출하여
                      <br />
                      방문 날짜를 정합니다.
                    </p>
                  </li>
                  <li class="item item_sell_2">
                    <span>2</span>&nbsp;
                    <strong>고객 내방</strong>
                    <p>
                      가까운 한국금거래소에 내방합니다.
                      <br />
                      상담원은 보증서와 고객의
                      <br />
                      다이아몬드를 검사합니다.
                    </p>
                  </li>
                  <li class="item item_sell_3">
                    <span>3</span>&nbsp;
                    <strong>최종 가격</strong>
                    <p>
                      검사가 완료된 다이아몬드의 최종
                      <br />
                      가격을 제시합니다.
                    </p>
                  </li>
                  <li class="item item_sell_4">
                    <span>4</span>&nbsp;
                    <strong>금액 지불</strong>
                    <p>
                      고객님이 최종 가격에 승인을
                      <br />
                      하면 현장에서 금액을 지불합니다.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileDiamondPrice;
