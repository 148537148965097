import React from "react";
import BusinessImg from "../../assets/img/business_introduction_img.jpeg";

const MobileBusinessIntroduction = () => {
  return (
    <div className="m_company_wrap">
      <div className="company_tit">
        <h3>사업소개</h3>
        <p>Company Introduction</p>
      </div>

      <div className="m_busi_cont2">
        <img src={BusinessImg} alt="한국금거래소 주요 사업" />
      </div>
    </div>
  );
};

export default MobileBusinessIntroduction;
