import React, { useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import "../../assets/css/sub.css";
import "../../assets/css/slick.css";
import allBtn from "../../assets/img/common/all_btn1.png";
import allCross from "../../assets/img/common/all_cross1.png";
import goldExchange04 from "../../assets/img/img_goldexchange_04.png";
import goldShell04 from "../../assets/img/img_Bi_glodshell04.png";
import goldWorld04 from "../../assets/img/img_BI_goldworld04.png";
import mbro04 from "../../assets/img/img_mbro_04.png";
import home from "../../../src/assets/img/icon_home.png";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
import SideBar from "../../components/common/SideBar";
export default function BrandIntroduction() {
  const [selectImg, setSelectImg] = useState("goldExchange");
  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };
  return (
    <>
      <div
        className="all_box1"
        style={{
          position: "absolute",
          left: "50%",
          marginLeft: "500px",
          top: "75px",
        }}
      >
        <a href="#layer" className="btn-layer">
          <img src={allBtn} alt="전체메뉴" />
        </a>
      </div>
      {/* <!-- Start : layer-popup content --> */}
      <div id="layer" className="layer-wrap">
        <div className="pop-layer">
          <div className="pop-container">
            <div className="pop-conts">
              {/* <!-- content --> */}
              <div className="allmenu1">
                <h2 className="alltitl">all Menu</h2>
                <ul className="menulist">
                  <li>
                    <dl>
                      <dt>귀금속시세</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.domesticPriceInquiry}>
                              국내시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.internationalPriceInquiry}>
                              국제시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.diamondPriceInquiry}>
                              다이아몬드 시세
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.securitiesPriceInquiry}>
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>상품소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="/product/productall.html">원자재</Link>
                          </li>
                          <li>
                            <Link to="/product/securitiesgd.html">
                              유가증권
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>기념품 제작</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="#" href="/souvenir/inquiry.html">
                              제작문의 조회
                            </Link>
                          </li>
                          <li>
                            <Link to="#" href="/souvenir/inquiryRegister.html">
                              제작문의 등록
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>뉴스센터</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={"/newscenter/marketNoticeList.html"}>
                              뉴스
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>공지사항</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to="#" href="/notice/announcementList.html">
                              공지사항
                            </Link>
                          </li>
                        </ul>
                      </dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>회사소개</dt>
                      <dd>
                        <ul>
                          <li>
                            <Link to={routes.companyIntroduction}>
                              회사소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyGreeting}>CEO인사말</Link>
                          </li>
                          <li>
                            <Link to={routes.companyHistory}>회사연혁</Link>
                          </li>
                          <li>
                            <Link to={routes.companyBusinessIntroduction}>
                              사업소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyBrandIntroduction}>
                              브랜드소개
                            </Link>
                          </li>
                          <li>
                            <Link to={routes.companyDirections}>오시는 길</Link>
                          </li>
                          {/* <li>
                            <Link to={routes.companyFranchiseInvitation}>
                              가맹점 모집
                            </Link>
                          </li> */}
                        </ul>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <Link to="#" href="#" className="btn-layerClose">
                <img src={allCross} alt="닫기" />
              </Link>
              {/* <!--// content--> */}
            </div>
          </div>
          {/* <!-- End : layer-popup content --> */}
        </div>
      </div>
      {/* <!--=============================================================**헤더 영역 끝-->
		<!-- wrap --> */}
      <div id="wrap">
        {/* <!-- 비쥬얼 박스 시작 --> */}
        <div className="svisual_box">
          <div className="sub_visual5_bg">
            <div className="subvisual_wrap">
              <dl className="sub_vtitl1">
                <dt>회사소개</dt>
                <dd>Company Introduction</dd>
              </dl>
              <div className="location_wrap">
                <div className="location_box">
                  <dl>
                    <dt className="home">
                      <Link to="/">
                        <img src={home} alt="home" />
                      </Link>
                    </dt>
                    <dd className="dep_menu1">
                      <button onClick={dropDownFirstTab} className="dropbtn">
                        회사소개
                      </button>
                      <div id="myDropdown1" className="dropdown-content">
                        <Link to={routes.domesticPriceInquiry}>시세조회</Link>
                        {/* <Link style={{ display: "none" }}>상품안내</Link>
                        <Link style={{ display: "none" }}>기념품제작</Link>
                        <Link
                          //   to="/newscenter/newsNoticeList.html"
                          style={{ display: "none" }}
                        >
                          뉴스센터
                        </Link>
                        <Link
                          href="/notice/announcementList.html"
                          style={{ display: "none" }}
                        >
                          공지사항
                        </Link> */}
                      </div>
                    </dd>
                    <dd className="dep_menu2">
                      <button onClick={dropDownSecondTab} className="dropbtn">
                        브랜드소개
                      </button>
                      <div id="myDropdown2" className="dropdown-content">
                        <Link to={routes.companyIntroduction}>회사소개</Link>
                        <Link to={routes.companyGreeting}>CEO 인사말</Link>
                        <Link to={routes.companyHistory}>회사연혁</Link>
                        <Link to={routes.companyBusinessIntroduction}>
                          사업소개
                        </Link>
                        <Link to={routes.companyDirections}>오시는 길</Link>
                        {/* <Link to={routes.companyFranchiseInvitation}>
                          가맹점 모집
                        </Link> */}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_container">
          <div className="sub_container_wrap">
            {/* <!-- lnb start --> */}
            <SideBar />
            {/* <!-- lnb end -->
					<!-- contents start --> */}
            <div className="contents">
              <div className="con_titl1">
                <h4>브랜드 소개</h4>
                <span>Brand Introduction</span>
              </div>
              <div className="outer">
                <div className="tab_buttons">
                  <button
                    className={`int_img1 ${
                      selectImg === "goldExchange" && "active"
                    }`}
                    onClick={() => setSelectImg("goldExchange")}
                  >
                    1
                  </button>
                  <button
                    className={`int_img2 ${
                      selectImg === "goldShell" && "active"
                    }`}
                    onClick={() => setSelectImg("goldShell")}
                  >
                    2
                  </button>
                  <button
                    className={`int_img3 ${
                      selectImg === "soongum" && "active"
                    }`}
                    onClick={() => setSelectImg("soongum")}
                  >
                    3
                  </button>
                  <button
                    className={`int_img4 ${selectImg === "mbro" && "active"}`}
                    onClick={() => setSelectImg("mbro")}
                  >
                    4
                  </button>
                </div>
                <div className="tabs">
                  <div
                    className={`tab ${
                      selectImg === "goldExchange" && "active"
                    }`}
                  >
                    <ul className="prd_box2">
                      <li>
                        <p className="prd_tits1">
                          대한민국 최고의 귀금속 브랜드
                        </p>
                        <h6 className="prd_tits2">한국금거래소</h6>
                        <p className="prd_s1">
                          수시조회 4만~8만여 고객 및 회원의 관심을 기초로 <br />{" "}
                          가장 빠르고 정확한 귀금속 정보를 드리기 위해 오늘도{" "}
                          <br /> 매진하고 있습니다.
                        </p>
                        <p className="prd_s2">
                          한국금거래소는 귀금속 거래의 <br /> 투명성 확보를 위해
                          실시간 시세 정보를 제공하며, <br />
                          컨설팅을 통해 귀금속 전반에 대한 다양한 서비스를{" "}
                          <br /> 제공합니다.
                        </p>
                      </li>
                      <li>
                        <img src={goldExchange04} alt="goldexchange4" />
                      </li>
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                  <div
                    className={`tab ${selectImg === "goldShell" && "active"}`}
                  >
                    <ul className="prd_box2">
                      <li>
                        <p className="prd_tits1">
                          내면으로부터 시작되는 우아한 아름다움
                        </p>
                        <h6 className="prd_tits2">골드쉘</h6>
                        <p className="prd_s1">
                          근거 있는 아름다움을 소유한 당신의 오브제, GOLDSHELL
                          여성의 우아한 아름다움은 내면의 가치로부터 시작됩니다.
                        </p>
                        <p className="prd_s2">
                          부드럽고 강인한 여성을 표현하는 골드쉘은 자신의 고유한
                          빛을 찾아가는 이들의 신념과 만나 그 누구도 흉내낼 수
                          없는 아우라로 완성됩니다.
                        </p>
                      </li>
                      <li>
                        <img src={goldShell04} alt="goldshell4" />
                      </li>
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                  <div className={`tab ${selectImg === "soongum" && "active"}`}>
                    <ul className="prd_box2">
                      <li>
                        <p className="prd_tits1">영롱한 빛을 간직한 순금</p>
                        <h6 className="prd_tits2">순금나라</h6>
                        <p className="prd_s1">
                          금은 휘귀성, 연속성, 그리고 빛의 찬란함 때문에
                          <br /> 장신구와 화폐로 많은 사랑을 받아오고
                          <br />
                          있습니다. 현대에 이르러 반도체, 의료용 등 그 쓰임이{" "}
                          <br /> 많아 지속적으로 관심을 받아오고 있으며, <br />
                          그 가치는 상승하고 있습니다.
                        </p>
                        <p className="prd_s2">
                          순금나라는 영롱한 빛을 간직한 순금 장신구 및 <br />{" "}
                          다양한 기념품 서비스를 제공합니다.
                        </p>
                      </li>
                      <li>
                        <img src={goldWorld04} alt="goldworld4" />
                      </li>
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                  <div className={`tab ${selectImg === "mbro" && "active"}`}>
                    <ul className="prd_box2">
                      <li>
                        <p className="prd_tits1">
                          최고의 세공 기술을 가진 명장의 고품격 주얼리
                        </p>
                        <h6 className="prd_tits2">엠브로</h6>
                        <p className="prd_s1">
                          명장이 직접 핸드메이드로 완성하는 주얼리는 <br />{" "}
                          감각적인 컴템포러리 주얼리인 동시에 클래식한 <br />
                          웅장함을 표현합니다.
                        </p>
                        <p className="prd_s2">
                          40여 년 귀금속 세공에 영정을 쏟은 명장의 작품 세계가{" "}
                          <br /> 녹아 있는 주얼리는 다양하고 황홀한 빛깔의
                          <br /> 천연 보석들이 세팅되어 찬란한 빛을 더합니다.
                        </p>
                      </li>
                      <li>
                        <img src={mbro04} alt="mbro4" />
                      </li>
                    </ul>
                  </div>
                  {/* <!-- panel --> */}
                </div>
              </div>
              <div className="clear"></div>
              {/* <!-- contents end --> */}
            </div>
          </div>
        </div>
        {/* <!--**컨테이너 영역 끝--> */}
      </div>
      {/* <!-- ////// wrap --> */}
      <svg
        id="SvgjsSvg1001"
        width="2"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsxa="http://www.w3.org/1999/xa"
        xmlnssvgjs="http://svgjs.com/svgjs"
        style={{
          verflow: "hidden",
          top: "-100%",
          left: "-100%",
          position: "absolute",
          opacity: 0,
        }}
      >
        <defs id="SvgjsDefs1002"></defs>
        <polyline id="SvgjsPolyline1003" points="0,0"></polyline>
        <path id="SvgjsPath1004" d="M0 0 "></path>
      </svg>
    </>
  );
}
