import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  numberWithCommas,
  createQueryString,
  formatTime,
} from "../../lib/utils";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import AssetChart from "../../components/common/AssetChart";
import ReactPaginate from "react-paginate";

const MobileSequrityPrice = () => {
  const [type, setType] = useState("AU");
  const [range, setRange] = useState("month");
  const [fromDate, setFromDate] = useState(
    formatTime(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      "YYYY-MM-DD"
    )
  );
  const [toDate, setToDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [chartData, setChartData] = useState([]);
  const [stockPrice, setStockPrice] = useState([]);
  const [stockPriceData] = useAsync(APIS.getStockPrice, [], true);
  const { data } = stockPriceData;

  // 페이징 관련
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [chartList, setChartList] = useState([]);

  useEffect(() => {
    const queryString = createQueryString({
      type: type,
      page: pageNumber,
      size: 15,
      from: fromDate,
      to: toDate,
    });
    APIS.getSecurityChartList(queryString).then((res) => {
      const { data, success } = res.data;
      if (success) {
        if (data.content.length > 0) {
          setPageCount(data?.["totalElements"] || 0);
          setChartList(data["content"] || []);
        }
      }
    });
    // eslint-disable-next-line
  }, [type, range, pageNumber]);

  const rangeQueryString = createQueryString(
    range === "month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : {
          type: type,
          from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
          to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
        }
  );
  const actionType = () => APIS.getSecurityChart(rangeQueryString);
  const [state, refetchChart] = useAsync(actionType, [], false);
  const dropDownFirstTab = () => {
    let clickFirstTab = document.getElementById("myDropdown1");
    if (clickFirstTab.className === "dropdown-content") {
      clickFirstTab.className = "dropdown-content show";
    } else {
      clickFirstTab.className = "dropdown-content";
    }
  };
  const dropDownSecondTab = () => {
    let clickSecondTab = document.getElementById("myDropdown2");
    if (clickSecondTab.className === "dropdown-content") {
      clickSecondTab.className = "dropdown-content show";
    } else {
      clickSecondTab.className = "dropdown-content";
    }
  };
  const headerType = {
    AU: {
      engName: "GOLD",
      korName: "금",
    },
    AG: {
      engName: "SILVER",
      korName: "은",
    },
    PT: {
      engName: "PLATINUM",
      korName: "백금",
    },
    PD: {
      engName: "PALLADIUM",
      korName: "팔라듐",
    },
    USD: {
      engName: "USD",
      korName: "USD",
    },
  };

  const seriesName = headerType[type].korName;
  let arr = [];
  if (chartData?.length > 0) {
    chartData?.forEach((item) => {
      arr.push([new Date(item["date"]), item["price"]]);
    });
  }
  const searchChartDate = () => {
    setRange("");
    const queryString = createQueryString({
      type: type,
      from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
      to: formatTime(new Date(toDate), "YYYY-MM-DD"),
    });
    refetchChart(() => APIS.getSecurityChart(queryString), "", false);
  };

  const setting = {
    options: {
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            return moment(new Date(val)).format("yyyy. MM.DD");
          },
          // datetimeUTC: false,
        },
        tickAmount: 2,
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            var val = String(value).split(".");
            if (val.length <= 1) {
              //소수점이 없는 경우
              return numberWithCommas(value);
            } else {
              //소수점이 있는 경우
              return numberWithCommas(value.toFixed(2));
            }
          },
        },
        tickAmount: 4,
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return moment(value).format("yyyy년 MM월 DD일");
          },
        },
      },
      colors: [type === "AU" ? "#ffc945" : "#7c7c7c"],
    },
    series: [
      {
        name: seriesName,
        data: arr,
      },
    ],
  };
  useEffect(() => {
    const queryString = createQueryString(
      range === "month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "5year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 5)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "total"
        ? {
            type: type,
            from: "2016-01-01",
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : {
            type: type,
            from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
            to: formatTime(new Date(toDate), "YYYY-MM-DD"),
          }
    );
    const actionType =
      //   range === "live"
      // ? () => APIS.getDomesticPrice(queryString)
      () => APIS.getSecurityChart(queryString);

    refetchChart(actionType);
    // eslint-disable-next-line
  }, [type, range]);

  useEffect(() => {
    setChartData(state.data);
  }, [state]);

  useEffect(() => {
    setStockPrice(data?.["securityMainDtoList"] || []);
  }, [data]);

  const onPageChange = (props) => {
    const { selected } = props;
    setPageNumber(selected);
  };

  const onChangeDate = (type) => {
    switch (type) {
      case "month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "1year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "5year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 5)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "total":
        setFromDate("2016-01-01");
        break;

      default:
    }
    setPageNumber(0);
    setPageCount(0);
    setRange(type);
  };
  return (
    <div class="m_price_wrap">
      <div class="price_tit">
        <h3>유가증권시세</h3>
        <p>Securities Price</p>
      </div>
      <div class="price_cot scprice">
        <ul class="graph_dt_check">
          <li>
            <input
              type="checkbox"
              name="goldbar"
              value="goldbar"
              onChange={() => {
                setPageNumber(0);
                setPageCount(0);
                setType("AU");
              }}
              checked={type === "AU" ? true : false}
            />
            <label>골드바 10g</label>
          </li>
          <li>
            <input
              type="checkbox"
              name="silverbar"
              value="silverbar"
              onChange={() => {
                setPageNumber(0);
                setPageCount(0);
                setType("AG");
              }}
              checked={type === "AG" ? true : false}
            />
            <label>실버바 1kg</label>
          </li>
        </ul>
        <br />
        <br />
        <div class="m_tabs price">
          <div class="m_tab_top">
            <ul class="m_term">
              <li>
                <Link
                  to="#"
                  className={range === "month" ? "on" : ""}
                  onClick={() => onChangeDate("month")}
                >
                  1개월
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={range === "3month" ? "on" : ""}
                  onClick={() => onChangeDate("3month")}
                >
                  3개월
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={range === "year" ? "on" : ""}
                  onClick={() => onChangeDate("1year")}
                >
                  1년
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={range === "3year" ? "on" : ""}
                  onClick={() => onChangeDate("3year")}
                >
                  3년
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={range === "5year" ? "on" : ""}
                  onClick={() => onChangeDate("5year")}
                >
                  5년
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={range === "total" ? "on" : ""}
                  onClick={() => onChangeDate("total")}
                >
                  전체
                </Link>
              </li>
            </ul>
          </div>
          <ul>
            <li class="m_last">
              <div class="m_data_day">
                <input
                  className="datepicker1 hasDatepicker"
                  type="date"
                  id="dp1636523011796"
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                  disabled={true}
                  value={fromDate}
                />
              </div>
              <div class="m_data_day">
                <input
                  className="datepicker2 hasDatepicker"
                  type="date"
                  id="dp1636523011801"
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  disabled={true}
                  value={toDate}
                />
              </div>
              <button
                onClick={searchChartDate}
                className="m_search_btn1"
                style={{ background: "#7d7d7d", color: "#fff" }}
                disabled={true}
              >
                조회
              </button>
            </li>
          </ul>
          <div>
            <AssetChart
              options={setting.options}
              series={setting.series}
              type="area"
              width="100%"
              height="300"
            />
          </div>
        </div>
      </div>
      <div class="m_clear"></div>
      <div class="now_price">
        <h4 class="m_mtit">실시간 판매가</h4>
        <table class="m_nptable">
          <tr>
            <th rowspan="2">권종</th>
            <th>골드바</th>
            <th>실버바</th>
          </tr>
          <tr class="color_price">
            <td>10g</td>
            <td>1,000g</td>
          </tr>
          <tr>
            <th>판매가격</th>
            <td>{`${numberWithCommas(stockPrice[0]?.price)}`}원</td>
            <td>{`${numberWithCommas(stockPrice[1]?.price)}`}원</td>
          </tr>
        </table>
      </div>
      <div class="m_clear"></div>
      <p class="m_sgtable_top">
        총 <b id="tatalMarket">{pageCount}</b>개
      </p>
      <table class="m_sgtable">
        <thead>
          <tr>
            <td>고지시간</td>
            <td>{type === "AU" ? "골드바 10g" : "실버바 1kg"}</td>
          </tr>
        </thead>
        {chartList.map((item, index) => {
          return (
            <tr key={index}>
              <td>{item?.date || "-"}</td>
              <td>{numberWithCommas(item?.price) || "-"}</td>
            </tr>
          );
        })}
      </table>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          className={"pagination next"}
          style={{
            border: "1px solid lightgray",
            borderRadius: "10%",
            height: "30px",
            lineHeight: "25px",
            color: "#337ab7",
          }}
          onClick={() => {
            if (pageNumber === 0) {
              return false;
            }
            setPageNumber(0);
          }}
        >
          {"<<"}
        </button>
        &nbsp;
        <ReactPaginate
          className={"pagination"}
          pageCount={Math.ceil(pageCount / 15)}
          forcePage={pageNumber}
          pageRangeDisplayed={5}
          itemsCountPerPage={5}
          marginPagesDisplayed={0}
          previousLabel={"<"}
          nextLabel={">"}
          activeClassName={"tabulator-page active"}
          onPageChange={onPageChange}
        />
        &nbsp;
        <button
          className={"pagination previou"}
          style={{
            border: "1px solid lightgray",
            borderRadius: "10%",
            height: "30px",
            lineHeight: "25px",
            color: "#337ab7",
          }}
          onClick={() => {
            if (pageNumber === Math.ceil(pageCount / 15) - 1) {
              return false;
            } else {
              setPageNumber(Math.ceil(pageCount / 15) - 1);
            }
          }}
        >
          {">>"}
        </button>
      </div>
      <div className="m_clear"></div>
    </div>
  );
};

export default MobileSequrityPrice;
