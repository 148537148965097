import React, { useState, useEffect } from "react";
import useAsync from "../../lib/hooks/useAsync";
import { createQueryString } from "../../lib/utils";
import * as APIS from "../../lib/api/index";

const MobileLdnFix = () => {
  const [date, setDate] = useState(new Date().getFullYear().toString());
  const [data, setData] = useState({});
  const queryString = createQueryString({ year: date });
  const [lodonFixData, refetch] = useAsync(
    () => APIS.getLondonFixInquiry(queryString),
    [],
    true
  );

  useEffect(() => {
    setData(lodonFixData.data);
  }, [lodonFixData, date]);

  const dateKey = Object.keys(data || {});

  // eslint no-extend-native: ["error", { "exceptions": ["Object"] }]
  // eslint freeze: true */
  Array.prototype.division = function (n) {
    var arr = this;
    var len = arr.length;
    var cnt = Math.floor(len / n);
    var tmp = [];

    for (var i = 0; i <= cnt; i++) {
      tmp.push(arr.splice(0, n));
    }

    return tmp;
  };

  const parseDate = dateKey.division(5);
  return (
    <div class="m_price_wrap">
      <div class="m_contents">
        <div class="price_tit">
          <h3>지난 London Fix 시세</h3>
          <p>Last London Fix Price</p>
        </div>
        <div class="m_londonfix_cont">
          <div>
            <select
              defaultValue={date}
              onChange={(e) => {
                const { value } = e.target;
                const query = createQueryString({ year: value });
                refetch(() => APIS.getLondonFixInquiry(query), "", false);
                setDate(value);
              }}
            >
              <option value="2023">2023</option>
              <option value="2022">2022</option>
              <option value="2021">2021</option>
            </select>
          </div>
          <div>
            <table style={{ width: "100%" }} class="m_lf_table">
              <thead>
                <tr>
                  <td class="lf_date">날짜</td>
                  <td>
                    금<br />
                    <span class="m_unit10">(Gold)</span>
                  </td>
                  <td class="lf_pk">
                    은<br />
                    <span class="m_unit10">(Silver)</span>
                  </td>
                  <td>
                    백금
                    <br />
                    <span class="m_unit10">(Platinum)</span>
                  </td>
                  <td class="lf_pk">
                    팔라듐
                    <br />
                    <span class="m_unit10">(Palladium)</span>
                  </td>
                </tr>
              </thead>
            </table>
            {(parseDate || []).map((item) => {
              // item = index , index 안에 5개의 어레이가 있다.
              return (
                <table className="m_lf_table" style={{ width: "100%" }}>
                  {(item || []).map((el, index) => {
                    const au =
                      data?.[el]?.find((item) => item.type === "Au") || {};
                    const ag =
                      data?.[el]?.find((item) => item.type === "Ag") || {};
                    const pt =
                      data?.[el]?.find((item) => item.type === "Pt") || {};
                    const pd =
                      data?.[el]?.find((item) => item.type === "Pd") || {};

                    const parseDate = el.split("-");

                    return (
                      <tr key={index}>
                        <td className="lf_date" style={{ width: "11%" }}>
                          {parseDate[0]}
                          <br />
                          {`${parseDate[1]}.${parseDate[2]}`}
                        </td>
                        <td className="lf_date" style={{ width: "9%" }}>
                          AM
                          <br />
                          PM
                        </td>
                        <td>
                          {au?.am || "-"}
                          <br />
                          {au?.pm || "-"}
                        </td>
                        <td className="lf_pk">{ag?.am || "-"}</td>
                        <td>
                          {pt?.am || "-"}
                          <br />
                          {pt?.pm || "-"}
                        </td>
                        <td className="lf_pk">
                          {pd?.am || "-"}
                          <br />
                          {pd?.pm || "-"}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileLdnFix;
