import React from "react";
import { Link } from "react-router-dom";
import ico_cs from "../../assets/img/ico_cs.png";
import ico_support from "../../assets/img/ico_support.png";
import { routes } from "../../lib/routes";

const MobileFooter = () => {
  return (
    <footer id="footer">
      <div>
        <div className="m_infor_wrap1">
          <div className="m_infor_box1">
            <ul>
              <li>
                <Link to={routes.termService}>이용약관</Link>
              </li>
              <li>
                <Link to={routes.privacyPolicy}>개인정보 처리 방침</Link>
              </li>
            </ul>
            <select
              defaultValue={""}
              className="m_select_type2 sel_family"
              onChange={(e) => {
                const { value } = e.target;
                if (value !== "") {
                  window.location.href = value;
                }
              }}
            >
              <option value="">Family Site</option>
              <option value="http://www.goldshell.co.kr/">골드쉘</option>
              <option value="http://www.soongumnara.co.kr/">순금나라</option>
              <option value="https://www.koreagoldx.co.kr/">
                한국금거래소
              </option>
              <option value="http://www.exgoldftc.co.kr/">
                한국금거래소 FTC
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="m_footer_wrap">
        <div className="m_footer_info">
          <p className="addres2">
            본사 : 서울 종로구 돈화문로5가길 1 피카디리플러스 8층
          </p>
          <p className="mb15">TEL : 070-4717-0740 FAX : 02)741-6314</p>
          <p className="addres2">직영점 : 서울 종로구 종로 128 화영빌딩 1층</p>
          <p className="mb15">TEL:02)744-9990 FAX: 02)741-6314</p>
          <p>상호:주식회사 한국금거래소 / 대표이사:송종길</p>
          <p>사업자등록번호:101-86-11518</p>
          <p>통신판매업신고번호:제01-1999호</p>
          <p className="mb15">
            개인정보보호관리책임자:안광호
          </p>
          <p className="addres2">
            <img src={ico_cs} /> 실시간 상담 <span>1544 9993</span>
          </p>
          <p className="mb15">
            <img src={ico_support} /> 금제품 문의 <span>02 744 9999</span>
          </p>
          <p>Copyright Korea Gold Exchange All, rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default MobileFooter;
