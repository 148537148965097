import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";
export default function PurchasePrice() {

  const [purchasePrice, setPurchasePrice] = useState([]);
  const [purchasePriceData] = useAsync(APIS.getPurchasePrice, [], true);
  const { data } = purchasePriceData;

  const compareFluctuationRate = (type, index) => {
    switch (type) {
      case "default":
        if (purchasePrice[index]?.fluctuationRate > 0) {
          return (
            <td className="ch_up">{purchasePrice[index]?.fluctuationRate}%</td>
          );
        } else if (purchasePrice[index]?.fluctuationRate < 0) {
          return (
            <td className="ch_dw">{purchasePrice[index]?.fluctuationRate}%</td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (purchasePrice[index]?.fluctuation > 0) {
          return (
            <td>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(purchasePrice[index]?.fluctuation)}
              </span>
            </td>
          );
        } else if (purchasePrice[index]?.fluctuation < 0) {
          return (
            <td>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(purchasePrice[index]?.fluctuation)}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
         // no default
    }
  };
  useEffect(() => {
    setPurchasePrice(data?.["purchasePriceMainDtoList"] || []);
  }, [data]);

  return (
    <li style={{ width: "500px", height: "467px", float: "right" }}>
      <h4 className="mdtitl1">사업자 매입 시세</h4>
      <table>
        <thead>
          <tr>
            <td>종류</td>
            <td>시세</td>
            <td>변동</td>
            <td>등락률</td>
          </tr>
        </thead>
        <tbody style={{ background: "#fff"}}>
          <tr>
            <td>순금시세</td>
            <td>{numberWithCommas(purchasePrice[0]?.price)}</td>
            {compareFluctuationRate("arrow", 0)}
            {compareFluctuationRate("default", 0)}
          </tr>
          <tr>
            <td>18k 금시세</td>
            <td>{numberWithCommas(purchasePrice[1]?.price)}</td>
            {compareFluctuationRate("arrow", 1)}
            {compareFluctuationRate("default", 1)}
          </tr>
          <tr>
            <td>14k 금시세</td>
            <td>{numberWithCommas(purchasePrice[2]?.price)}</td>
            {compareFluctuationRate("arrow", 2)}
            {compareFluctuationRate("default", 2)}
          </tr>
          <tr>
            <td>백금시세</td>
            <td>{numberWithCommas(purchasePrice[3]?.price)}</td>
            {compareFluctuationRate("arrow", 3)}
            {compareFluctuationRate("default", 3)}
          </tr>
          <tr>
            <td>은시세</td>
            <td>{numberWithCommas(purchasePrice[4]?.price)}</td>
            {compareFluctuationRate("arrow", 4)}
            {compareFluctuationRate("default", 4)}
          </tr>
        </tbody>
      </table>
      <p className="gtime" style={{marginTop: "17px"}}>고시시간 :{data ? formatDate(new Date(data?.regdate)): "-"}</p>
    </li>
  );
}
