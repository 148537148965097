import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import MobileFooter from "./MobileFooter";
import MobileHeader from "./MobileHeader";
import menu from "../../assets/img/menu_bar.png";
import "../../assets/css/layout_mobile.css";
import "../../assets/css/main_mobile.css";
import "../../assets/css/common_mobile.css";
import "../../assets/css/sub_mobile.css";
import MobileSideMenu from "./MobileSideMenu";
import MobileEtcHeader from "./MobileEtcHeader";
import { routes } from "../../lib/routes";

const MobileMainLayout = (props) => {
  const { children } = props;
  const [expanded, setExpanded] = useState(false);
  const firstLoadRef = useRef(false);
  const location = useLocation();

  const path = location.pathname;

  const check =
    path.includes("/company/") ||
    path.includes("/price/") ||
    path.includes("premiumPrice")
      ? true
      : false;

  useEffect(() => {
    if (!firstLoadRef.current) {
      firstLoadRef.current = true;
    }
    if (expanded) {
      document.getElementById("main-root").style.overflow = "hidden";
    } else {
      document.getElementById("main-root").style.overflow = "auto";
    }
  }, [expanded]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [path]);

  return (
    <div id="m_main" className="m_main">
      {expanded && (
        <div
          style={{
            position: "fixed",
            width: "100% !important",
            zIndex: 100,
          }}
        >
          <MobileSideMenu setExpanded={setExpanded} check={check} />
        </div>
      )}
      <div className="header">
        <MobileHeader />
      </div>
      <div className="m_common_bt">
        <ul>
          <li className={path === "" || path === "/" ? "on" : ""}>
            <Link to={routes.main}>Home</Link>
          </li>
          <li className={path.includes("/premiumPrice") ? "on" : ""}>
            <Link to={routes.premiumPrice}>
              매매
              <br />
              프리미엄
            </Link>
          </li>
          <li className={path.includes("/price/inquiry/") ? "on" : ""}>
            <Link to={routes.domesticPriceInquiry}>시세조회</Link>
          </li>
          <li className="menu" onClick={() => setExpanded(!expanded)}>
            <img src={menu} alt="메뉴" />
          </li>
        </ul>
      </div>
      <div className="m_wrap">
        {check && !expanded && (
          <div className="m_visual_sub">
            <MobileEtcHeader />
          </div>
        )}
        <div
          style={{
            marginTop:
              check && !expanded && path !== "/premiumPrice"
                ? "100px"
                : check && !expanded && path === "/premiumPrice"
                ? "47px"
                : "0px",
          }}
        >
          {/* {children} */}
          {children.props.children}
        </div>
      </div>
      <div>
        <MobileFooter />
      </div>
    </div>
  );
};

export default MobileMainLayout;
