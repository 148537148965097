import React, { useEffect, useState } from "react";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import {numberWithCommas, formatDate} from "../../lib/utils"
export default function ExchangeRate() {
  const [exchangeRate, setExchangeRate] = useState([]);
  const [exchageRateData, refetch] = useAsync(APIS.getExchangeRate, [], true);
  const { data } = exchageRateData;

  const compareFluctuationRate = (type, index) => {
    switch (type) {
      case "default":
        if (exchangeRate[index]?.fluctuationRate > 0) {
          return (
            <td className="ch_up">{exchangeRate[index]?.fluctuationRate}%</td>
          );
        } else if (exchangeRate[index]?.fluctuationRate < 0) {
          return (
            <td className="ch_dw">{exchangeRate[index]?.fluctuationRate}%</td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (exchangeRate[index]?.fluctuation > 0) {
          return (
            <td>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {exchangeRate[index]?.fluctuation}
              </span>
            </td>
          );
        } else if (exchangeRate[index]?.fluctuation < 0) {
          return (
            <td>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {exchangeRate[index]?.fluctuation}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
        // no default
    }
  };
  useEffect(() => {
    setExchangeRate(data?.["exchangeRateDtoList"] || []);
  }, [data]);

  useEffect(()=> {
    const interval = setInterval(()=> {
      refetch(() => APIS.getExchangeRate(), "", false);
    }, 60000)
    return()=>clearInterval(interval)
    // eslint-disable-next-line
  },[])

  return (
    <li className="money_box1">
      <h4 className="mdtitl1">환율</h4>
      <table>
        <thead>
          <tr>
            <td>통화명</td>
            <td>내가 살때</td>
            <td>전일대비</td>
            <td>등락률</td>
          </tr>
        </thead>
        <tbody style={{ background: "#fff"}}>
          <tr>
            <td>달러인덱스</td>
            <td>{numberWithCommas(exchangeRate[0]?.ask)}</td>
            {compareFluctuationRate("arrow", 0)}
            {compareFluctuationRate("default", 0)}
          </tr>
          <tr>
            <td>
              미국<span>USD</span>
            </td>
            <td>{numberWithCommas(exchangeRate[1]?.ask)}</td>
            {compareFluctuationRate("arrow", 1)}
            {compareFluctuationRate("default", 1)}
          </tr>
          <tr>
            <td>
              일본<span>JPY100</span>
            </td>
            <td>{numberWithCommas(exchangeRate[2]?.ask)}</td>
            {compareFluctuationRate("arrow", 2)}
            {compareFluctuationRate("default", 2)}
          </tr>
          <tr>
            <td>
              유럽연합<span>EUR</span>
            </td>
            <td>{numberWithCommas(exchangeRate[3]?.ask)}</td>
            {compareFluctuationRate("arrow", 3)}
            {compareFluctuationRate("default", 3)}
          </tr>
          <tr>
            <td>
              중국<span>CNY</span>
            </td>
            <td>{exchangeRate[4]?.ask}</td>
            {compareFluctuationRate("arrow", 4)}
            {compareFluctuationRate("default", 4)}
          </tr>
        </tbody>
      </table>
      <p className="gtime" style={{marginTop: "17px"}}>고시시간 : {data ? formatDate(new Date(data?.regdate)): "-"}</p>
    </li>
  );
}
