import React, { useState, useEffect, useRef } from "react";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import {
  numberWithCommas,
  createQueryString,
  formatDate,
} from "../../lib/utils";

const BlinkText = (props) => {
  const { children, id, style = {}, className = "" } = props;

  const blinkEffect = () => {
    document?.getElementById(id)?.classList?.add("blink_me");
    setTimeout(() => {
      document?.getElementById(id)?.classList?.remove("blink_me");
    }, 3000);
  };
  return (
    <span style={{ ...style }} className={className} id={id}>
      {children}
      {blinkEffect()}
    </span>
  );
};

const MobilePremiumPrice = () => {
  const assetTypeRef = useRef();
  const bidAskTypeRef = useRef();
  const intervalRef = useRef();
  const [assetType, setAssetType] = useState("AU");
  const [bidAskType, setBidAskType] = useState("ASK");
  const [domesitPercentData, setDomesticPercentData] = useState();
  const [domesitMarketPercentData, setDomesticMarketPercentData] = useState();

  const queryString = createQueryString({
    assetType: assetType,
    bidAskType: bidAskType,
  });

  const [percentData, refetch] = useAsync(
    () => APIS.getPercent(queryString),
    [],
    false
  );
  const { data } = percentData;

  useEffect(() => {
    assetTypeRef.current = assetType || "AU";
    bidAskTypeRef.current = bidAskType || "ASK";
    setDomesticPercentData(data?.["percentPremiumMainDtoList"] || []);
    setDomesticMarketPercentData(data?.["percentMarketPriceDto"] || []);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    const query = createQueryString({
      assetType: assetType,
      bidAskType: bidAskType,
    });
    refetch(() => APIS.getPercent(query), "", false);
    // eslint-disable-next-line
  }, [assetType, bidAskType]);

  useEffect(() => {
    const query = createQueryString({
      assetType: assetType,
      bidAskType: bidAskType,
    });
    const interval = setInterval(() => {
      refetch(() => APIS.getPercent(query), "", false);
    }, 60000);
    intervalRef.current = interval;
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const intervalAction = () => {
    const query = createQueryString({
      assetType: assetTypeRef.current,
      bidAskType: bidAskTypeRef.current,
    });
    const interval = setInterval(() => {
      refetch(() => APIS.getPercent(query), "", false);
    }, 5000);
    intervalRef.current = interval;
  };

  return (
    <div className="m_pre_wrap" style={{ padding: "0px 25px 40px" }}>
      {/* <div className="pre_tit">
        <h3>매매프리미엄</h3>
        <p>Sale Premium</p>
      </div> */}
      <div className="pre_cot">
        <div className="m_tbl_table2">
          <ul>
            <li
              className={`m_tbl_bs ${bidAskType === "ASK" && "active"}`}
              onClick={() => {
                clearInterval(intervalRef.current);
                bidAskTypeRef.current = "ASK";
                setBidAskType("ASK");
                //intervalAction();
              }}
            >
              매수(Buy)
            </li>
            <li
              className={`m_tbl_bs ${bidAskType === "BID" && "active"}`}
              onClick={() => {
                clearInterval(intervalRef.current);
                bidAskTypeRef.current = "BID";
                setBidAskType("BID");
                //intervalAction();
              }}
            >
              매도(Sell)
            </li>
          </ul>
          <ul>
            <li
              className={`m_tbl_pro ${assetType === "AU" && "active"}`}
              onClick={() => {
                clearInterval(intervalRef.current);
                assetTypeRef.current = "AU";
                setAssetType("AU");
              }}
            >
              금(Au)
            </li>
            <li
              className={`m_tbl_pro ${assetType === "AG" && "active"}`}
              onClick={() => {
                clearInterval(intervalRef.current);
                assetTypeRef.current = "AG";
                setAssetType("AG");
                //intervalAction();
              }}
            >
              은(Ag)
            </li>
            <li
              className={`m_tbl_pro ${assetType === "PT" && "active"}`}
              onClick={() => {
                clearInterval(intervalRef.current);
                assetTypeRef.current = "PT";
                setAssetType("PT");
                //intervalAction();
              }}
            >
              백금(Pt)
            </li>
            <li
              className={`m_tbl_pro ${assetType === "PD" && "active"}`}
              onClick={() => {
                clearInterval(intervalRef.current);
                assetTypeRef.current = "PD";
                setAssetType("PD");
                //intervalAction();
              }}
            >
              팔라듐(Pd)
            </li>
            {/* <li class="tbl_pro active">금(Au)</li>
          <li class="tbl_pro">은(Ag)</li>
          <li class="tbl_pro">백금(Pt)</li>
          <li class="tbl_pro">팔라듐(Pd)</li> */}
          </ul>
          <ul className="m_tbl_cot_ul">
            <li className="m_tbl_tit">프리미엄율</li>
            <li className="m_tbl_tit">국내가(g)</li>
            <li className="m_tbl_tit">국내가(3.75g)</li>
            <li className="m_tbl_tit">국제가($)</li>
          </ul>
          {(domesitPercentData || []).map((item, index) => {
            return (
              <ul className="m_tbl_cot_ul" key={`domestic${index}`}>
                <li className="m_tbl_cot">{item.premium.toFixed(2) || "-"}</li>
                <li className="m_tbl_cot">
                  {numberWithCommas(item.domesticPrice) || "-"}
                </li>
                <li className="m_tbl_cot">
                  {numberWithCommas(item.domesticPriceDon) || "-"}
                </li>
                <li className="m_tbl_cot">
                  {numberWithCommas(item.globalPrice.toFixed(2)) || "-"}
                </li>
              </ul>
            );
          })}
          <table className="m_tbl_tb2">
            <tr>
              <td>국제가($)</td>
              <td>
                <BlinkText id="premium_globalPrice">
                  {numberWithCommas(domesitMarketPercentData?.globalPrice)}
                </BlinkText>
              </td>
              <td>환율(₩)</td>
              <td>
                <BlinkText id="premium_exchangeRate">
                  {numberWithCommas(domesitMarketPercentData?.exchangeRate)}
                </BlinkText>
              </td>
            </tr>
            <tr>
              <td>기준가(g)</td>
              <td>
                <BlinkText id="premium_domesticPrice">
                  {numberWithCommas(domesitMarketPercentData?.domesticPrice)}
                </BlinkText>
              </td>
              <td>기준가(3.75g)</td>
              <td>
                <BlinkText id="premium_domesticPriceDon">
                  {numberWithCommas(domesitMarketPercentData?.domesticPriceDon)}
                </BlinkText>
              </td>
            </tr>
          </table>
          <p className="m_gtime">
            고시시간 : {data ? formatDate(new Date(data?.regdate)) : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobilePremiumPrice;
