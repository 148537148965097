import React, { useEffect, useState } from "react";
import downArrow from "../../assets/img/down.png";
import upArrow from "../../assets/img/up.png";
import midd from "../../assets/img/midd.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";

const MobileEtc = () => {
  const [wholesalePrice, setWholesalePrice] = useState([]);
  const [wholesaleData, refetch] = useAsync(APIS.getWholesaleEtc, [], true);
  const { data } = wholesaleData;

  const [exchangeRate, setExchangeRate] = useState([]);
  const [exchageRateData, refetchRate] = useAsync(
    APIS.getExchangeRate,
    [],
    true
  );
  const { data: rateData } = exchageRateData;

  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "GC") {
        arrayData[0] = item;
      }
      if (item.type === "SC") {
        arrayData[1] = item;
      }
      if (item.type === "SP") {
        arrayData[2] = item;
      }
      if (item.type === "IR") {
        arrayData[3] = item;
      }
      if (item.type === "PSC") {
        arrayData[4] = item;
      }
      if (item.type === "SN") {
        arrayData[5] = item;
      }
    });
    return arrayData;
  };

  const compareFluctuationExchangeRate = (type, index) => {
    switch (type) {
      case "default":
        if (exchangeRate[index]?.fluctuationRate > 0) {
          return (
            <td className="ch_up">
              {Math.abs(exchangeRate[index]?.fluctuationRate)}%
            </td>
          );
        } else if (exchangeRate[index]?.fluctuationRate < 0) {
          return (
            <td className="ch_dw">
              {Math.abs(exchangeRate[index]?.fluctuationRate)}%
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (exchangeRate[index]?.fluctuation > 0) {
          return (
            <td>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {Math.abs(exchangeRate[index]?.fluctuation)}
              </span>
            </td>
          );
        } else if (exchangeRate[index]?.fluctuation < 0) {
          return (
            <td>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {Math.abs(exchangeRate[index]?.fluctuation)}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      // no default
    }
  };

  const compareFluctuationRate = (type, fluctuation, indexState) => {
    switch (type) {
      case "arrow":
        if (fluctuation > 0) {
          return (
            <td style={{ textAlign: "-webkit-center" }}>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(Math.abs(fluctuation))}
              </span>
            </td>
          );
        } else if (fluctuation < 0) {
          return (
            <td style={{ textAlign: "-webkit-center" }}>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(Math.abs(fluctuation))}
              </span>
            </td>
          );
        } else {
          return (
            <td style={{ textAlign: "-webkit-center" }}>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      // no default
    }
  };
  useEffect(() => {
    setWholesalePrice(reformData(data?.["wholesalePriceMainDtoList"]) || []);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getWholesaleEtc(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setExchangeRate(rateData?.["exchangeRateDtoList"] || []);
  }, [rateData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchRate(() => APIS.getExchangeRate(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="m_mcontainer1">
        <div
          data-aos="zoom-in"
          className="m_mmiddle_area1 aos-init aos-animate"
        >
          <ul id="etc_rate" className="m_pro_money1">
            <li>
              <h4 className="m_mtit">기타 금속 도매시세</h4>
              <table>
                <thead>
                  <tr>
                    <td>금속기준가</td>
                    <td>(₩/g)</td>
                    <td>(₩/3.75g)</td>
                    <td>변동(₩/g)</td>
                  </tr>
                </thead>
                <tbody>
                  {wholesalePrice?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.description}</td>
                        <td>{numberWithCommas(item.priceGram)}</td>
                        {item.description === "청화금" ||
                        item.description === "이리듐" ? (
                          <td>{numberWithCommas(item.priceDon)} <br/>(₩/100g)</td>
                        ) : (
                          <td>{numberWithCommas(item.priceDon)} <br/>(₩/1kg)</td>
                        )}
                        {compareFluctuationRate("arrow", item.fluctuation)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="m_gtime">
                고시시간 : {data ? formatDate(new Date(data?.regdate)) : "-"}
              </p>
            </li>
            <div className="m_clear"></div>
            <li className="m_money_box1">
              <h4 className="m_mtit">환율</h4>
              <table>
                <thead>
                  <tr>
                    <td>통화명</td>
                    <td>내가 살때</td>
                    <td>전일대비</td>
                    <td>등락률</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>달러인덱스</td>
                    <td>{numberWithCommas(exchangeRate[0]?.ask)}</td>
                    {compareFluctuationExchangeRate("arrow", 0)}
                    {compareFluctuationExchangeRate("default", 0)}
                  </tr>
                  <tr>
                    <td>
                      미국<span>USD</span>
                    </td>
                    <td>{numberWithCommas(exchangeRate[1]?.ask)}</td>
                    {compareFluctuationExchangeRate("arrow", 1)}
                    {compareFluctuationExchangeRate("default", 1)}
                  </tr>
                  <tr>
                    <td>
                      일본<span>JPY100</span>
                    </td>
                    <td>{numberWithCommas(exchangeRate[2]?.ask)}</td>
                    {compareFluctuationExchangeRate("arrow", 2)}
                    {compareFluctuationExchangeRate("default", 2)}
                  </tr>
                  <tr>
                    <td>
                      유럽연합<span>EUR</span>
                    </td>
                    <td>{numberWithCommas(exchangeRate[3]?.ask)}</td>
                    {compareFluctuationExchangeRate("arrow", 3)}
                    {compareFluctuationExchangeRate("default", 3)}
                  </tr>
                  <tr>
                    <td>
                      중국<span>CNY</span>
                    </td>
                    <td>{exchangeRate[4]?.ask}</td>
                    {compareFluctuationExchangeRate("arrow", 4)}
                    {compareFluctuationExchangeRate("default", 4)}
                  </tr>
                </tbody>
              </table>
              <p className="m_gtime">
                고시시간 :{" "}
                {rateData ? formatDate(new Date(rateData?.regdate)) : "-"}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="m_clear"></div>{" "}
    </>
  );
};

export default MobileEtc;
