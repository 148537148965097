import React from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/common.css";
import BasePriceWholesalePrice from "../main/BasePriceWholesalePrice";
import DomesticPercentCalculate from "./DomesticPercentCalculate";
import WholesalePrices from "./WholesalePrices";
import ExchangeRate from "./ExchangeRate";
import StockPrice from "./StockPrice";
import PurchasePrice from "./PurchasePrice";
import LdnFix from "./LdnFix";
import MetalPrices from "./MetalPrices";
// import Chart from "react-apexcharts";
import MajorIndex from "./MajorIndex";
import LiveSpotPrice from "./LiveSpotPrice";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import ExchangeRateSection from "./ExchangeRateSection";
import { Link } from "react-router-dom";
import DomesticAssetChart from "./DomesticAssetChart";
import banner3 from "../../assets/img/main/banner_3.jpeg";
import banner1 from "../../assets/img/main/banner_1.jpeg";
import banner2 from "../../assets/img/main/banner_2.jpeg";
import bannerFTC from "../../assets/img/main/banner_ftc_pc.jpeg";

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Main() {
  const banner = [
    { image: banner1 },
    { image: banner2 },
    { image: banner3 },
    {
      image: bannerFTC,
      link: "https://www.koreagoldx.co.kr/main/html.php?htmid=service/FTC.html",
    },
  ];

  return (
    <div id="wrap">
      {/* <!-- 비쥬얼 박스 시작 --> */}
      <div className="visual_box">
        <div
          className="swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal"
          //style={{ height: "444px !important" }}
        >
          <Swiper
            className="banner"
            spaceBetween={100}
            slidesPerView={1}
            loop={true}
            navigation
            // pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
          >
            {banner.map((item, index) => {
              const { image, link } = item;
              return (
                <SwiperSlide
                  key={index}
                  style={{ cursor: link ? "pointer" : "unset" }}
                  onClick={() => {
                    {
                      link && (window.location.href = link);
                    }
                  }}
                >
                  <div
                    className="swiper-slide"
                    style={{
                      minWidth: "1420px",
                      opacity: 1,
                      margin: "0 auto",
                      transitionDuration: "0ms",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={image || ""}
                      alt="배너 이미지"
                      style={{ minWidth: "1420px", height: "480px" }}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <LiveSpotPrice />
      </div>
      {/* <!-- 비쥬얼 박스 끝 --> */}
      {/* <!--**컨테이너 영역 시작--> */}
      <div className="mcontainer_wrap">
        {/* <!--======================================================= 컨테이너--> */}
        {/* <!--  환율 USD --> */}
        <ExchangeRateSection />
        {/* <!-- ///////환율 USD --> */}

        <div className="p50">
          {/* <!--*중단 영역 시작1--> */}
          <div className="mcontainer1">
            <div className="mmiddle_area1">
              <ul className="pro_money1" style={{ height: "720px" }}>
                <li data-aos="zoom-in" className="aos-init aos-animate">
                  <BasePriceWholesalePrice />
                </li>
                <li data-aos="zoom-in" className="aos-init aos-animate">
                  <DomesticPercentCalculate />
                </li>
              </ul>
            </div>
          </div>
          {/* <!--*중단 영역 끝1--> */}
          {/* <!--*중단 영역 시작2--> */}
          <div className="mcontainer2">
            <div
              className="mmiddle_area2 aos-init aos-animate"
              data-aos="zoom-in"
            >
              <ul className="inter_chart1">
                {/* <TestChart /> */}
                <DomesticAssetChart
                  title="국제 실시간 상품차트"
                  header={[
                    "GOLD,AU",
                    "SILVER,AG",
                    "PLATINUM,PT",
                    "PALLADIUM,PD",
                    "환율 USD,USD",
                  ]}
                  color="#f89b00"
                  searchType="international"
                  name="chart1"
                  id="domesticChart1"
                  routeName="internationalPriceInquiry"
                  // yaxisTitle="(USD/T.oz)"
                />
                <DomesticAssetChart
                  title="국내 실시간 상품차트"
                  header={[
                    "금,AU,금",
                    "은,AG,은",
                    "백금,PT,백금",
                    "팔라듐,PD,팔라듐",
                  ]}
                  searchType="domestic"
                  name="chart2"
                  id="domesticChart2"
                  routeName="routes.domesticPriceInquiry"
                  // yaxisTitle="(₩/g)"
                />
              </ul>
            </div>
          </div>
          {/* <!--*중단 영역 끝2--> */}
          <div className="mcontainer1">
            <div
              data-aos="zoom-in"
              className="mmiddle_area1 aos-init aos-animate"
            >
              <ul id="etc_rate" className="pro_money1">
                {/* <MarketValueTable
                  title="기타도매시세"
                  data={data}
                  columns={[
                    { label: "금속기준가", key: "item", type: "default" },
                    { label: "(₩/g)", key: "wg", type: "default" },
                    { label: "(₩/3.75g)", key: "wg3", type: "default" },
                    { label: "변동(₩/g)", key: "variance", type: "variance" },
                  ]}
                /> */}

                <WholesalePrices />
                {/* <MarketValueTable
                  title="환율"
                  data={data}
                  columns={[
                    {
                      label: "통화명",
                      key: "currencyName",
                      type: "currencyName",
                    },
                    { label: "내가 살때", key: "buy", type: "default" },
                    { label: "전일대비", key: "variance", type: "variance" },
                    {
                      label: "등락률",
                      key: "fluctuationRate",
                      type: "fluctuationRate",
                    },
                  ]}
                /> */}
                <ExchangeRate />
              </ul>
            </div>
          </div>
          {/* <!--*중단 영역 시작4--> */}
          <div className="mcontainer1">
            <div
              data-aos="zoom-in"
              className="mmiddle_area1 aos-init aos-animate"
            >
              <ul id="down_price" className="pro_money2">
                <StockPrice /> {/* 유가증권 시세 */}
                <PurchasePrice /> {/* 매입 시세 */}
                <LdnFix /> {/* LDNFix */}
                <MetalPrices /> {/* 기타금속 시세 */}
              </ul>
              {/* <MarketValueTable
                  title="유가증권 시세"
                  data={data}
                  columns={[
                    { label: "권종", key: "securities", type: "default" },
                    { label: "판매가격", key: "buyFee", type: "default" },
                    { label: "변동", key: "variance", type: "variance" },
                    {
                      label: "등락률",
                      key: "fluctuationRate",
                      type: "fluctuationRate",
                    },
                  ]}
                /> */}
              {/* <MarketValueTable
                  title="매입 시세"
                  data={data}
                  columns={[
                    { label: "종류", key: "securities", type: "default" },
                    { label: "시세", key: "buyFee", type: "default" },
                    { label: "변동", key: "variance", type: "variance" },
                    {
                      label: "등락률",
                      key: "fluctuationRate",
                      type: "fluctuationRate",
                    },
                  ]}
                />
                <MarketValueTable
                  title="LDN Fix"
                  data={data}
                  columns={[
                    { label: "종류", key: "itemLdn", type: "default" },
                    { label: "AM", key: "am", type: "default" },
                    { label: "PM", key: "pm", type: "default" },
                  ]}
                /> */}
              {/* <MarketValueTable
                  title="기타 금속 시세"
                  data={data}
                  columns={[
                    { label: "종류", key: "itemLdn", type: "default" },
                    { label: "시세", key: "marketValue", type: "default" },
                    { label: "변동", key: "variance", type: "variance" },
                    { label: "날짜", key: "date", type: "default" },
                  ]}
                /> */}
            </div>
          </div>
          {/* <!--*중단 영역 끝4--> */}
          {/* <!--*중단 영역 시작5--> */}

          {/* 주요지수 */}
          <MajorIndex />
          {/* <!--*중단 영역 끝5--> */}
          {/* <!--*배너 영역 시작7--> */}
          <div className="mcontainer7">
            <div
              className="mmiddle_area7 aos-init aos-animate"
              data-aos="zoom-in"
            >
              <div className="bottom_banner">
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={() => window.open("http://koreagoldx.co.kr/")}
                    >
                      <img
                        src="https://koreagoldx.hgodo.com/exgold/img/2c0cb467a29dbe77d4fe2ff5f790deaa_27301.jpg"
                        alt="기업체 기념품"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => window.open("http://www.mbro.co.kr/")}
                    >
                      <img
                        src="https://koreagoldx.hgodo.com/exgold/img/7bff2dabc78fda719a87808259fd7958_70240.jpg"
                        alt="엠브로"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open("http://www.gold24k.goldshell.co.kr/")
                      }
                    >
                      <img
                        src="https://koreagoldx.hgodo.com/exgold/img/a8716c202972729a66d0b2e2d93e8d87_97896.jpg"
                        alt="골드쉘 순금주얼리"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() => window.open("http://www.goldshell.co.kr/")}
                    >
                      <img
                        src="https://koreagoldx.hgodo.com/exgold/img/e1712d11581825b28d2dd713a3cb31c9_42585.jpg"
                        alt="골드쉘"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!--*배너 영역 시작7--> */}
          {/* <!--==========================================================*컨테이너 영역 마무리 --> */}
        </div>
      </div>
      {/* <!--**컨테이너 영역 끝--> */}
    </div>
  );
}
