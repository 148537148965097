import React, { useEffect, useState } from "react";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import {formatDate, numberWithCommas} from "../../lib/utils.js"
export default function ExchangeRateSection() {
  const [exchangeRate, setExchangeRate] = useState([]);
  const [exchageRateData, refetch] = useAsync(APIS.getExchangeRate, [], true);
  const { data } = exchageRateData;
  
  const compareFluctuationRate = (type, index) => {
    switch (type) {
      case "default":
        if (exchangeRate[index]?.fluctuationRate > 0) {
          return (
            <td style={{fontWeight: "bold", fontSize: "larger"}} className="ch_up">{exchangeRate[index]?.fluctuationRate}</td>
          );
        } else if (exchangeRate[index]?.fluctuationRate < 0) {
          return (
            <td style={{fontWeight: "bold", fontSize: "larger"}} className="ch_dw">{exchangeRate[index]?.fluctuationRate}</td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (exchangeRate[index]?.fluctuation > 0) {
          return (
            <dd>
              <img src={upArrow} alt="up" />
              <span style={{fontWeight: "bold", fontSize: "larger"}} className="up_red">{exchangeRate[index]?.fluctuation}</span>
            </dd>
          );
        } else if (exchangeRate[index]?.fluctuation < 0) {
          return (
            <dd>
              <img src={downArrow} alt="down" />
              <span style={{fontWeight: "bold", fontSize: "larger"}} className="dw_blue">
                {exchangeRate[index]?.fluctuation}
              </span>
            </dd>
          );
        } else {
          return (
            <dd>
              <img src={midd} alt="midd" />
            </dd>
          );
        }
        // no default
    }
  };

  useEffect(() => {
    setExchangeRate(data?.["exchangeRateDtoList"] || []);
  }, [data]);

  useEffect(()=> {
    const interval = setInterval(()=> {
      refetch(() => APIS.getExchangeRate(), "", false);
    }, 60000)
    return()=>clearInterval(interval)
    // eslint-disable-next-line
  },[])

  return (
    <div className="usd_area1" style={{fontWeight: "bold"}}>
      <h4 style={{fontWeight: "bold", fontSize: "larger"}}>환율(USD)</h4>
      <ul className="usd_box">
        {/* <li>
            <dl>
              <dt>최초고시</dt>
              <dd className="first">1114.87</dd>
              <dd>
                <img src={upArrow} alt="up" />
                <span className="up_red">1.10</span>
              </dd>
              <dd className="timeday">고시시간 : 2020-12-23 12:20:05</dd>
            </dl>
          </li> */}
        <li>
          <dl>
            <dt style={{fontSize: "larger", width: "120px"}}>기준원달러환율</dt>
            <dd className="first" style={{fontSize: "larger"}}>{numberWithCommas(exchangeRate[1]?.ask)}</dd>
            {compareFluctuationRate("arrow", 1)}
            <dd className="timeday" style={{fontSize: "larger"}}>고시시간 : {data ? formatDate(new Date(data?.regdate)): "-"}</dd>
          </dl>
        </li>
      </ul>
    </div>
  );
}
