import { useEffect, useReducer } from "react";
// import { useDispatch } from "react-redux";
// import { offLoad, onLoad } from "../../modules/reducers/loading";
// import { onOpen } from "../../modules/reducers/modal";
// import { onLogout } from "../../modules/reducers/user";

const reducer = (_, action) => {
  switch (action.type) {
    case "SUCCESS":
      return {
        data: action.data,
        error: null,
      };
    case "ERROR":
      return {
        data: null,
        error: null,
      };
    default:
      throw new Error(`잘못된 요청입니다.${action.type}`);
  }
};

/*
  callback : api 호출 메서드
  deps : useEffect를 위한 condition
  skip : 스킵여부 
  initAction : api호출 후 상태 초기화 관련 함수
*/
const useAsync = (
  callback,
  deps = [],
  skip = false,
  initAction = () => {},
) => {
  //위에 생성한 리듀서를 가져온다.
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    data: null,
    error: false,
    result: "",
  });

  // const commonDisPatch = useDispatch();

//   const fetchData = async (props, msg = '설정이 완료되었습니다.', view = false) => {
  const fetchData = async (props) => {
    // commonDisPatch(onLoad());
    try {
      const callbackAction = props ? props : callback;
      const response = await callbackAction();
      // const { success, message, data } = response.data;
      const { success, data } = response.data;

      if (success) {
        dispatch({ type: "SUCCESS", data: data });
      }
    } catch (e) {
      dispatch({ type: "ERROR", error: e });
      // const status = e?.response?.status;
    } finally {
      initAction();
    //   commonDisPatch(offLoad());
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, deps);

  return [state, fetchData];
};

export default useAsync;