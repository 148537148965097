import React, { useEffect, useState, useRef } from "react";
import AssetChart from "../../components/common/AssetChart";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { createQueryString, numberWithCommas } from "../../lib/utils";
import moment from "moment";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";

const DomesticAssetChart = React.memo((props) => {
  const {
    title = "",
    header = [],
    searchType = "international",
    name = "",
    id = "",
    routeName = "",
    color = "#008FFB",
    yaxisTitle = "",
  } = props;
  const today = new Date();
  const [type, setType] = useState("AU");
  const dataRef = useRef();
  const intervalRef = useRef();
  const typeRef = useRef();
  // const rangeRef = React.useRef();
  const queryString = createQueryString({
    type: type,
    from: `${moment(today).format("YYYY-MM-DDT00:00:00")}`,
    to: `${moment(today).format("YYYY-MM-DDTHH:mm:ss")}`,
  });

  const actionType =
    searchType === "international" && type !== "USD"
      ? () => APIS.getInternationalPrice(queryString)
      : type === "USD"
      ? () => APIS.getInternationalUsdPrice(queryString)
      : () => APIS.getDomesticPrice(queryString);
  const [state, refetch] = useAsync(actionType, [], false);

  const [chartData, setChartData] = useState([]);
  const { data } = state;

  useEffect(() => {
    const interval = setInterval(() => {
      newPrice();
    }, 60000);
    intervalRef.current = interval;
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dataRef.current = data || [];
    typeRef.current = type || "AU";
    setChartData(data || []);
    // eslint-disable-next-line
  }, [data]);

  const newPrice = () => {
    const today = new Date();
    const queryString = createQueryString({
      type: typeRef.current,
      from: `${moment(today).format("YYYY-MM-DDTHH:mm:00")}`,
    });
    const actionType =
      searchType === "international" && type !== "USD"
        ? () => APIS.getInternationalPrice(queryString)
        : type === "USD"
        ? () => APIS.getInternationalUsdPrice(queryString)
        : () => APIS.getDomesticPrice(queryString);
    actionType().then((res) => {
      const { success, data } = res.data;
      if (success) {
        if (data.length > 0) {
          let copyData = [];
          copyData.push(...data);
          copyData.push(...dataRef.current);
          dataRef.current = copyData;
          setChartData(copyData);
        }
      }
    });
  };


  useEffect(() => {
    const today = new Date();
    const queryString = createQueryString({
      type: type,
      from: `${moment(today).format("YYYY-MM-DDT00:00:00")}`,
      to: `${moment(today).format("YYYY-MM-DDTHH:mm:ss")}`,
    });
    const actionType =
      searchType === "international" && type !== "USD"
        ? () => APIS.getInternationalPrice(queryString)
        : type === "USD"
        ? () => APIS.getInternationalUsdPrice(queryString)
        : () => APIS.getDomesticPrice(queryString);
    refetch(actionType);
    // eslint-disable-next-line
  }, [type]);

  let arr = [];

  const headerType = {
    AU: {
      engName: "GOLD",
      korName: "금",
    },
    AG: {
      engName: "SILVER",
      korName: "은",
    },
    PT: {
      engName: "PLATINUM",
      korName: "백금",
    },
    PD: {
      engName: "PALLADIUM",
      korName: "팔라듐",
    },
    USD: {
      engName: "USD",
      korName: "USD",
    },
  };
  const seriesName =
    searchType === "international"
      ? headerType[type].engName
      : headerType[type].korName;

  if (chartData.length > 0) {
    const propertyType =
      searchType === "international" && type !== "USD"
        ? "internationalPrice"
        : type === "USD"
        ? "internationalRate"
        : "domesticPrice";
    chartData.forEach((item) => {
      arr.push([new Date(item["dateTime"]), item[propertyType]]);
    });
  }

  const setting = {
    options: {
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            const parseDate =
                 moment(new Date(val)).format("HH:mm")
            return parseDate;
          },
           datetimeUTC: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            var val = String(value).split(".");
            if (val.length <= 1) {
              //소수점이 없는 경우
              return numberWithCommas(value);
            } else {
              //소수점이 있는 경우
              return numberWithCommas(value.toFixed(2));
            }
          },
        },
        tickAmount: 4,
        title:{
          text: yaxisTitle
        }
      },
      tooltip: {
        x: {
          formatter: function (value) {
            return moment(value).format("YYYY-MM-DD HH:mm");
          },
        },
      },
      colors: [color]
    },
    series: [
      {
        name: seriesName,
        data: arr,
      },
    ],
    fill: {
      colors: ["#fff"],
    },
  };
  return (
    <li name={name} style={{ position: "relative" }}>
      <h4 className="htitl3">
        {title}
        {routeName && (
          <div className="more_btn">
            {routeName === "internationalPriceInquiry" ? (
              <Link to={`${routes.internationalPriceInquiry}`}>더보기</Link>
            ) : (
              <Link to={`${routes.domesticPriceInquiry}`}>더보기</Link>
            )}
          </div>
        )}
      </h4>
      <div className="prod_list1">
        {header.map((item) => {
          const parseItem = item.split(",");
          const key = parseItem[1];
          const value = parseItem[0];
          return (
            <p
              className={type === key ? "on" : ""}
              onClick={() => {
                if (type === key) {
                  return false;
                } else {
                  typeRef.current = key;
                  clearInterval(intervalRef.current);
                  const interval = setInterval(() => {
                    newPrice();
                  }, 60000);
                  intervalRef.current = interval;
                  setType(key);
                }
              }}
            >
              {value}
            </p>
          );
        })}
      </div>
      <div>{routeName === "internationalPriceInquiry" ? ( <div style={{marginLeft: "28px", position: "absolute", fontWeight: "bold", marginTop: "9px"}}>(USD/T.oz)</div>) : (<div style={{marginLeft: "31px", position: "absolute", fontWeight: "bold", marginTop: "9px"}}>(₩/g)</div>)}</div>
      <div id={id} style={{ minHeight: "265px" }}>
        <AssetChart
          style={{ padding: "15px" }}
          options={setting.options}
          series={setting.series}
          type="area"
          width="430"
        />
      </div>
      <div className="resize-triggers">
        <div className="expand-trigger">
          <div style={{ width: "501px", height: "358px" }}></div>
        </div>
        <div className="contract-trigger"></div>
      </div>
    </li>
  );
});

export default DomesticAssetChart;
