import React from "react";
// import "../../assets/css/aos.css";
import "../../assets/css/superfish.css";
import "../../assets/css/layout.css";
// import "../../assets/css/datepicker.css";
// import "../../assets/css/slick.css";
// import "../../assets/css/skeletabs.css";
// import "../../assets/css/service.css";
// import "../../assets/css/jquery-data_ui.css";
// import "../../assets/css/board.css";
// import "../../assets/css/member.css";
// import "../../assets/css/goods.css";
// import "../../assets/css/chosen.css";
// import "../../assets/css/www-player-webp.css";
import mainLogo from "../../assets/img/common/exgold_logo.png";
import { Link } from "react-router-dom";
import { routes } from "../../lib/routes";
export default function Header() {
  return (
    <div className="header">
      <div className="header_wrap">
        <h1 className="logo">
          <Link to="/">
            <img src={mainLogo} alt="로고" />
          </Link>
        </h1>
        <ul className="sf-menu" id="gmenu">
          <li>
            <Link to={routes.domesticPriceInquiry}>시세조회</Link>
            <ul>
              <li>
                <Link to={routes.domesticPriceInquiry}>국내시세</Link>
              </li>
              <li>
                <Link to={routes.internationalPriceInquiry}>국제시세</Link>
              </li>
              <li>
                <Link to={routes.londonFixInquiry}>런던픽스시세</Link>
              </li>
              <li>
                <Link to={routes.securitiesPriceInquiry}>유가증권</Link>
              </li>
              <li>
                <Link to={routes.diamondPriceInquiry}>다이아몬드 시세</Link>
              </li>
            </ul>
          </li>
          {/* <li style="display: none;">
            <a href="/product/productall.html">상품안내</a>
            <ul>
              <li>
                <a href="/product/productall.html" className="menu">
                  원자재
                </a>
              </li>
              <li>
                <a href="/product/securitiesgd.html" className="menu">
                  유가증권
                </a>
              </li>
            </ul>
          </li>
          <li style="display: none;">
            <a href="/souvenir/inquiry.html">기념품제작</a>
            <ul>
              <li>
                <a href="/souvenir/inquiry.html">제작문의 조회</a>
              </li>
              <li>
                <a href="/souvenir/inquiryRegister.html">제작문의 등록</a>
              </li>
            </ul>
          </li>
          <li style="display: none;">
            <a href="/newscenter/newsNoticeList.html">뉴스센터</a>
          </li>
          <li style="display: none;">
            <a href="/notice/announcementList.html">공지사항</a>
          </li> */}
          <li>
            <Link to={routes.companyIntroduction}>회사소개</Link>
            <ul>
              <li>
                <Link to={routes.companyIntroduction}>회사소개</Link>
              </li>
              <li>
                <Link to={routes.companyGreeting}>CEO인사말</Link>
              </li>
              <li>
                <Link to={routes.companyHistory}>회사연혁</Link>
              </li>
              <li>
                <Link to={routes.companyBusinessIntroduction}>사업소개</Link>
              </li>
              <li>
                <Link to={routes.companyBrandIntroduction}>브랜드소개</Link>
              </li>
              <li>
                <Link to={routes.companyDirections}>오시는 길</Link>
              </li>
              {/* <li>
                <Link to={routes.companyFranchiseInvitation}>가맹점 모집</Link>
              </li> */}
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
