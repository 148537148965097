import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import banner from "../../assets/img/mbanner1.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";
export default function StockPrice() {
  const [stockPrice, setStockPrice] = useState([]);
  const [stockPriceData, refetch] = useAsync(APIS.getStockPrice, [], true);
  const { data } = stockPriceData;

  const compareFluctuationRate = (type, index) => {
    switch (type) {
      case "default":
        if (stockPrice[index]?.fluctuationRate > 0) {
          return (
            <td className="ch_up">{stockPrice[index]?.fluctuationRate}%</td>
          );
        } else if (stockPrice[index]?.fluctuationRate < 0) {
          return (
            <td className="ch_dw">{stockPrice[index]?.fluctuationRate}%</td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (stockPrice[index]?.fluctuation > 0) {
          return (
            <td>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(stockPrice[index]?.fluctuation)}
              </span>
            </td>
          );
        } else if (stockPrice[index]?.fluctuation < 0) {
          return (
            <td>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(stockPrice[index]?.fluctuation)}
              </span>
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      // no default
    }
  };
  useEffect(() => {
    setStockPrice(data?.["securityMainDtoList"] || []);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getStockPrice(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <li style={{ width: "500px", height: "467px", float: "left" }}>
      <h4 className="mdtitl1">유가증권 시세</h4>
      <table className="tit_tabl3">
        <thead>
          <tr>
            <td>권종</td>
            <td>판매가격</td>
            <td>변동</td>
            <td>등락률</td>
          </tr>
        </thead>
        <tbody style={{ background: "#fff" }}>
          {/* {stockPrice?.map((item) => {
            return(

            )
          })} */}
          <tr>
            <td>골드바 10g</td>
            <td>{`${numberWithCommas(stockPrice[0]?.price)}`}원</td>
            {compareFluctuationRate("arrow", 0)}
            {compareFluctuationRate("default", 0)}
          </tr>
          <tr>
            <td>실버바 1kg</td>
            <td>{`${numberWithCommas(stockPrice[1]?.price)}`}원</td>
            {compareFluctuationRate("arrow", 1)}
            {compareFluctuationRate("default", 1)}
          </tr>
        </tbody>
      </table>
      <div className="banner_img1">
        <Link to={routes.securitiesPriceInquiry}>
          <img
            style={{ width: "500px" }}
            src={banner}
            alt="한국금거래소 유가증권 이것을 알아야 성공한다."
          />
        </Link>
      </div>
      <p className="gtime">
        고시시간 :{data ? formatDate(new Date(data?.regdate)) : "-"}
      </p>
      <div className="more_btn1">
        <Link to={routes.securitiesPriceInquiry}>더보기</Link>
      </div>
    </li>
  );
}
