export const routes = {
    main: "/",

    //이용약관, 개인정보 처리방침
    terms: "/terms",
    termService: "/terms/termService",
    privacyPolicy: "/terms/privacypolicy",

    //시세조회
    price: "/price",
    priceInquiry: "/price/inquiry",
    domesticPriceInquiry: "/price/inquiry/domestic",
    internationalPriceInquiry: "/price/inquiry/international",
    diamondPriceInquiry: "/price/inquiry/diamond",
    securitiesPriceInquiry: "/price/inquiry/security",
    londonFixInquiry: "/price/inquiry/londonfix",
    premiumPrice: "/premiumPrice",

    //회사소개
    company: "/company",
    companyIntroduction: "/company/introduction",
    companyGreeting: "/company/greeting",
    companyHistory: "/company/history",
    companyBusinessIntroduction: "/company/introduction/business",
    companyBrandIntroduction: "/company/introduction/brand",
    companyDirections: "/company/directions",
    companyFranchiseInvitation: "/company/franchise/invitation",
}