import React from "react";
import { routes } from "../../lib/routes";
import { Link } from "react-router-dom";

const MobileSideMenu = (props) => {
  const { setExpanded, check } = props;
  return (
    <div
      className="m_menu_wrap"
      style={{
        width: "100vw",
        height: "100vh",
        right: 100,
        zIndex: 100,
        transition: "0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
        marginTop: check ? "25px" : "0px",
      }}
    >
      <div
        className="m_lf_menu"
        style={{ transition: "0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91)" }}
      >
        <ul style={{ width: "90px" }}>
          <li>시세조회</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul style={{ width: "90px" }}>
          <li>매매 프리미엄</li>
          <li></li>
        </ul>
        <ul style={{ width: "90px" }}>
          <li>회사소개</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div
        className="m_rt_menu"
        style={{ transition: "0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91)" }}
      >
        <ul>
          <li className="m_menu_tit">시세조회</li>
          <li>
            <Link
              to={routes.domesticPriceInquiry}
              onClick={() => setExpanded(false)}
            >
              국내시세
            </Link>
          </li>
          <li>
            <Link
              to={routes.internationalPriceInquiry}
              onClick={() => setExpanded(false)}
            >
              국제시세
            </Link>
          </li>
          <li>
            <Link
              to={routes.londonFixInquiry}
              onClick={() => setExpanded(false)}
            >
              런던픽스 시세
            </Link>
          </li>
          <li>
            <Link
              to={routes.securitiesPriceInquiry}
              onClick={() => setExpanded(false)}
            >
              유가증권 시세
            </Link>
          </li>
          <li>
            <Link
              to={routes.diamondPriceInquiry}
              onClick={() => setExpanded(false)}
            >
              다이아몬드 시세
            </Link>
          </li>
        </ul>
        <ul>
          <li className="m_menu_tit">매매 프리미엄</li>
          <li>
            <Link to={routes.premiumPrice} onClick={() => setExpanded(false)}>
              매매 프리미엄
            </Link>
          </li>
        </ul>
        <ul style={{ paddingBottom: "60px" }}>
          <li className="m_menu_tit">회사소개</li>
          <li>
            <Link
              to={routes.companyIntroduction}
              onClick={() => setExpanded(false)}
            >
              회사소개
            </Link>
          </li>
          <li>
            <Link
              to={routes.companyGreeting}
              onClick={() => setExpanded(false)}
            >
              CEO인사말
            </Link>
          </li>
          <li>
            <Link to={routes.companyHistory} onClick={() => setExpanded(false)}>
              회사연혁
            </Link>
          </li>
          <li>
            <Link
              to={routes.companyBusinessIntroduction}
              onClick={() => setExpanded(false)}
            >
              사업소개
            </Link>
          </li>
          <li>
            <Link
              to={routes.companyBrandIntroduction}
              onClick={() => setExpanded(false)}
            >
              브랜드소개
            </Link>
          </li>
          <li>
            <Link
              to={routes.companyDirections}
              onClick={() => setExpanded(false)}
            >
              오시는 길
            </Link>
          </li>
          {/* <li>
            <Link
              to={routes.companyFranchiseInvitation}
              onClick={() => setExpanded(false)}
            >
              가맹점 모집
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default MobileSideMenu;
