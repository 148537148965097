import React from "react";
import MobileBottomSlider from "./MobileBottomSilder";
import MobileDosmeticPrice from "./MobileDosmeticPrice";
import MobileEtc from "./MobileEtc";
import MobileLivePrice from "./MobileLivePrice";
import MobileMajorPrice from "./MobileMajorPrice";
import MobileSecurity from "./MobileSecurity";
import MobileSpotPrice from "./MobileSpotPrice";
import MobileTopSlider from "./MobileTopSlider";
import MobileUsd from "./MobileUsd";

const MobileMain = () => {
  return (
    <>
      <div className="m_visual_box mt50">
        <MobileTopSlider />
        <MobileSpotPrice />
      </div>
      <div className="m_container_wrap">
        <MobileUsd />
      </div>
      <div className="m_container_wrap" style={{ backgroundColor: "#F9F9F4" }}>
        <MobileLivePrice />
        <MobileDosmeticPrice
          title="국제 실시간 상품차트"
          header={[
            "GOLD,AU",
            "SILVER,AG",
            "PLATINUM,PT",
            "PALLADIUM,PD",
            "환율 USD,USD",
          ]}
          color="#f89b00"
          searchType="international"
          name="chart1"
          id="domesticChart1"
          routeName="internationalPriceInquiry"
          // yaxisTitle="(USD/T.oz)"
        />
        <MobileDosmeticPrice
          title="국내 실시간 상품차트"
          header={["금,AU,금", "은,AG,은", "백금,PT,백금", "팔라듐,PD,팔라듐"]}
          searchType="domestic"
          name="chart2"
          id="domesticChart2"
          routeName="routes.domesticPriceInquiry"
          // yaxisTitle="(₩/g)"
        />
        <MobileEtc />
        <MobileSecurity />
        <MobileMajorPrice />
        <MobileBottomSlider />
      </div>
    </>
  );
};

export default MobileMain;
