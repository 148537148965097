import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAsync from "../../lib/hooks/useAsync";
import moment from "moment";
import AssetChart from "../../components/common/AssetChart";
import ReactPaginate from "react-paginate";
import {
  numberWithCommas,
  createQueryString,
  formatTime,
} from "../../lib/utils";
import * as APIS from "../../lib/api/index";

const MobileInternationalPrice = () => {
  const [type, setType] = useState("AU");
  const [range, setRange] = useState("month");
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [chartList, setChartList] = useState([]);
  const [fromDate, setFromDate] = useState(
    formatTime(
      new Date(new Date().setMonth(new Date().getMonth() - 1)),
      "YYYY-MM-DD"
    )
  );
  const rangeRef = React.useRef();
  const [toDate, setToDate] = useState(formatTime(new Date(), "YYYY-MM-DD"));
  const [internationalAsset, setInternationalAsset] = useState([]);

  const queryStringAsset = createQueryString({
    type: type,
  });
  const [internationalData, refetch] = useAsync(
    () => APIS.getInternational(queryStringAsset),
    [],
    false
  );
  const [internationalRateData] = useAsync(
    APIS.getInternationalRate,
    [],
    false
  );

  useEffect(() => {
    setInternationalAsset(internationalData.data);
  }, [internationalData.data]);

  useEffect(() => {
    if (type !== "USD") {
      refetch(() => APIS.getInternational(queryStringAsset));
    }
    // eslint-disable-next-line
  }, [type]);

  const actionType =
    type !== "USD"
      ? () => APIS.getInternationalProductChart(rangeQueryString)
      : () => APIS.getInternationalRateChart(rangeQueryString);

  const [state, refetchChart] = useAsync(actionType, [], false);
  const [chartData, setChartData] = useState([]);

  const searchChartDate = () => {
    setRange("");
    const queryString = createQueryString({
      type: type,
      from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
      to: formatTime(new Date(toDate), "YYYY-MM-DD"),
    });

    const actionType =
      range === "live" && type !== "USD"
        ? () => APIS.getInternationalPrice(queryString)
        : range === "live" && type === "USD"
        ? () => APIS.getInternationalUsdPrice(queryString)
        : range !== "live" && type !== "USD"
        ? () => APIS.getInternationalProductChart(queryString)
        : () => APIS.getInternationalRateChart(queryString);

    refetchChart(actionType);
  };

  useEffect(() => {
    setChartData(state.data);
  }, [state]);

  const headerType = {
    AU: {
      engName: "GOLD",
      korName: "금",
    },
    AG: {
      engName: "SILVER",
      korName: "은",
    },
    PT: {
      engName: "PLATINUM",
      korName: "백금",
    },
    PD: {
      engName: "PALLADIUM",
      korName: "팔라듐",
    },
    USD: {
      engName: "USD",
      korName: "USD",
    },
  };

  const seriesName = headerType[type].korName;
  let arr = [];
  if (chartData?.length > 0) {
    chartData?.forEach((item) => {
      range === "live" && type !== "USD"
        ? arr.push([new Date(item["dateTime"]), item["internationalPrice"]])
        : range === "live" && type === "USD"
        ? arr.push([new Date(item["dateTime"]), item["internationalRate"]])
        : range !== "live" && type !== "USD"
        ? arr.push([new Date(item["date"]), item["internationalPrice"]])
        : arr.push([new Date(item["date"]), item["internationalRate"]]);
    });
  }

  const setting = {
    options: {
      stroke: {
        curve: "smooth",
        width: 1,
      },
      dataLabels: {
        enabled: false,
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (val) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(new Date(val)).format("HH:mm")
                : moment(new Date(val)).format("yyyy.MM.DD");
            return parseDate;
          },
        },
        tickAmount: 3,
      },
      yaxis: {
        labels: {
          formatter: function (value, index) {
            var val = String(value).split(".");
            if (val.length <= 1) {
              //소수점이 없는 경우
              return numberWithCommas(value);
            } else {
              //소수점이 있는 경우
              return numberWithCommas(value.toFixed(2));
            }
          },
        },
        tickAmount: 4,
      },
      tooltip: {
        x: {
          formatter: function (value) {
            const parseDate =
              rangeRef.current === "live"
                ? moment(new Date(value)).format("YYYY-MM-DD HH:mm")
                : moment(new Date(value)).format("yyyy년 MM월 DD일");
            return parseDate;
          },
        },
      },
    },
    series: [
      {
        name: seriesName,
        data: arr,
      },
    ],
    fill: {
      colors: ["#fff"],
    },
  };

  const rangeQueryString = createQueryString(
    range === "month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3month"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : range === "3year"
      ? {
          type: type,
          from: formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          ),
          to: formatTime(new Date(), "YYYY-MM-DD"),
        }
      : {
          type: type,
          from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
          to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
        }
  );

  useEffect(() => {
    const queryString = createQueryString(
      range !== "live"
        ? {
            type: type,
            page: pageNumber,
            size: 15,
            from: fromDate,
            to: toDate,
          }
        : {
            type: type,
            page: pageNumber,
            size: 15,
            from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
            to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
          }
    );
    if (type !== "USD" && range !== "live") {
      APIS.getInternationalPage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              return { ...item, dateTime: item.date || item.dateTime };
            });
            setChartList(parseData || []);
          }
        }
      });
    } else if (type !== "USD" && range === "live") {
      APIS.getInternationalLivePage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              const date = item?.date || item.dateTime;
              const parseDate = moment(new Date(date.replace("T", " "))).format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              return { ...item, dateTime: parseDate };
            });
            setChartList(parseData || []);
          }
        }
      });
    } else if (type === "USD" && range !== "live") {
      APIS.getInternationalRatePage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              return { ...item, dateTime: item.date || item.dateTime };
            });
            setChartList(parseData || []);
          }
        }
      });
    } else if (type === "USD" && range === "live") {
      APIS.getInternationalRateLivePage(queryString).then((res) => {
        const { data, success } = res.data;
        if (success) {
          if (data.content.length > 0) {
            setPageCount(data?.["totalElements"] || 0);
            const parseData = data["content"].map((item) => {
              const date = item?.date || item.dateTime;
              const parseDate = moment(new Date(date.replace("T", " "))).format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              return { ...item, dateTime: parseDate };
            });
            setChartList(parseData || []);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [type, range, pageNumber]);

  useEffect(() => {
    const queryString = createQueryString(
      range === "month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3month"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setMonth(new Date().getMonth() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 1)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "3year"
        ? {
            type: type,
            from: formatTime(
              new Date(new Date().setYear(new Date().getFullYear() - 3)),
              "YYYY-MM-DD"
            ),
            to: formatTime(new Date(), "YYYY-MM-DD"),
          }
        : range === "live"
        ? {
            type: type,
            from: `${moment(new Date()).format("YYYY-MM-DDT00:00:00")}`,
            to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
          }
        : {
            type: type,
            from: formatTime(new Date(fromDate), "YYYY-MM-DD"),
            to: formatTime(new Date(toDate), "YYYY-MM-DD"),
          }
    );
    const actionType =
      range === "live" && type !== "USD"
        ? () => APIS.getInternationalPrice(queryString)
        : range === "live" && type === "USD"
        ? () => APIS.getInternationalUsdPrice(queryString)
        : type !== "USD"
        ? () => APIS.getInternationalProductChart(queryString)
        : () => APIS.getInternationalRateChart(queryString);

    refetchChart(actionType);
    // eslint-disable-next-line
  }, [type, range]);

  const onPageChange = (props) => {
    const { selected } = props;
    setPageNumber(selected);
  };

  const onChangeDate = (type) => {
    switch (type) {
      case "live":
        setFromDate(formatTime(new Date(), "YYYY-MM-DD"));
        break;
      case "month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3month":
        setFromDate(
          formatTime(
            new Date(new Date().setMonth(new Date().getMonth() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 1)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "3year":
        setFromDate(
          formatTime(
            new Date(new Date().setYear(new Date().getFullYear() - 3)),
            "YYYY-MM-DD"
          )
        );
        break;
      case "total":
        setFromDate("2016-01-01");
        break;

      default:
    }
    setPageNumber(0);
    setPageCount(0);
    rangeRef.current = type;
    setRange(type);
  };
  return (
    <>
      <div className="m_price_wrap">
        <div className="price_tit">
          <h3>국제시세</h3>
          <p>International Price</p>
        </div>
        <div className="price_cot">
          <div className="outer">
            <div
              className="m_tab_buttons internalprice"
              style={{ width: "100%", display: "block", overflow: "hidden" }}
            >
              <button
                className={`dptab ${type === "AU" ? "active" : ""}`}
                onClick={() => setType("AU")}
              >
                골드
              </button>
              <button
                className={`dptab ${type === "AG" ? "active" : ""}`}
                onClick={() => setType("AG")}
              >
                실버
              </button>
              <button
                className={`dptab  ${type === "PT" ? "active" : ""}`}
                onClick={() => setType("PT")}
              >
                플래티넘
              </button>
              <button
                className={`dptab ${type === "PD" ? "active" : ""}`}
                onClick={() => setType("PD")}
              >
                팔라듐
              </button>
              <button
                className={`dptab ${type === "USD" ? "active" : ""}`}
                onClick={() => setType("USD")}
              >
                환율
              </button>
            </div>
            <div className="m_tabs price">
              <div className="m_tab active">
                <ul className="m_price_list">
                  <li style={{ boxSizing: "border-box" }}>
                    <dl className="gold">
                      {type !== "USD" ? (
                        <dt>
                          국제
                          {internationalAsset?.type
                            ?.replace("Au", "금")
                            ?.replace("Ag", "은")
                            ?.replace("Pt", "플래티넘")
                            ?.replace("Pd", "팔라듐")}
                          기준가
                        </dt>
                      ) : (
                        <dt>국제 환율 기준가</dt>
                      )}
                      <dd>
                        <span>Bid</span>
                        <span>
                          {type !== "USD"
                            ? numberWithCommas(internationalAsset?.bid)
                            : numberWithCommas(internationalRateData?.data.bid)}
                        </span>
                      </dd>
                      <dd>
                        <span>Ask</span>
                        <span>
                          {type !== "USD"
                            ? numberWithCommas(internationalAsset?.ask)
                            : numberWithCommas(internationalRateData?.data.ask)}
                        </span>
                      </dd>
                    </dl>
                  </li>
                  <li style={{ boxSizing: "border-box" }}>
                    <dl className="gold">
                      <dt>
                        국내
                        {internationalAsset?.type
                          ?.replace("Au", "금")
                          ?.replace("Ag", "은")
                          ?.replace("Pt", "플래티넘")
                          ?.replace("Pd", "팔라듐")}
                        기준가
                      </dt>
                      <dd>
                        <span>₩/g</span>
                        <span>
                          {numberWithCommas(internationalAsset?.domesticPrice)}
                        </span>
                      </dd>
                      <dd>
                        <span>₩/3.75g</span>
                        <span>
                          {numberWithCommas(
                            internationalAsset?.domesticPriceDon
                          )}
                        </span>
                      </dd>
                    </dl>
                  </li>
                  <li style={{ boxSizing: "border-box" }}>
                    <dl className="gold">
                      <dd>
                        <span>
                          기준국제시세
                          <br />
                          (USD/T.oz)
                        </span>
                        <span>
                          {type !== "USD"
                            ? numberWithCommas(
                                internationalAsset?.internationalStandardPrice
                              )
                            : numberWithCommas(
                                internationalRateData?.data
                                  .internationalStandardPrice
                              )}
                        </span>
                      </dd>
                    </dl>
                  </li>
                  <li style={{ boxSizing: "border-box" }}>
                    <dl className="gold">
                      <dd>
                        <span>
                          기준원달러환율
                          <br />
                          (USD/₩)
                        </span>
                        <span>
                          {type !== "USD"
                            ? numberWithCommas(internationalAsset?.exchangeRate)
                            : numberWithCommas(
                                internationalRateData?.data.exchangeRate
                              )}
                        </span>
                      </dd>
                    </dl>
                  </li>
                </ul>
                <div className="m_tab_top">
                  <ul className="m_term">
                    <li>
                      <Link
                        to="#"
                        className={range === "live" ? "on" : ""}
                        onClick={() => onChangeDate("live")}
                      >
                        실시간
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "month" ? "on" : ""}
                        onClick={() => onChangeDate("month")}
                      >
                        1개월
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "3month" ? "on" : ""}
                        onClick={() => onChangeDate("3month")}
                      >
                        3개월
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "year" ? "on" : ""}
                        onClick={() => onChangeDate("year")}
                      >
                        1년
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className={range === "3year" ? "on" : ""}
                        onClick={() => onChangeDate("3year")}
                      >
                        3년
                      </Link>
                    </li>
                  </ul>
                  <ul class="donYN">
                    <li class="first">
                      <Link to="#" className="on">
                        달러
                      </Link>
                    </li>
                  </ul>
                </div>
                <ul>
                  <li className="m_last">
                    <div className="m_data_day">
                      <input
                        className="datepicker1 hasDatepicker"
                        type="date"
                        id="dp1636523011796"
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                        value={fromDate}
                        disabled={range === "live" ? true : false}
                      />
                    </div>
                    <div className="m_data_day">
                      <input
                        className="datepicker2 hasDatepicker"
                        type="date"
                        id="dp1636523011801"
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                        value={toDate}
                        disabled={range === "live" ? true : false}
                      />
                    </div>
                    <button
                      onClick={searchChartDate}
                      className="search_btn1"
                      disabled={range === "live" ? true : false}
                    >
                      조회
                    </button>
                  </li>
                </ul>

                <div>
                  <AssetChart
                    style={{ marginTop: "15px" }}
                    options={setting.options}
                    series={setting.series}
                    type="area"
                    width="100%"
                    height="300px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m_tl_line" style={{ marginBottom: "40px" }}>
        <div
          className="m_gold_table1"
          style={{ border: "none", overflowX: "scroll", width: "100%" }}
        >
          <table style={{ width: "1000px" }}>
            <thead>
              {type !== "USD" && range !== "live" && (
                <tr>
                  <th>날짜</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>국제가(USD/T.oz)</th>
                  <th>환율(₩/USD)</th>
                  <th>국내기준가(₩/g)</th>
                  <th>국내기준가(₩/3.75g)</th>
                </tr>
              )}

              {type !== "USD" && range === "live" && (
                <tr>
                  <th>날짜</th>
                  <th>시간</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>국제가(USD/T.oz)</th>
                  <th>환율(₩/USD)</th>
                  <th>국내기준가(₩/g)</th>
                  <th>국내기준가(₩/3.75g)</th>
                </tr>
              )}

              {type === "USD" && range !== "live" && (
                <tr>
                  <th>날짜</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>환율(₩/USD)</th>
                </tr>
              )}

              {type === "USD" && range === "live" && (
                <tr>
                  <th>날짜</th>
                  <th>시간</th>
                  <th>Bid</th>
                  <th>Ask</th>
                  <th>환율(₩/USD)</th>
                </tr>
              )}
            </thead>
            <tbody>
              {chartList.map((item, index) => {
                if (type !== "USD" && range !== "live") {
                  return (
                    <tr key={index}>
                      <td>{item?.dateTime || "-"}</td>
                      <td>{numberWithCommas(item?.bid) || "-"}</td>
                      <td>{numberWithCommas(item?.ask) || "-"}</td>
                      <td>
                        {numberWithCommas(item?.internationalPrice) || "-"}
                      </td>
                      <td>{numberWithCommas(item?.exchangeRate) || "-"}</td>
                      <td>{numberWithCommas(item?.domesticPrice) || "-"}</td>
                      <td>{numberWithCommas(item?.domesticPriceDon) || "-"}</td>
                    </tr>
                  );
                } else if (type !== "USD" && range === "live") {
                  return (
                    <tr key={index}>
                      <td>{formatTime(item?.dateTime, "YYYY-MM-DD") || "-"}</td>
                      <td>{formatTime(item?.dateTime, "HH:mm:ss") || "-"}</td>
                      <td>{numberWithCommas(item?.bid) || "-"}</td>
                      <td>{numberWithCommas(item?.ask) || "-"}</td>
                      <td>
                        {numberWithCommas(item?.internationalPrice) || "-"}
                      </td>
                      <td>{numberWithCommas(item?.exchangeRate) || "-"}</td>
                      <td>{numberWithCommas(item?.domesticPrice) || "-"}</td>
                      <td>{numberWithCommas(item?.domesticPriceDon) || "-"}</td>
                    </tr>
                  );
                } else if (type === "USD" && range !== "live") {
                  return (
                    <tr key={index}>
                      <td>{item?.date || "-"}</td>
                      <td>{numberWithCommas(item?.bid) || "-"}</td>
                      <td>{numberWithCommas(item?.ask) || "-"}</td>
                      <td>
                        {numberWithCommas(item?.internationalRate) || "-"}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index}>
                      <td>{formatTime(item?.dateTime, "YYYY-MM-DD") || "-"}</td>
                      <td>{formatTime(item?.dateTime, "HH:mm:ss") || "-"}</td>
                      <td>{numberWithCommas(item?.bid) || "-"}</td>
                      <td>{numberWithCommas(item?.ask) || "-"}</td>
                      <td>
                        {numberWithCommas(item?.internationalRate) || "-"}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          {/* <div style={{ fontWeight: "bold" }}>※ 17:00시 기준</div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className={"pagination next"}
            style={{
              border: "1px solid lightgray",
              borderRadius: "10%",
              height: "30px",
              lineHeight: "25px",
              color: "#337ab7",
            }}
            onClick={() => {
              if (pageNumber === 0) {
                return false;
              }
              setPageNumber(0);
            }}
          >
            {"<<"}
          </button>
          &nbsp;
          <ReactPaginate
            className={"pagination"}
            pageCount={Math.ceil(pageCount / 15)}
            forcePage={pageNumber}
            pageRangeDisplayed={5}
            itemsCountPerPage={5}
            marginPagesDisplayed={0}
            previousLabel={"<"}
            nextLabel={">"}
            activeClassName={"tabulator-page active"}
            onPageChange={onPageChange}
          />
          &nbsp;
          <button
            className={"pagination previou"}
            style={{
              border: "1px solid lightgray",
              borderRadius: "10%",
              height: "30px",
              lineHeight: "25px",
              color: "#337ab7",
            }}
            onClick={() => {
              if (pageNumber === Math.ceil(pageCount / 15) - 1) {
                return false;
              } else {
                setPageNumber(Math.ceil(pageCount / 15) - 1);
              }
            }}
          >
            {">>"}
          </button>
        </div>
        <div className="m_clear"></div>
      </div>
    </>
  );
};

export default MobileInternationalPrice;
