import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import upArrow from "../../assets/img/up.png";
import midd from "../../assets/img/midd.png";
import downArrow from "../../assets/img/down.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";
export default function MetalPrices() {
  const [metalPrice, setMetalPrice] = useState([]);
  const [metalPriceData] = useAsync(APIS.getMetalPrices, [], true);
  const { data } = metalPriceData;

  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "Cu") {
        arrayData[0] = item;
      }
      if (item.type === "Rh") {
        arrayData[1] = item;
      }
      if (item.type === "In") {
        arrayData[2] = item;
      }
      if (item.type === "Ir") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };

  const compareFluctuationRate = (type, index) => {
    switch (type) {
      case "arrow":
        if (metalPrice[index]?.sign === "+") {
          return (
            <>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">{metalPrice[index]?.fluctuation}</span>
            </>
          );
        } else if (metalPrice[index]?.sign === "-") {
          if (metalPrice[index]?.fluctuation === 0) {
            return (
              // <td>
              <img src={midd} alt="midd" />
              // </td>
            );
          }
          return (
            <>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {`${metalPrice[index]?.fluctuation}`}
              </span>
            </>
          );
        }
      // no default
    }
  };

  useEffect(() => {
    setMetalPrice(reformData(data?.["preciousMetalsDtoList"]) || []);
  }, [data]);
  return (
    <li
      style={{
        width: "500px",
        height: "467px",
        float: "right",
        marginTop: "15px",
      }}
    >
      <h4 className="mdtitl1">기타 금속 시세</h4>
      <table>
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "25%" }} />
          <col style={{ width: "30%" }} />
        </colgroup>
        <thead>
          <tr>
            <td>종류</td>
            <td>시세</td>
            <td>변동</td>
            <td>날짜</td>
          </tr>
        </thead>
        <tbody style={{ background: "#fff" }}>
          <tr>
            <td>구리(USD/LBS)</td>
            <td>{numberWithCommas(metalPrice[0]?.price)}</td>
            <td>{compareFluctuationRate("arrow", 0)}</td>
            <td>
              {metalPrice[3]
                ? formatDate(new Date(metalPrice[0]?.regdate))
                : "-"}
            </td>
          </tr>
          <tr>
            <td>로듐(USD/OZ)</td>
            <td>{numberWithCommas(metalPrice[1]?.price)}</td>
            <td>{compareFluctuationRate("arrow", 1)}</td>
            <td>
              {metalPrice[0]
                ? formatDate(new Date(metalPrice[1]?.regdate))
                : "-"}
            </td>
          </tr>
          <tr>
            <td>인듐(USD/KG)</td>
            <td>{numberWithCommas(metalPrice[2]?.price)}</td>
            <td>{compareFluctuationRate("arrow", 2)}</td>
            <td>
              {metalPrice[2]
                ? formatDate(new Date(metalPrice[2]?.regdate))
                : "-"}
            </td>
          </tr>
          <tr>
            <td>이리듐(USD/OZ)</td>
            <td>{numberWithCommas(metalPrice[3]?.price)}</td>
            <td>{compareFluctuationRate("arrow", 3)}</td>
            <td>
              {metalPrice[1]
                ? formatDate(new Date(metalPrice[3]?.regdate))
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
      <p className="gtime" style={{ marginTop: "10px" }}>
        고시시간 : {data ? formatDate(new Date(data?.regdate)) : "-"}
      </p>
    </li>
  );
}
