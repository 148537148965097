import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";

export default function BasePriceWholesalePrice() {
  const [standardPrice, setStandardPrice] = useState([]);
  const [wholesalePrice, setWholesalePrice] = useState([]);
  const [wholesaleData, refetch] = useAsync(APIS.getWholesale, [], true);
  const [totalData, setTotalData] = useState([]);
  const { data } = wholesaleData;
  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "AU") {
        arrayData[0] = item;
      }
      if (item.type === "AG") {
        arrayData[1] = item;
      }
      if (item.type === "PT") {
        arrayData[2] = item;
      }
      if (item.type === "PD") {
        arrayData[3] = item;
      }
    });
    return arrayData;
  };
  const compareFluctuationRate = (type, fluctuation, indexState) => {
    switch (type) {
      case "arrow":
        if (fluctuation > 0) {
          return (
            <span className="rddown" style={{ marginLeft: "0px" }}>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span>{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else if (fluctuation < 0) {
          return (
            <span className="rddown">
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span>{numberWithCommas(fluctuation)}</span>
            </span>
          );
        } else {
          return (
            <span className="rddown">
              <span>
                <img src={midd} alt="midd" />
              </span>
            </span>
          );
        }
      // no default
    }
  };

  useEffect(() => {
    const standard = reformData(data?.["standardPriceMainDtoList"]) || [];
    const wholesale = reformData(data?.["wholesalePriceMainDtoList"]) || [];
    let parseArr = [];
    wholesale.forEach((item, index) => {
      parseArr[index] = {
        ...parseArr[index],
        priceDonWholesale: item.priceDon,
        priceGramWholesale: item.priceGram,
        fluctuation: item.fluctuation,
      };
    });
    standard.forEach((item, index) => {
      parseArr[index] = {
        ...parseArr[index],
        description:
          item.description === "금"
            ? item.description.replace("금", "Au(금)")
            : item.description === "백금"
            ? item.description.replace("백금", "Pt(백금)")
            : item.description === "은"
            ? item.description.replace("은", "Ag(은)")
            : item.description === "팔라듐"
            ? item.description.replace("팔라듐", "Pd(팔라듐)")
            : "",
        type: item.type,
        priceDonStandard: item.priceDon,
        priceGramStandard: item.priceGram,
      };
    });
    setTotalData(parseArr);
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getWholesale(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <div id="top_price" className="test-aos" style={{ height: "750px" }}>
      <h4 className="mdtitl1">실시간 시세</h4>
      <div className="tbl_table9 rnow">
        <table className="rnow_top">
          <thead>
            <tr>
              <td rowSpan="2">구분</td>
              <td colSpan="2">국제 기준시세</td>
              <td colSpan="3">국내 도매시세</td>
            </tr>
            <tr>
              <td>(₩/g)</td>
              <td>(₩/3.75g)</td>
              <td>(₩/g)</td>
              <td>(₩/3.75g)</td>
              <td>
                변동<span>(₩/3.75g)</span>
              </td>
            </tr>
          </thead>
        </table>
        <table>
          <tbody>
            {totalData?.map((item, index) => {
              return index === 0 || index === 2 ? (
                <tr
                  key={index}
                  className="odd_tr"
                  style={{ borderTop: "1px solid #DDDDDD" }}
                >
                  <td className="td1">{item.description}</td>
                  <td className="td2">
                    {numberWithCommas(item.priceGramStandard)}
                  </td>
                  <td className="td2 lst">
                    {numberWithCommas(item.priceDonStandard)}
                  </td>
                  <td className="td3">
                    {numberWithCommas(item.priceGramWholesale)}
                  </td>
                  <td className="td3">
                    {numberWithCommas(item.priceDonWholesale)}
                  </td>
                  <td className="td3">
                    {compareFluctuationRate("arrow", item.fluctuation)}
                  </td>
                </tr>
              ) : (
                <tr key={index} className="even_tr">
                  <td className="td1">{item.description}</td>
                  <td className="td2">
                    {numberWithCommas(item.priceGramStandard)}
                  </td>
                  <td className="td2 lst">
                    {numberWithCommas(item.priceDonStandard)}
                  </td>
                  <td className="td3">
                    {numberWithCommas(item.priceGramWholesale)}
                  </td>
                  <td className="td3">
                    {numberWithCommas(item.priceDonWholesale)}
                  </td>
                  <td className="td3">
                    {compareFluctuationRate("arrow", item.fluctuation)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <p className="gtime" style={{ marginTop: 20 }}>
        고시시간 : {data ? formatDate(new Date(data?.regdate)) : "-"}
      </p>
    </div>
  );
}

/* 예전 디자인 */
/* <dl className="tbt1" style={{ width: "79px" }}>
          <dt style={{padding: "20px 0px 0px 0px", height: "70px !important", fontWeight: "bold", width: "86px",}}></dt>
          <dd>
            <ul>
              <li style={{padding: "20px 0px 0px 0px", height: "95px", background : "#f9f9f4"}}>금(Au)</li>
              <li style={{padding: "20px 0px 0px 0px", height: "95px", background: "#f9f9f4"}}>은(Ag)</li>
              <li style={{padding: "20px 0px 0px 0px", height: "95px", background: "#f9f9f4"}}>백금(Pt)</li>
              <li style={{padding: "20px 0px 0px 0px", height: "95px", background: "#f9f9f4"}}>팔라듐(Pd)</li>
            </ul>
          </dd>
        </dl>
        <dl className="tbt2" style={{marginLeft: "5px"}}>
          <dt style={{padding: "20px 0px 0px 0px", height: "70px !important", fontWeight: "bold"}}>
            <div className="tbtil2">국제 기준시세</div>
            <div className="tbtil_c2">
              <span style={{width: "71px"}}>(₩/g)</span>
              <span>(₩/3.75g)</span>
            </div>
          </dt>
          <dd>
            <ul id="perGBase">
              {standardPrice?.map(({ priceGram }, index) => {
                return <li key={index} style={{padding: "20px 0px 0px 0px", height: "95px", background : "#f9f9f4"}}>{numberWithCommas(priceGram)}</li>;
              })}
            </ul>
            <ul id="perDonBase">
              {standardPrice?.map(({ priceDon }, index) => {
                return <li key={index} style={{padding: "20px 0px 0px 0px", height: "95px", background : "#f9f9f4"}}>{numberWithCommas(priceDon)}</li>;
              })}
            </ul>
          </dd>
        </dl>
        <dl className="tbt3" style={{ width: "255px" }}>
          <dt style={{padding: "20px 0px 0px 0px",}}>
            <div className="tbtil3" style={{fontWeight: "bold"}}>국내 도매시세</div>
            <div className="tbtil_c3" style={{ fontWeight: "bold"}}>
              <span style={{width: "55px"}}>(₩/g)</span>
              <span style={{width: "100px"}}>(₩/3.75g)</span>
              <span>변동(₩/g)</span>
            </div>
          </dt>
          <dd>
            <ul id="perG" style={{ width: "75px" }}>
              {wholesalePrice?.map(({ priceGram }, index) => {
                return (
                  <li key={index} style={{padding: "20px 0px 0px 0px", height: "95px", background: "#f9f9f4"}}>
                    <span>{numberWithCommas(priceGram)}</span>
                  </li>
                );
              })}
            </ul>
            <ul id="perDon" style={{ width: "75px" }}>
              {wholesalePrice?.map(({ priceDon }, index) => {
                return (
                  <li key={index} style={{padding: "20px 0px 0px 0px", height: "95px", background: "#f9f9f4"}}>
                    <span>{numberWithCommas(priceDon)}</span>
                  </li>
                );
              })}
            </ul>
            <ul id="price" style={{ width: "105px"}}>
              {wholesalePrice?.map(({fluctuation}, index) => {
                return (
                  <li key={index} style={{padding: "20px 0px 0px 0px", height: "95px", background : "#f9f9f4"}}>{compareFluctuationRate("arrow", fluctuation)}</li>
                )
              })}
            </ul>
          </dd>
        </dl> */
