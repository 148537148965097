import React, { useEffect, useState } from "react";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import upArrow from "../../assets/img/up.png";
import downArrow from "../../assets/img/down.png";
import midd from "../../assets/img/midd.png";
import { numberWithCommas } from "../../lib/utils";

const MobileUsd = () => {
  const [exchangeRate, setExchangeRate] = useState([]);
  const [exchageRateData, refetch] = useAsync(APIS.getExchangeRate, [], true);
  const { data } = exchageRateData;

  useEffect(() => {
    setExchangeRate(
      data?.["exchangeRateDtoList"]?.filter((item) => item.type === "USD") || []
    );
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(() => APIS.getExchangeRate(), "", false);
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const compareFluctuationRate = (type, index) => {
    switch (type) {
      case "default":
        if (exchangeRate[index]?.fluctuationRate > 0) {
          return (
            <td
              style={{ fontWeight: "bold", fontSize: "larger" }}
              className="ch_up"
            >
              {exchangeRate[index]?.fluctuationRate}
            </td>
          );
        } else if (exchangeRate[index]?.fluctuationRate < 0) {
          return (
            <td
              style={{ fontWeight: "bold", fontSize: "larger" }}
              className="ch_dw"
            >
              {exchangeRate[index]?.fluctuationRate}
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (exchangeRate[index]?.fluctuation > 0) {
          return (
            <dd>
              <img src={upArrow} alt="up" />
              <span
                style={{ fontWeight: "bold", fontSize: "larger" }}
                className="up_red"
              >
                {exchangeRate[index]?.fluctuation}
              </span>
            </dd>
          );
        } else if (exchangeRate[index]?.fluctuation < 0) {
          return (
            <dd>
              <img src={downArrow} alt="down" />
              <span
                style={{ fontWeight: "bold", fontSize: "larger" }}
                className="dw_blue"
              >
                {exchangeRate[index]?.fluctuation}
              </span>
            </dd>
          );
        } else {
          return (
            <dd>
              <img src={midd} alt="midd" />
            </dd>
          );
        }
      // no default
    }
  };

  return (
    <div className="m_usd_area1">
      <h4 className="m_mtit">
        환율(USD)<span style={{ fontWeight: 400 }}>{data?.regdate || "-"}</span>
      </h4>

      <ul className="m_usd_box">
        <li>기준원달러환율</li>
        <li>{numberWithCommas(exchangeRate[0]?.ask || "-")}</li>
        <li>
          {compareFluctuationRate("arrow", 1)}
          {numberWithCommas(Math.abs(exchangeRate[0]?.fluctuation || "-"))}
        </li>
      </ul>
    </div>
  );
};

export default MobileUsd;
