import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import downArrow from "../../assets/img/down.png";
import upArrow from "../../assets/img/up.png";
import midd from "../../assets/img/midd.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";
export default function MajorIndex() {
  const [asiaIndex, setAsiaIndex] = useState([]);
  const [euIndex, setEuIndex] = useState([]);
  const [krIndex, setKrIndex] = useState([]);
  const [oilIndex, setOilIndex] = useState([]);
  const [usIndex, setUsIndex] = useState([]);
  const [majorIndexData, refetch] = useAsync(APIS.getMajorIndex, [], true);
  const { data } = majorIndexData;

  const compareFluctuationRate = (type, index, indexState) => {
    switch (type) {
      case "default":
        if (indexState[index]?.flucRa > 0) {
          return (
            <td className="ch_up">{indexState[index]?.flucRa}%</td>
          );
        } else if (indexState[index]?.flucRa < 0) {
          return (
            <td className="ch_dw">{indexState[index]?.flucRa}%</td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }
      case "arrow":
        if (indexState[index]?.fluc > 0) {
          return (
            <td className="tar">
              <img src={upArrow} alt="up" />
              <span>{indexState[index]?.fluc}</span>
            </td>
          );
        } else if (indexState[index]?.fluc < 0) {
          return (
            <td className="tar">
              <img src={downArrow} alt="down" />
              <span>{indexState[index]?.fluc}</span>
            </td>
          );
        } else {
          return (
            <td>
              <img src={midd} alt="midd" />
            </td>
          );
        }

       // no default
    }
  };

  useEffect(() => {
    setAsiaIndex(data?.["asiaIndexDtoList"] || []);
    setEuIndex(data?.["euIndexDtoList"] || []);
    setKrIndex(data?.["krIndexDtoList"] || []);
    setOilIndex(data?.["oilIndexDtoList"] || []);
    setUsIndex(data?.["usIndexDtoList"] || []);
  }, [data]);

  useEffect(()=> {
    const interval = setInterval(()=> {
      refetch(() => APIS.getMajorIndex(), "", false);
    }, 60000)
    return()=>clearInterval(interval)
    // eslint-disable-next-line
  },[])

  return (
    <div className="mcontainer5">
      <h4 className="mdtitl1">주요지수</h4>
      <div className="mmiddle_area5">
        <div data-aos="zoom-in" className="aos-init aos-animate">
          <div id="stock_table">
            <div className="table_type1">
              <table summary="주요지수 코스피, 나스닥, 영국, 니케이, WTI 선물">
                <colgroup>
                  <col style={{ width: "185px" }} />
                  <col style={{ width: "86px" }} />
                  <col style={{ width: "86px" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>코스피</dt>
                        <dd>
                          <span className="rdup">
                            {numberWithCommas(krIndex[0]?.price)}
                          </span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                      {krIndex[0] ? formatDate(new Date(krIndex[0]?.createAt)): "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                    {compareFluctuationRate("arrow", 0, krIndex)}
                    {compareFluctuationRate("default", 0, krIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>다우존스</dt>
                        <dd>
                          <span className="rdup">{numberWithCommas(usIndex[2]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {usIndex[2] ? formatDate(new Date(usIndex[2]?.createAt)): "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 2, usIndex)}
                    {compareFluctuationRate("default", 2, usIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>영국 FTSE</dt>
                        <dd>
                          <span className="rdup">{numberWithCommas(euIndex[0]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {euIndex[0] ? formatDate(new Date(euIndex[0]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 0, euIndex)}
                    {compareFluctuationRate("default", 0, euIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>닛케이225</dt>
                        <dd>
                          <span className="rdup">{numberWithCommas(asiaIndex[1]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {asiaIndex[1] ? formatDate(new Date(asiaIndex[1]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 1, asiaIndex)}
                    {compareFluctuationRate("default", 1, asiaIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt> WTI선물</dt>
                        <dd>
                          <span className="rddown">{numberWithCommas(oilIndex[0]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {oilIndex[0] ? formatDate(new Date(oilIndex[0]?.createAt)): "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 0, oilIndex)}
                    {compareFluctuationRate("default", 0, oilIndex)}
                  </tr>
                </tbody>
              </table>
              {/* <!--===================================table1 end --> */}
              <table summary="주요지수 코스피, 나스닥, 영국, 니케이, WTI 선물">
                <colgroup>
                  <col style={{ width: "185px" }} />
                  <col style={{ width: "86px" }} />
                  <col style={{ width: "86px" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>코스닥</dt>
                        <dd>
                          <span className="rdup">
                            {numberWithCommas(krIndex[1]?.price)}
                          </span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                      {krIndex[1] ? formatDate(new Date(krIndex[1]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                    {compareFluctuationRate("arrow", 1, krIndex)}
                    {compareFluctuationRate("default", 1, krIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>나스닥</dt>
                        <dd>
                          <span className="rddown">{numberWithCommas(usIndex[1]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {usIndex[1] ? formatDate(new Date(usIndex[1]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 1, usIndex)}
                    {compareFluctuationRate("default", 1, usIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>프랑스 CAC</dt>
                        <dd>
                          <span className="rdup">{numberWithCommas(euIndex[2]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {euIndex[2] ? formatDate(new Date(euIndex[2]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 2, euIndex)}
                    {compareFluctuationRate("default", 2, euIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>상하이 종합지수</dt>
                        <dd>
                          <span className="rdup">{numberWithCommas(asiaIndex[0]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {asiaIndex[0] ? formatDate(new Date(asiaIndex[0]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 0, asiaIndex)}
                    {compareFluctuationRate("default", 0, asiaIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>브렌트유</dt>
                        <dd>
                          <span className="rddown">{numberWithCommas(oilIndex[2]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {oilIndex[2] ? formatDate(new Date(oilIndex[2]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 2, oilIndex)}
                    {compareFluctuationRate("default", 2, oilIndex)}
                  </tr>
                </tbody>
              </table>
              {/* <!-- ===================================table2 end --> */}
              <table summary="주요지수 코스피, 나스닥, 영국, 니케이, WTI 선물">
                <colgroup>
                  <col style={{ width: "185px" }} />
                  <col style={{ width: "86px" }} />
                  <col style={{ width: "86px" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>코스피200</dt>
                        <dd>
                          <span className="rdup">
                            {numberWithCommas(krIndex[2]?.price)}
                          </span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                      {krIndex[2] ? formatDate(new Date(krIndex[2]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                    {compareFluctuationRate("arrow", 2, krIndex)}
                    {compareFluctuationRate("default", 2, krIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>S&amp;P500</dt>
                        <dd>
                          <span className="rddown"> {numberWithCommas(usIndex[0]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {usIndex[0] ? formatDate(new Date(usIndex[0]?.createAt)): "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 0, usIndex)}
                    {compareFluctuationRate("default", 0, usIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>독일 DAX</dt>
                        <dd>
                          <span className="rdup">{numberWithCommas(euIndex[1]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {euIndex[1] ? formatDate(new Date(euIndex[1]?.createAt)): "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 1, euIndex)}
                    {compareFluctuationRate("default", 1, euIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>독일 H.I.S 지수</dt>
                        <dd>
                          <span className="rdup">{numberWithCommas(asiaIndex[2]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {asiaIndex[2] ? formatDate(new Date(asiaIndex[2]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 2, asiaIndex)}
                    {compareFluctuationRate("default", 2, asiaIndex)}
                  </tr>
                  <tr>
                    <th rowSpan="2" className="tal">
                      <dl>
                        <dt>두바이유</dt>
                        <dd>
                          <span className="rddown">{numberWithCommas(oilIndex[1]?.price)}</span>
                        </dd>
                      </dl>
                    </th>
                    <td colSpan="2" className="tarCreateAt">
                    {oilIndex[1] ? formatDate(new Date(oilIndex[1]?.createAt)) : "-"}
                    </td>
                  </tr>
                  <tr className="bdmlin">
                  {compareFluctuationRate("arrow", 1, oilIndex)}
                    {compareFluctuationRate("default", 1, oilIndex)}
                  </tr>
                </tbody>
              </table>
              {/* <!--===================================table3 end --> */}
            </div>
            {/* <!-- stock_table --> */}
          </div>
        </div>
      </div>
    </div>
  );
}
