import React, { useEffect, useState } from "react";
import "../../assets/css/layout.css";
import "../../assets/css/main.css";
import "../../assets/css/swiper.min.css";
import "../../assets/css/common.css";
import downArrow from "../../assets/img/down.png";
import upArrow from "../../assets/img/up.png";
import midd from "../../assets/img/midd.png";
import useAsync from "../../lib/hooks/useAsync";
import * as APIS from "../../lib/api/index";
import { numberWithCommas, formatDate } from "../../lib/utils";
export default function WholesalePrices() {
  const [wholesalePrice, setWholesalePrice] = useState([]);
  const [wholesaleData, refetch] = useAsync(APIS.getWholesaleEtc, [], true);
  const { data } = wholesaleData;
 
  const reformData = (data) => {
    let arrayData = [];
    data?.forEach((item) => {
      if (item.type === "GC") {
        arrayData[0] = item;
      }
      if (item.type === "SC") {
        arrayData[1] = item;
      }
      if (item.type === "SP") {
        arrayData[2] = item;
      }
      if (item.type === "IR") {
        arrayData[3] = item;
      }
      if (item.type === "PSC") {
        arrayData[4] = item;
      }
      if (item.type === "SN") {
        arrayData[5] = item;
      }
    });
    return arrayData;
  };
  
  const compareFluctuationRate = (type, fluctuation, indexState) => {
    switch (type) {
      case "arrow":
        if (fluctuation > 0) {
          return (
            <td style={{textAlign: "-webkit-center"}}>
              <span>
                <img src={upArrow} alt="up" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(fluctuation)}
              </span>
            </td>
          );
        } else if (fluctuation < 0) {
          return (
            <td style={{textAlign: "-webkit-center"}}>
              <span>
                <img src={downArrow} alt="down" />
              </span>
              <span className="fn_num1">
                {numberWithCommas(fluctuation)}
              </span>
            </td>
          );
        } else {
          return (
            <td style={{textAlign: "-webkit-center"}}>
              <img src={midd} alt="midd" />
            </td>
          );
        }
         // no default
    }
  };
  useEffect(() => {
    setWholesalePrice(reformData(data?.["wholesalePriceMainDtoList"]) || []);
  }, [data]);

  useEffect(()=> {
    const interval = setInterval(()=> {
      refetch(() => APIS.getWholesaleEtc(), "", false);
    }, 60000)
    return()=>clearInterval(interval)
    // eslint-disable-next-line
  },[])

  return (
    <li>
      <h4 className="mdtitl1">기타 금속 도매시세</h4>
      <table>
        <colgroup>
            <col style={{width: "15%"}}/>
            <col style={{width: "20%"}}/>
            <col style={{width: "35%"}}/>
            <col style={{width: "30%"}}/>
				</colgroup>
        <thead>
          <tr>
            <td>종류</td>
            <td>기준가(₩/g)</td>
            <td>기준가</td>
            <td>변동(₩/g)</td>
          </tr>
        </thead>
        <tbody style={{ background: "#fff"}}>
            {wholesalePrice?.map((item, index) => {
              return(
                <tr key={index}>
                <td>{item.description}</td>
                <td>{numberWithCommas(item.priceGram)}</td>
                {item.description === "청화금" || item.description === "이리듐" ? (
                  <td>{numberWithCommas(item.priceDon)} (₩/100g)</td>
                ): (
                  <td>{numberWithCommas(item.priceDon)} (₩/1kg)</td>
                )}
                {compareFluctuationRate("arrow", item.fluctuation)}
                </tr>
              )
            })}
        </tbody>
      </table>
      <p className="gtime" style={{marginTop: "17px"}}>고시시간 :{data ? formatDate(new Date(data?.regdate)): "-"}</p>

    </li>
  );
}
